import { atom } from "jotai";

//全てのブースを管理
export const boothsAtom = atom([]);
//絞り込み結果のブースを管理
export const displayboothsAtom = atom([]);
//検索ボックスに入力されている文字列を管理
export const InputWordsAtom = atom("");
//キーワード検索にされている文字列を管理
export const searchInputAtom = atom("");
//選択されているカテゴリを管理
export const selectedCategoryAtom = atom("すべて");
//ページ番号を管理
export const currentPageAtom = atom(1);
//並び順のオプションを管理
export const orderAtom = atom("asc");
