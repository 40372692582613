import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useAtom } from "jotai";

import { displayboothsAtom, currentPageAtom } from "../atoms";
import { SearchFormSP } from "../organism/BoothList/SearchFormSP";
// import { Map } from "../organism/BoothList/Map";
import { Categories } from "../organism/BoothList/Categories";
import { DisplayBooths } from "../organism/BoothList/DisplayBooths";
import { Pagenation } from "../organism/BoothList/Pagenation";
import { ToPageTop } from "../organism/ToPageTop";

export const BoothList = () => {
  const [displayBooths] = useAtom(displayboothsAtom);
  const [currentPage] = useAtom(currentPageAtom);

  //1ページに表示するブース数の上限
  const BOOTHS_PER_PAGE = 18;

  const indexOfLastBooth = currentPage * BOOTHS_PER_PAGE;
  const indexOfFirstBooth = indexOfLastBooth - BOOTHS_PER_PAGE;
  //ページネーション時に表示するブースを取り出す
  const currentBooths = displayBooths.slice(indexOfFirstBooth, indexOfLastBooth);
  return (
    <>
      {/* タイトルの書き換え */}
      <HelmetProvider>
        <Helmet>
          <title>リベ大フェス2023 出店一覧</title>
        </Helmet>
      </HelmetProvider>

      {/* スマホ用の検索ボックス */}
      <SearchFormSP />

      <div className="content">
        {/* 会場マップ */}
        {/* <Map /> */}

        <main className="toppage-main">
          {/* カテゴリで絞り込み */}
          <Categories />

          <DisplayBooths currentBooths={currentBooths} />

          {/* ブースの表示が2ページ以上になる(検索結果が13件以上の)場合にページネーションを表示 */}
          {displayBooths.length > BOOTHS_PER_PAGE && <Pagenation boothsPerPage={BOOTHS_PER_PAGE} />}
        </main>
        <ToPageTop />
      </div>
    </>
  );
};
