import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAtom } from "jotai";

import {
  boothsAtom,
  displayboothsAtom,
  InputWordsAtom,
  searchInputAtom,
  selectedCategoryAtom,
  currentPageAtom,
  orderAtom,
} from "../atoms";
import { filterBooths } from "../../helper/filterBooths";

import logo from "../../images/logo.png";
import { scrollToSecShops } from "../../helper/scrollToSecShops";

export const Header = () => {
  const [booths] = useAtom(boothsAtom);
  const [, setDisplayBooths] = useAtom(displayboothsAtom);
  const [inputWords, setInputWords] = useAtom(InputWordsAtom);
  const [, setSearchInput] = useAtom(searchInputAtom);
  const [selectedCategory] = useAtom(selectedCategoryAtom);
  const [, setCurrentPage] = useAtom(currentPageAtom);
  const [, setOrder] = useAtom(orderAtom);

  const navigate = useNavigate();
  const location = useLocation();

  //入力された文字列を受け、searchInputに保存
  const handleInputChange = (e) => {
    e.preventDefault();
    setInputWords(e.target.value);
  };

  const onClickSearch = (e) => {
    e.preventDefault();

    setCurrentPage(1);
    setOrder("asc");

    setSearchInput(inputWords);

    filterBooths(booths, inputWords, selectedCategory, setDisplayBooths);

    if (location.pathname !== "/2023") {
      navigate("/2023");
      //一覧ページに戻る際に100ms待ってからスクロールを行う
      setTimeout(() => {
        scrollToSecShops(selectedCategory);
      }, 100);
    } else {
      scrollToSecShops(selectedCategory);
    }
  };

  const onClickClear = (e) => {
    e.preventDefault();
    setInputWords("");
  };

  return (
    <header className="header">
      <div className="header-inner">
        <h1 className="header-logo">
          <a href="/2023">
            <img src={logo} alt="リベ大フェス2023出店一覧" />
          </a>
        </h1>

        {/* <!-- 検索フォームPC -->
        <!-- 検索ボタンを押しても入力した文字は残しておく --> */}
        <div className="fix-keywords-form-pc">
          <div className="fix-keywords-inner-pc">
            <form className="keywords-form-pc">
              <input
                className="keywords-form-box-pc"
                type="text"
                value={inputWords}
                onChange={handleInputChange}
                placeholder="ブース名やユーザー名などを入力"
              />
              {inputWords && (
                <div className="keywords-form-clearbutton-pc" onClick={onClickClear}>
                  <i className="fa-solid fa-circle-xmark"></i>
                </div>
              )}
              <button type="submit" onClick={onClickSearch}>
                <i className="fa-solid fa-magnifying-glass"></i>
              </button>
            </form>
          </div>
        </div>

        {/* <!-- ヘッダーボタン --> */}
        <nav className="header-nav">
          <a className="modal-map-open" href="#">
            <i className="fa-solid fa-map-location-dot"></i>
            マップ
          </a>

          <a className="modal-keywords-open" href="#">
            <i className="fa-solid fa-filter"></i>
            絞り込み
          </a>
        </nav>
      </div>
    </header>
  );
};
