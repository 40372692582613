import { useAtom } from "jotai";
import React from "react";
import { useNavigate } from "react-router-dom";

import {
  boothsAtom,
  displayboothsAtom,
  selectedCategoryAtom,
  currentPageAtom,
  orderAtom,
} from "../../atoms";
import { filterBooths } from "../../../helper/filterBooths";
import { CategoryItem } from "../CategoryItem";
import { scrollToSecShops } from "../../../helper/scrollToSecShops";

export const SameCategory = ({ booth }) => {
  const [booths] = useAtom(boothsAtom);
  const [, setDisplayBooths] = useAtom(displayboothsAtom);
  const [, setSelectedCategory] = useAtom(selectedCategoryAtom);
  const [, setCurrentPage] = useAtom(currentPageAtom);
  const [, setOrder] = useAtom(orderAtom);

  const navigate = useNavigate();

  const onClickCategory = (e) => {
    e.preventDefault();
    const chosenCategory = e.target.dataset.nav;

    setCurrentPage(1);
    setOrder("asc");
    setSelectedCategory(chosenCategory);

    filterBooths(booths, "", chosenCategory, setDisplayBooths);

    //ブース詳細ページからトップページに遷移し、スクロールを行う
    navigate("/2023");
    //一覧ページに戻る際に100ms待ってからスクロールを行う
    setTimeout(() => {
      scrollToSecShops(chosenCategory);
    }, 100);
  };

  return (
    <div className="shops-item">
      <h3 className="shops-tit">
        <i className="fa-solid fa-magnifying-glass"></i>
        同じキーワードから探す
      </h3>

      <ul className="shops-keywords-list">
        {booth.categories.split(",").map((category) => {
          const categoryName = category.trim();
          return (
            <li key={categoryName} className="shops-keywords-item">
              <CategoryItem onClickCategory={onClickCategory} categoryName={categoryName} />
            </li>
          );
        })}
      </ul>
    </div>
  );
};
