import React from "react";
import { useAtom } from "jotai";

import {
  boothsAtom,
  currentPageAtom,
  displayboothsAtom,
  orderAtom,
  searchInputAtom,
  selectedCategoryAtom,
} from "../../atoms";
import { categories } from "../../categoryList";
import { CategoryItem } from "../CategoryItem";
import { filterBooths } from "../../../helper/filterBooths";
import { scrollToSecShops } from "../../../helper/scrollToSecShops";

export const Categories = () => {
  const [booths] = useAtom(boothsAtom);
  const [, setDisplayBooths] = useAtom(displayboothsAtom);
  const [searchInput] = useAtom(searchInputAtom);
  const [selectedCategory, setSelectedCategory] = useAtom(selectedCategoryAtom);
  const [, setCurrentPage] = useAtom(currentPageAtom);
  const [, setOrder] = useAtom(orderAtom);

  const onClickCategory = (e) => {
    e.preventDefault();
    const chosenCategory = e.target.dataset.nav;

    setCurrentPage(1);
    setOrder("asc");
    setSelectedCategory(chosenCategory);

    filterBooths(booths, searchInput, chosenCategory, setDisplayBooths);

    scrollToSecShops(chosenCategory, searchInput);
  };

  return (
    <>
      <section id="sec-keywords" className="sec-keywords">
        <h2 className="sec-keywords-tit">ブースを探す</h2>

        <ul className="sec-keywords-list show-text">
          {/* 「すべて」の場合は、絞り込みを行わないため別にしています */}
          <li className={`sec-keywords-item${selectedCategory === "すべて" ? "-selected" : ""}`}>
            <a href="#sec-shops" onClick={onClickCategory} data-nav="すべて">
              すべて({booths.length})
            </a>
          </li>

          {categories.map((category) => (
            <li
              key={category}
              className={`sec-keywords-item${selectedCategory === category ? "-selected" : ""}`}
            >
              <CategoryItem onClickCategory={onClickCategory} categoryName={category} />
            </li>
          ))}
        </ul>

        <p className="keywords-more-btn readmore">
          すべて表示する
          <i className="fa-solid fa-chevron-down"></i>
        </p>
        <p className="keywords-close-btn close">
          閉じる
          <i className="fa-solid fa-chevron-up"></i>
        </p>
      </section>
    </>
  );
};
