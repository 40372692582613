import React from "react";
import { useAtom } from "jotai";

import { boothsAtom } from "../atoms";

export const CategoryItem = (props) => {
  const { onClickCategory, categoryName } = props;
  const [booths] = useAtom(boothsAtom);
  const boothsCount = booths.filter((booth) => {
    const boothCategories = booth.categories.split(",").map((category) => category.trim());
    return boothCategories.includes(categoryName);
  }).length;

  return (
    <a href="#sec-shops" onClick={onClickCategory} data-nav={categoryName}>
      {categoryName}({boothsCount})
    </a>
  );
};
