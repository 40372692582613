import React from "react";
import { useAtom } from "jotai";
import {
  currentPageAtom,
  displayboothsAtom,
  searchInputAtom,
  selectedCategoryAtom,
} from "../../atoms";
import { scrollToSecShops } from "../../../helper/scrollToSecShops";

export const Pagenation = ({ boothsPerPage }) => {
  const [displayBooths] = useAtom(displayboothsAtom);
  const [selectedCategory] = useAtom(selectedCategoryAtom);
  const [searchInput] = useAtom(searchInputAtom);
  const [currentPage, setCurrentPage] = useAtom(currentPageAtom);

  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(displayBooths.length / boothsPerPage); i++) {
    pageNumbers.push(i);
  }

  const handlePrevClick = (e) => {
    e.preventDefault();
    if (currentPage > 1) {
      setCurrentPage((currentPage) => currentPage - 1);
    }
    setTimeout(() => {
      scrollToSecShops(selectedCategory, searchInput);
    }, 100);
  };

  const handleNextClick = (e) => {
    e.preventDefault();
    if (currentPage < pageNumbers.length) {
      setCurrentPage((currentPage) => currentPage + 1);
    }
    setTimeout(() => {
      scrollToSecShops(selectedCategory, searchInput);
    }, 100);
  };

  const setPage = (page) => {
    setCurrentPage(page);
    setTimeout(() => {
      scrollToSecShops(selectedCategory, searchInput);
    }, 100);
  };

  const renderPageNumbers = (numbers) => {
    return numbers.map((number, index, array) => {
      let style = {};
      if (currentPage === number) {
        style.backgroundColor = "rgba(75, 141, 202, 0.2)";
        style.cursor = "inherit";

        if (number === array[0]) {
          style.borderRadius = "2px 0 0 2px";
        } else if (number === array[array.length - 1]) {
          style.borderRadius = "0 2px 2px 0";
        } else {
          style.borderRadius = "0px";
        }
        if (numbers.length === 1) {
          style.borderRadius = "2px";
        }
      }

      // numbersが[2]の場合は個別のスタイルを適用
      const alone = JSON.stringify(numbers) === JSON.stringify([2]) ? "alone" : "";
      return (
        <li key={number} className={`paging-item ${alone}`}>
          <a
            href="#"
            style={style}
            onClick={(e) => {
              e.preventDefault();
              setPage(number);
            }}
          >
            {number}
          </a>
        </li>
      );
    });
  };

  return (
    <>
      <p className="count">
        {(currentPage - 1) * boothsPerPage + 1} -{" "}
        {displayBooths.length < currentPage * boothsPerPage
          ? displayBooths.length
          : currentPage * boothsPerPage}
        件 <var>({displayBooths.length}件)</var>
      </p>
      <nav className="paging">
        <ul className="paging-list">
          {/* 1ページ目 */}
          {renderPageNumbers([1])}

          {/* 3ページの場合 */}
          {pageNumbers.length === 3 && renderPageNumbers([2])}

          {/* 4ページ以上、7ページ以下の場合 */}
          {pageNumbers.length < 8 &&
            pageNumbers.length > 3 &&
            renderPageNumbers(pageNumbers.slice(1, -1))}

          {/* 8ページ以上かつ、現在のページが4以下の場合 */}
          {pageNumbers.length > 7 && currentPage < 5 && renderPageNumbers(pageNumbers.slice(1, 7))}

          {/* 8ページ以上かつ、現在のページが5以上最後のページ-3の場合 */}
          {pageNumbers.length > 7 &&
            currentPage > 4 &&
            currentPage < pageNumbers.slice(-1)[0] - 3 &&
            renderPageNumbers(pageNumbers.slice(currentPage - 3, currentPage + 3))}

          {/* 8ページ以上かつ、現在のページが最後のページ-3以内の場合 */}
          {pageNumbers.length > 7 &&
            currentPage > pageNumbers.slice(-1)[0] - 4 &&
            renderPageNumbers(pageNumbers.slice(pageNumbers.length - 7, pageNumbers.length - 1))}

          {/* 最終ページ */}
          {renderPageNumbers([pageNumbers.slice(-1)[0]])}
        </ul>

        {/* <!-- 1ページ目のみ表示 --> */}
        {currentPage === 1 && (
          <div className="next-move">
            <a href="#" onClick={handleNextClick}>
              次へ
              <i className="fa-solid fa-chevron-right"></i>
            </a>
          </div>
        )}

        {/* <!-- 2ページ目以降に表示 --> */}
        {currentPage > 1 && currentPage < pageNumbers.slice(-1)[0] && (
          <div className="move">
            <div className="prev">
              <a href="#" onClick={handlePrevClick}>
                前へ
                <i className="fa-solid fa-chevron-left"></i>
              </a>
            </div>

            <div className="next">
              <a href="#" onClick={handleNextClick}>
                次へ
                <i className="fa-solid fa-chevron-right"></i>
              </a>
            </div>
          </div>
        )}
        {/* 
      <!-- 最終ページのみ表示 --> */}
        {currentPage === pageNumbers.slice(-1)[0] && (
          <div className="prev-move">
            <a href="#" onClick={handlePrevClick}>
              前へ
              <i className="fa-solid fa-chevron-left"></i>
            </a>
          </div>
        )}
      </nav>
    </>
  );
};
