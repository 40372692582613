import { filterBoothsByKeyword } from "./filterBoothsByKeyword";

export const filterBooths = (booths, searchInput, selectedCategory, setDisplayBooths) => {
  //一旦リセットする
  setDisplayBooths(booths);
  //カテゴリが「すべて」ではない場合は、まずそのカテゴリで絞り込みを行う
  if (selectedCategory !== "すべて") {
    const filteredBooths = booths.filter((booth) => {
      const boothCategories = booth.categories.split(",").map((category) => category.trim());
      return boothCategories.includes(selectedCategory);
    });
    //キーワードの入力の有無でdisplayBoothsをさらに絞り込む
    if (searchInput === "") {
      setDisplayBooths(filteredBooths);
    } else {
      const filteredBoothsByKeyword = filterBoothsByKeyword(filteredBooths, searchInput);
      setDisplayBooths(filteredBoothsByKeyword);
    }
  } else {
    //カテゴリが「すべて」の場合は、キーワード次第
    const filteredBooths = filterBoothsByKeyword(booths, searchInput);
    setDisplayBooths(filteredBooths);
  }
};
