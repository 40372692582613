import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAtom } from "jotai";
import $ from "jquery";

import {
  boothsAtom,
  currentPageAtom,
  displayboothsAtom,
  orderAtom,
  searchInputAtom,
  selectedCategoryAtom,
} from "../atoms";
import { categories } from "../categoryList";
import { CategoryItem } from "./CategoryItem";
import { filterBooths } from "../../helper/filterBooths";
import { scrollToSecShops } from "../../helper/scrollToSecShops";

export const CategoryModal = () => {
  const [booths] = useAtom(boothsAtom);
  const [, setDisplayBooths] = useAtom(displayboothsAtom);
  const [searchInput] = useAtom(searchInputAtom);
  const [selectedCategory, setSelectedCategory] = useAtom(selectedCategoryAtom);
  const [, setCurrentPage] = useAtom(currentPageAtom);
  const [, setOrder] = useAtom(orderAtom);

  const navigate = useNavigate();
  const location = useLocation();

  const onClickCategory = (e) => {
    e.preventDefault();
    const chosenCategory = e.target.dataset.nav;

    setCurrentPage(1);
    setOrder("asc");
    setSelectedCategory(chosenCategory);

    filterBooths(booths, searchInput, chosenCategory, setDisplayBooths);

    if (location.pathname !== "/2023") {
      navigate("/2023");
      //一覧ページに戻る際に100ms待ってからスクロールを行う
      setTimeout(() => {
        scrollToSecShops(chosenCategory);
      }, 100);
    } else {
      scrollToSecShops(chosenCategory);
    }
  };

  // キーワード拡大
  $(function () {
    var open = $(".modal-keywords-open"),
      close = $(".modal-keywords-close"),
      keyword = $(".modal-keywords-item"),
      container = $(".modal-keywords-container");

    open.on("click", function () {
      container.addClass("active");
      $("body").addClass("modalBg");
      return false;
    });

    close.on("click", function () {
      container.removeClass("active");
      $("body").removeClass("modalBg");
    });

    keyword.on("click", function () {
      container.removeClass("active");
      $("body").removeClass("modalBg");
    });

    $(".modal-keywords-container").click(function (event) {
      if (!$(event.target).closest(".modal-keywords-content").length) {
        container.removeClass("active");
        $("body").removeClass("modalBg");
      }
    });
  });

  $(document).ready(function () {
    var width = $(window).width();
    if (width <= 765) {
      $(".keywords-more-btn").on("click", function () {
        $(".sec-keywords-list").css("max-height", $(".sec-keywords-list")[0].scrollHeight + "px");
        $(this).hide();
        $(".keywords-close-btn").addClass("show");
        $(".keywords-close-btn").show();
      });

      $(".keywords-close-btn").on("click", function () {
        $(".sec-keywords-list").css("max-height", "360px");
        $(this).hide();
        $(".keywords-more-btn").show();
      });
    }
  });

  return (
    <div className="modal-keywords-container">
      <div></div>
      <div className="modal-keywords-body">
        <div className="modal-keywords-content">
          <div className="modal-keywords-close">
            <i className="fa-solid fa-xmark"></i>
          </div>

          <div className="modal-keywords-inner">
            <ul className="modal-keywords-list">
              {/* 「すべて」の場合は、絞り込みを行わないため別にしています */}
              <li
                className={`modal-keywords-item${selectedCategory === "すべて" ? "-selected" : ""}`}
              >
                <a href="#sec-shops" onClick={onClickCategory} data-nav="すべて">
                  すべて({booths.length})
                </a>
              </li>

              {categories.map((category) => (
                <li
                  key={category}
                  className={`modal-keywords-item${
                    selectedCategory === category ? "-selected" : ""
                  }`}
                >
                  <CategoryItem onClickCategory={onClickCategory} categoryName={category} />
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
