import React, { useState } from "react";

import noImage from "../../images/error.png";

export const BoothImage = ({ booth }) => {
  const zeroPadding = (num) => String(num).padStart(3, "0");
  const imageName = zeroPadding(booth.id);

  const [imageSrc, setImageSrc] = useState(`${process.env.PUBLIC_URL}/thumbnails/${imageName}.png`);

  const handleImageError = () => {
    setImageSrc(noImage);
  };

  return <img src={imageSrc} alt="サムネイル画像" onError={handleImageError} />;
};
