export const scrollToSecShops = (selectedCategory = "すべて", searchInput = "") => {
  const target = document.getElementById("sec-shops");
  if (target) {
    const isSP = window.innerWidth <= 830;
    let offset = isSP ? 190 : 100;
    if (isSP && selectedCategory === "すべて" && searchInput === "") {
      offset = 160;
    }
    window.scrollTo({
      top: target.offsetTop - offset,
      behavior: "smooth",
    });
  }
};
