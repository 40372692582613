import React from "react";

import { useNavigate, useLocation } from "react-router-dom";

export const BackToTopButton = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { selectedCategory, searchInput, currentPage, order, scrollPosition } =
    location.state || {};
  //useLocationで受け取った情報を保持して、一覧に戻るときにその情報を利用してページ遷移を行う
  const backToTopPage = (e) => {
    e.preventDefault();
    navigate("/2023", {
      state: {
        selectedCategory,
        searchInput,
        currentPage,
        order,
        scrollPosition,
      },
    });
  };

  return (
    <p className="back-btn">
      <a href="#" onClick={backToTopPage}>
        <i className="fa-solid fa-angle-left"></i>一覧に戻る
      </a>
    </p>
  );
};
