import React from "react";

import { BoothImage } from "../../atom/BoothImage";

export const BoothOverview = (props) => {
  const { booth } = props;

  return (
    <>
      <div className={`shop-img num_${booth.css_type}`}>
        <BoothImage booth={booth} />
        <p className={`number num_${booth.css_type}`}>{booth.area ? booth.area : booth.id}</p>
      </div>
      <div className="shop-info">
        <h2 className="name">{booth.name}</h2>
        {booth.flyer_url ? (
          <a className="webflyer-link" href={booth.flyer_url} target="_blank" rel="noreferrer">
            <i className="fa-regular fa-file-lines"></i>
            チラシ
          </a>
        ) : (
          <div className="no-webflyer">
            <p className="no-webflyer-txt">
              チラシ
              <br />
              なし
            </p>
          </div>
        )}
      </div>
    </>
  );
};
