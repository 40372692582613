import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useAtom } from "jotai";

import "./App.css";
import { useFetchBooths } from "./component/useFetchBooths";
import { booths } from "./component/boothData";
import { Error } from "./component/templates/Error";
import { Loading } from "./component/templates/Loading";

import { Header } from "./component/organism/Header";
import { BoothList } from "./component/templates/BoothList";
import { BoothDetail } from "./component/templates/BoothDetail";
import { MapModal } from "./component/organism/MapModal";
import { CategoryModal } from "./component/organism/CategoryModal";
import { Footer } from "./component/organism/Footer";

import { boothsAtom, displayboothsAtom } from "./component/atoms";

const App = () => {
  //SSSAPIからフェッチした情報を展開
  const { isLoading, error } = useFetchBooths();
  const [, setBooths] = useAtom(boothsAtom);
  const [, setDisplayBooths] = useAtom(displayboothsAtom);

  //初回、およびブースの値が変更されたら呼び出される
  useEffect(() => {
    if (booths && !isLoading) {
      setBooths(booths);
      setDisplayBooths(booths);
    }
  }, [booths, isLoading]);

  if (error) return <Error />;
  if (isLoading) return <Loading />;

  return (
    <>
      <BrowserRouter>
        {/* ヘッダー */}
        <Header />

        {/* ルーティング */}
        <Routes>
          <Route path={"/"} element={<Navigate to="/2023" />} />
          <Route path={"/2023"} element={<BoothList />} />
          <Route path={"/2023/booth/:id"} element={<BoothDetail />} />
          <Route path={"/*"} element={<Error />} />
        </Routes>

        {/* マップモーダル */}
        <MapModal />
        {/* カテゴリでの検索モーダル */}
        <CategoryModal />
      </BrowserRouter>

      {/* フッター */}
      <Footer />
    </>
  );
};

export default App;
