import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useAtom } from "jotai";
import {
  InputWordsAtom,
  boothsAtom,
  currentPageAtom,
  displayboothsAtom,
  orderAtom,
  searchInputAtom,
  selectedCategoryAtom,
} from "../../atoms";

import { BoothCard } from "../../molecules/BoothCard";
import { filterBooths } from "../../../helper/filterBooths";
import { scrollToSecShops } from "../../../helper/scrollToSecShops";

export const DisplayBooths = ({ currentBooths }) => {
  const [booths] = useAtom(boothsAtom);
  const [displayBooths, setDisplayBooths] = useAtom(displayboothsAtom);
  const [, setInputWords] = useAtom(InputWordsAtom);
  const [selectedCategory, setSelectedCategory] = useAtom(selectedCategoryAtom);
  const [searchInput, setSearchInput] = useAtom(searchInputAtom);
  const [, setCurrentPage] = useAtom(currentPageAtom);
  const [order, setOrder] = useAtom(orderAtom);
  const location = useLocation();
  const { scrollToId, scrollPosition } = location.state || {};

  //並び替えを実装する
  const handleSortChange = (e) => {
    const sortOrder = e.target.value;
    const newDisplayBooths = [...displayBooths];
    if (sortOrder === "desc") {
      newDisplayBooths.sort((a, b) => b.num - a.num);
      setOrder("desc");
    } else if (sortOrder === "asc") {
      newDisplayBooths.sort((a, b) => a.num - b.num);
      setOrder("asc");
    }
    setDisplayBooths(newDisplayBooths);
  };

  //検索条件のリセット
  const onClickReset = (e) => {
    e.preventDefault();
    setInputWords("");
    setSearchInput("");
    setCurrentPage(1);
    setOrder("asc");
    setSelectedCategory("すべて");
    filterBooths(booths, "", "すべて", setDisplayBooths);

    scrollToSecShops();
  };

  //他のページからの遷移時のスクロール位置を管理
  useEffect(() => {
    if (scrollToId) {
      const target = document.getElementById(scrollToId);
      if (target) {
        const isSP = window.innerWidth <= 830;
        const offset = isSP ? 160 : 100;
        window.scrollTo({
          top: target.offsetTop - offset,
          behavior: "smooth",
        });
      }
    }
  }, [scrollToId]);

  //ブース詳細画面の「一覧に戻る」ボタンからの遷移時
  useEffect(() => {
    if (scrollPosition) {
      window.scrollTo(0, scrollPosition);
    }
  }, [scrollPosition]);

  return (
    <section id="sec-shops" className="sec-shops">
      <div className="sec-shops-captionBox">
        <h2 className="sec-shops-caption">
          <span className="sec-shops-category">{selectedCategory}</span>
          {searchInput && <span className="sec-shops-words">{`"${searchInput}"`}</span>}
          <span className="sec-shops-result">
            の検索結果
            <span> 全{displayBooths.length}件</span>
          </span>
        </h2>
        {(selectedCategory !== "すべて" || searchInput !== "") && (
          <button className="reset-btn-sp" onClick={onClickReset}>
            <i className="fa-solid fa-xmark"></i>
            現在の検索条件をクリア
          </button>
        )}
        <div className="sec-shops-sort">
          <select name="sort" value={order} onChange={handleSortChange}>
            <option value="asc">ブース番号が小さい順</option>
            <option value="desc">ブース番号が大きい順</option>
          </select>
        </div>
      </div>

      {(selectedCategory !== "すべて" || searchInput !== "") && (
        <button className="reset-btn-pc" onClick={onClickReset}>
          <i className="fa-solid fa-xmark"></i>
          現在の検索条件をクリア
        </button>
      )}

      {displayBooths.length > 0 ? (
        <ul className="sec-shops-list">
          {currentBooths.map((booth) => (
            <li className="sec-shops-item" key={booth.id}>
              <BoothCard booth={booth} />
            </li>
          ))}
        </ul>
      ) : (
        <div className="result-no-service">
          <p className="result-no-service-txt">該当するブースはありません</p>
        </div>
      )}
    </section>
  );
};
