import React from "react";
import $ from "jquery";

export const ToPageTop = () => {
  // ページトップへ戻る
  $(function () {
    $(window).scroll(function () {
      if ($(window).scrollTop() > 1) {
        $(".pagetop").fadeIn(400).css("display", "flex");
      } else {
        $(".pagetop").fadeOut(400);
      }
    });
  });

  return (
    <a href="#" className="pagetop">
      <i className="fas fa-chevron-up" aria-hidden="true"></i>
    </a>
  );
};
