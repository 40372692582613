import React, { useEffect } from "react";
import $ from "jquery";
import Highlighter from "react-highlight-words";
import { useAtom } from "jotai";
import { searchInputAtom } from "../../atoms";

export const BoothIntro = (props) => {
  const { booth } = props;
  const [searchInput] = useAtom(searchInputAtom);

  const highlightWords = searchInput.split(/\s+/);

  //共同出店者の 全て表示/一部のみ表示 の切り替えを行う
  //ReactとjQueryの共存で(理由は他にあるかもですが)、レンダリング時に以下jQuery部分が2回実行され、
  //"..."が2回表示されてしまっていたため、useEffectを使用し制御
  useEffect(() => {
    $(document).ready(function () {
      if (booth && booth.co_owners) {
        $(".name-more-btn").on("click", function () {
          $(".name-show-text").css("max-height", $(".name-show-text")[0].scrollHeight + "px");
          $(this).hide();
          $(".name-close-btn").addClass("show");
          $(".name-close-btn").show();

          $(".name-ellipsis").remove();
        });

        $(".name-close-btn").on("click", function () {
          $(".name-show-text").css("max-height", "100px");
          $(this).hide();
          $(".name-more-btn").show();
          $(".name-more-btn").before('<span class="name-ellipsis">...</span>');
        });

        if ($(".name-show-text")[0].scrollHeight <= 100) {
          $(".name-more-btn").hide();
        } else {
          $(".name-more-btn").show();
          if ($(".name-show-text")[0].scrollHeight > 100) {
            $(".name-more-btn").before('<span class="name-ellipsis">...</span>');
          }
        }
      }
    });
  }, []);

  // マップ拡大
  $(function () {
    var open = $(".modal-map-open"),
      close = $(".modal-map-close"),
      container = $(".modal-map-container");

    open.on("click", function () {
      container.addClass("active");
      return false;
    });

    close.on("click", function () {
      container.removeClass("active");
    });

    $(".modal-map-container").click(function (event) {
      if (!$(event.target).closest(".modal-map-content").length) {
        container.removeClass("active");
      }
    });
  });

  return (
    <div className="shops-inner">
      <div className="shops-item">
        <h3 className="shops-tit">
          <i className="fa-solid fa-store"></i>ブース紹介
        </h3>
        <div className="shop-item">
          <p className="shop-content">内容</p>
          <p className="shops-txt shops-show-text">
            <Highlighter
              highlightClassName="HighlightClass"
              searchWords={highlightWords}
              autoEscape={true}
              textToHighlight={booth.content}
            />
          </p>
        </div>
        <div className="shop-item">
          <p className="shop-content">こだわり</p>
          <p className="shops-txt shops-show-text">
            <Highlighter
              highlightClassName="HighlightClass"
              searchWords={highlightWords}
              autoEscape={true}
              textToHighlight={booth.pr}
            />
          </p>
        </div>
        <div className="shop-item">
          <p className="shop-content">こんな方におすすめ</p>
          <p className="shops-txt shops-show-text">
            <Highlighter
              highlightClassName="HighlightClass"
              searchWords={highlightWords}
              autoEscape={true}
              textToHighlight={booth.for_whom}
            />
          </p>
        </div>
        <div className="shop-item">
          {booth.night_content && (
            <>
              <p className="shop-content">両子ママの夜フェス</p>
              <p className="shops-txt shops-show-text">
                <Highlighter
                  highlightClassName="HighlightClass"
                  searchWords={highlightWords}
                  autoEscape={true}
                  textToHighlight={booth.night_content}
                />
              </p>
            </>
          )}

          {booth.flyer_url ? (
            <a
              className="shop-webflyer-link"
              href={booth.flyer_url}
              target="_blank"
              rel="noreferrer"
            >
              <i className="fa-regular fa-file-lines"></i>
              Webチラシを見る
            </a>
          ) : (
            <div className="shop-no-webflyer">
              <p className="shop-no-webflyer-txt">Webチラシはありません</p>
            </div>
          )}
        </div>

        {/* <!-- 位置情報 --> */}
        <div className="shops-map">
          {booth.area ? (
            <>
              <p className="shops-map-txt">\ エリアの位置情報はこちら /</p>
              <p className="shops-map-arrow">エリア</p>
              <p className="shops-map-number">{booth.area}</p>
            </>
          ) : (
            <>
              <p className="shops-map-txt">\ ブースの位置情報はこちら /</p>
              <p className="shops-map-arrow">ブース番号</p>
              <p className="shops-map-number">{booth.id}</p>
            </>
          )}

          <a className="modal-map-open" href="#">
            <i className="fa-solid fa-map-location-dot"></i>
            マップで確認する
          </a>
        </div>
      </div>

      {/* <!-- 代表者・共同出展者名 --> */}
      <div className="shops-item">
        <h3 className="shops-tit">
          <i className="fa-solid fa-user is_mainColor"></i>
          代表者・共同出店者名
        </h3>
        <div className="shops-name">
          <dl className="shops-name-container">
            <dt className="shops-name-prof">代表者</dt>
            <dd className="shops-name-link">
              {/* <!-- 代表者のみプロフィールリンクを挿入 --> */}
              <a href={`${booth.owner_url}`} target="_blank" rel="noreferrer">
                <Highlighter
                  highlightClassName="HighlightClass"
                  searchWords={highlightWords}
                  autoEscape={true}
                  textToHighlight={booth.owner}
                />
              </a>
            </dd>
          </dl>
          <dl className="shops-name-container">
            <dt className="shops-name-prof">共同出店者</dt>
            {booth.co_owners ? (
              //共同出店者あり
              <dd className="shops-name-link all">
                <ul className="name-show-text">
                  {booth.co_owners.split("\n").map((co_owner) => (
                    <li className="shops-name-all" key={co_owner}>
                      <Highlighter
                        highlightClassName="HighlightClass"
                        searchWords={highlightWords}
                        autoEscape={true}
                        textToHighlight={co_owner}
                      />
                    </li>
                  ))}
                </ul>

                <p className="name-more-btn readmore">
                  すべての出店者を見る
                  <i className="fa-solid fa-chevron-down"></i>
                </p>

                <p className="name-close-btn close">
                  閉じる<i className="fa-solid fa-chevron-up"></i>
                </p>
              </dd>
            ) : (
              // 共同出店者なし
              <dd className="shops-nameless">共同出店者はいません</dd>
            )}
          </dl>
        </div>
      </div>
    </div>
  );
};
