import React from "react";

export const Loading = () => {
  return (
    <div className="loading">
      <div className="loading-icon"></div>
      <div className="loading-text">ロード中...</div>
    </div>
  );
};
