import React from "react";
import $ from "jquery";

import map from "../../images/map.png";

export const MapModal = () => {
  // マップ拡大
  $(function () {
    var open = $(".modal-map-open"),
      close = $(".modal-map-close"),
      container = $(".modal-map-container");

    open.on("click", function () {
      container.addClass("active");
      $("body").addClass("modalBg");
      $(window).on("touchmove.noScroll", function (e) {
        e.preventDefault();
      });
      return false;
    });

    close.on("click", function () {
      container.removeClass("active");
      $("body").removeClass("modalBg");
      $(window).off(".noScroll");
    });

    $(".modal-map-container").click(function (event) {
      if (!$(event.target).closest(".modal-map-content").length) {
        container.removeClass("active");
        $("body").removeClass("modalBg");
        $(window).off(".noScroll");
      }
    });
  });

  return (
    <div className="modal-map-container">
      <div className="modal-map-body">
        <div className="modal-map-content">
          <div className="modal-map-close">
            <i className="fa-solid fa-xmark"></i>
          </div>
          <div className="modal-map-inner">
            <div className="modal-map-txt">
              <div className="map-pdf-txt">
                マップをクリックすると、Webチラシ付きPDFをご覧になれます。
              </div>
              <div className="map-print-txt">
                会場では紙のパンフレットは配布されませんので、
                <a
                  href="https://site.libecity.com/wp-content/uploads/2023/06/f7259b7c1dde8f19ea66cd2685ad4357-1.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  こちら
                </a>
                から各自印刷してお持ちください。
              </div>
            </div>
            <div className="modal-map-img">
              <a
                href="https://site.libecity.com/wp-content/uploads/2023/06/9a20b513486a8d340e6b611333e34ace.pdf"
                target="_blank"
                rel="noreferrer"
              >
                <img src={map} alt="会場マップ" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
