export const filterBoothsByKeyword = (boothsForKeywordFilter, searchInput) => {
  const keysToCheck = [
    "search_id",
    "name",
    "content",
    "pr",
    "for_whom",
    "night_content",
    "owner",
    "co_owners",
  ];

  //考えられる空白文字で分割し、AND検索の対象にする
  const searchKeywords = searchInput.split(/\s+/);

  const filteredBooths = boothsForKeywordFilter.filter((booth) =>
    searchKeywords.every((keyword) =>
      keysToCheck.some(
        (key) =>
          Object.prototype.hasOwnProperty.call(booth, key) && String(booth[key]).includes(keyword)
      )
    )
  );

  return filteredBooths;
};
