import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";

import errorImage from "../../images/error-page.png";

export const Error = () => {
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>エラーページ</title>
        </Helmet>
      </HelmetProvider>

      <div className="error-page">
        <div className="error-subpage-inner">
          <img src={errorImage} alt="エラーページの画像" />
          <p className="error-page-word">Sorry.</p>
          <p className="error-page-tit">エラーが発生し表示できませんでした</p>
          <p className="error-page-txt">最初から操作しなおしてみてください。</p>

          <a href="/2023">
            TOPページに戻る
            <i className="fa-solid fa-chevron-right"></i>
          </a>

          <div className="error-contact-form">
            <p className="error-contact-tit">【それでもページが表示されない場合】</p>
            <p className="error-contact-txt">お問い合わせフォームからご連絡ください。</p>
            <p className="error-contact-link">
              <a href="https://site.libecity.com/contact" target="_blank" rel="noreferrer">
                お問い合わせフォーム <i className="fa-solid fa-up-right-from-square"></i>
              </a>
              を開く
              {/* <!-- <i className="fa-solid fa-up-right-from-square"></i> --> */}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
