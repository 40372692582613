import React from "react";

export const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-inner">
        <div className="fes-link">
          <div className="fespage-link">
            <a href="https://site.libecity.com/festival2023" target="_blank" rel="noreferrer">
              特設ページ<i className="fa-solid fa-up-right-from-square"></i>
            </a>
            <a
              href="https://e-ve.event-form.jp/event/48058/libefes2023"
              target="_blank"
              rel="noreferrer"
            >
              チケット販売サイト<i className="fa-solid fa-up-right-from-square"></i>
            </a>
          </div>
        </div>
        <p className="footer-text">© リベ大フェス2023出店一覧</p>
      </div>
    </footer>
  );
};
