import React, { useState, useEffect } from "react";
import { useAtom } from "jotai";

import {
  boothsAtom,
  displayboothsAtom,
  InputWordsAtom,
  searchInputAtom,
  selectedCategoryAtom,
  currentPageAtom,
  orderAtom,
} from "../../atoms";
import { filterBooths } from "../../../helper/filterBooths";
import { scrollToSecShops } from "../../../helper/scrollToSecShops";

export const SearchFormSP = () => {
  const [booths] = useAtom(boothsAtom);
  const [, setDisplayBooths] = useAtom(displayboothsAtom);
  const [inputWords, setInputWords] = useAtom(InputWordsAtom);
  const [searchInput, setSearchInput] = useAtom(searchInputAtom);
  const [selectedCategory, setSelectedCategory] = useAtom(selectedCategoryAtom);
  const [, setCurrentPage] = useAtom(currentPageAtom);
  const [, setOrder] = useAtom(orderAtom);

  const [startPos, setStartPos] = useState(0);
  const [isHide, setIsHide] = useState(false);

  //入力された文字列を受け、searchInputに保存
  const handleInputChange = (e) => {
    e.preventDefault();
    setInputWords(e.target.value);
  };

  //検索条件のリセット
  const onClickReset = (e) => {
    e.preventDefault();
    setInputWords("");
    setSearchInput("");
    setCurrentPage(1);
    setOrder("asc");
    setSelectedCategory("すべて");
    filterBooths(booths, "", "すべて", setDisplayBooths);

    scrollToSecShops();
  };

  const onClickSearch = (e) => {
    e.preventDefault();

    setCurrentPage(1);
    setOrder("asc");

    setSearchInput(inputWords);

    filterBooths(booths, inputWords, selectedCategory, setDisplayBooths);
    scrollToSecShops(selectedCategory, inputWords);
  };

  const onClickClear = (e) => {
    e.preventDefault();
    setInputWords("");
  };

  useEffect(() => {
    const handleScroll = () => {
      const width = window.innerWidth;
      if (width <= 830) {
        const scrollTop = window.scrollY;
        if (scrollTop >= startPos && scrollTop > 100) {
          setIsHide(true);
        } else {
          setIsHide(false);
        }
        setStartPos(scrollTop);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [startPos]);

  return (
    <div className={`fix-keywords-form ${isHide ? "is-hide" : ""}`}>
      <div className="fix-keywords-inner">
        <form className="keywords-form">
          <input
            className="keywords-form-box"
            type="text"
            value={inputWords}
            onChange={handleInputChange}
            placeholder="ブース名やユーザー名などを入力"
          />
          {inputWords && (
            <div className="keywords-form-clearbutton" onClick={onClickClear}>
              <i className="fa-solid fa-circle-xmark"></i>
            </div>
          )}
          <button type="submit" onClick={onClickSearch}>
            <i className="fa-solid fa-magnifying-glass"></i>
          </button>
        </form>
        {(selectedCategory !== "すべて" || searchInput !== "") && (
          <button className="header-reset-btn-sp" onClick={onClickReset}>
            <i className="fa-solid fa-xmark"></i>
            現在の検索条件をクリア
          </button>
        )}
      </div>
    </div>
  );
};
