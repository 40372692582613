import useSWR from "swr";
//ブース情報をfetchするカスタムフック

export const useFetchBooths = () => {
  const fetcher = (url) => fetch(url).then((res) => res.json());
  const { data, isLoading, error } = useSWR(
    "https://api.sssapi.app/Ok2oQC8fQsq8mj_AqU-bU",
    fetcher,
    {
      fallbackData: [],
    }
  );

  return {
    fetchedBooths: data,
    isLoading,
    isError: error,
  };
};
