import React from "react";
import { useAtom } from "jotai";
import Highlighter from "react-highlight-words";

import { useNavigate } from "react-router-dom";

import { BoothImage } from "../atom/BoothImage";
import {
  selectedCategoryAtom,
  searchInputAtom,
  currentPageAtom,
  orderAtom,
  boothsAtom,
  displayboothsAtom,
} from "../atoms";
import { filterBooths } from "../../helper/filterBooths";
import { scrollToSecShops } from "../../helper/scrollToSecShops";

export const BoothCard = (props) => {
  const { booth } = props;
  const [booths] = useAtom(boothsAtom);
  const [, setDisplayBooths] = useAtom(displayboothsAtom);
  const [selectedCategory, setSelectedCategory] = useAtom(selectedCategoryAtom);
  const [searchInput] = useAtom(searchInputAtom);
  const [currentPage, setCurrentPage] = useAtom(currentPageAtom);
  const [order, setOrder] = useAtom(orderAtom);

  const navigate = useNavigate();

  const highlightWords = searchInput.split(/\s+/);

  const onClickCategory = (e) => {
    e.preventDefault();
    const chosenCategory = e.target.innerText;
    console.log(chosenCategory);
    setCurrentPage(1);
    setOrder("asc");
    setSelectedCategory(chosenCategory);

    filterBooths(booths, "", chosenCategory, setDisplayBooths);

    scrollToSecShops(chosenCategory);
  };

  const goToDetailPage = (e) => {
    e.preventDefault();
    navigate(`/2023/booth/${booth.id}`, {
      state: {
        selectedCategory,
        searchInput,
        currentPage,
        order,
        scrollPosition: window.scrollY,
      },
    });
  };

  return (
    <>
      <div className="sec-shops-content">
        <div className="sec-shops-img" onClick={goToDetailPage}>
          <BoothImage booth={booth} />
        </div>
        <div className="sec-shops-tit">
          <span className={`sec-shops-number num_${booth.css_type}`}>
            {!booth.area && <span>No.</span>}
            {booth.area ? booth.area : booth.id}
          </span>
          <Highlighter
            highlightClassName="HighlightClass"
            searchWords={highlightWords}
            autoEscape={true}
            textToHighlight={booth.name}
          />
        </div>
        <div className="sec-shops-detail">
          <p className="sec-shops-keywords">
            {booth.categories.split(",").map((category) => {
              const trimmedCategory = category.trim();
              return (
                <a key={trimmedCategory} onClick={onClickCategory}>
                  {trimmedCategory}
                </a>
              );
            })}
          </p>
        </div>
      </div>
      <div className="sec-shops-btn">
        <a href="#" onClick={goToDetailPage}>
          詳細を見る<i className="fa-solid fa-chevron-right"></i>
        </a>
      </div>
    </>
  );
};
