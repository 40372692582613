export const categories = [
  "ワークショップ",
  "交流",
  "販売・セミナー",
  "ハンドメイド販売",
  "体験",
  "フード【飲食ブース】",
  "スイーツ【飲食ブース】",
  "子供向け",
  "ソフトドリンク【飲食ブース】",
  "アルコールドリンク【飲食ブース】",
  "健康",
  "美容・ファッション",
  "展示",
  "写真・動画",
  "IT・WEB",
  "ゲーム",
  "似顔絵・イラスト",
  "占い・診断",
  "ショー・パフォーマンス",
  "国・地域密着",
  "いきもの",
  "家計・税金",
  "リベ大グループ",
  "投資・不動産",
  "夜フェス企画あり",
  "リベ大フェス公式",
];
