import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useAtom } from "jotai";

import { boothsAtom } from "../atoms";

import { Error } from "./Error";
import { BoothOverview } from "../organism/BoothDetail/BoothOverview";
import { BoothIntro } from "../organism/BoothDetail/BoothIntro";
import { SameCategory } from "../organism/BoothDetail/SameCategory";
import { BackToTopButton } from "../atom/BackToTopButton";
import { ToPageTop } from "../organism/ToPageTop";

export const BoothDetail = () => {
  const [booths] = useAtom(boothsAtom);

  //URLに入力された数字を取得
  const params = useParams();
  const paramsId = params.id;
  //URLに入力された数字と一致するブース番号のブースを取得
  const booth = booths.find((booth) => booth.id == paramsId);

  //モバイルブラウザでのレンダリング終了後にスクロールできるように100ms待つ
  useEffect(() => {
    const timer = setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);

    return () => clearTimeout(timer);
  }, []);

  //URLに存在しないブースナンバーが入力されたら404ページを表示
  if (isNaN(paramsId) || paramsId < 1 || paramsId > 220 || booth === undefined) {
    return <Error />;
  }

  return (
    <>
      {/* タイトルの書き換え */}
      <HelmetProvider>
        <Helmet>
          <title>リベ大フェス2023 ブース検索|{booth.name}</title>
        </Helmet>
      </HelmetProvider>

      <main className="subpage-main">
        {/* ブースの概要 */}
        <BoothOverview booth={booth} />

        {/* ブース紹介 */}
        <BoothIntro booth={booth} />

        {/* 同じカテゴリから検索 */}
        <SameCategory booth={booth} />

        {/* トップ(出店一覧)ページに戻るボタン */}
        <BackToTopButton />
      </main>
      <ToPageTop />
    </>
  );
};
