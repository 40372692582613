export const booths = [
  {
    num: 1,
    id: 219,
    search_id: "H",
    name: "【公式キッチンカー】 旅するリベ大食堂",
    type: null,
    css_type: "OB",
    content:
      "両子ママとユニコーンをそれぞれモチーフにしたノンアルコールカクテルと、ミルク系ドリンク3種をご提供します！",
    pr: "味だけではなく、見た目でも楽しんでもらえるように彩りも意識しました。毎日数量限定でタピオカトッピングも用意しています。ぜひお試しください！",
    for_whom: "タピオカで気合注入されたい方や、フォトブースで記念撮影する際にぜひどうぞ！",
    night_content: "両子ママの塩対応ハイボールの販売と 数量限定のノベルティを配布予定です！",
    flyer_url: "https://drive.google.com/file/d/135RvfqKjQc4YFDfHWTpSRN4RbpGY8TE8/view?usp=sharing",
    area: "H",
    owner: "リベ大フェス運営",
    owner_url: "https://libecity.com/user_profile/RxlAIRPCQzO7QqFSblmYyxXcmu23",
    co_owners: null,
    categories:
      "ソフトドリンク【飲食ブース】,アルコールドリンク【飲食ブース】, リベ大フェス公式, 夜フェス企画あり",
  },
  {
    num: 2,
    id: 220,
    search_id: "G",
    name: "公式グッズショップ",
    type: null,
    css_type: "OB",
    content:
      "フェス限定Tシャツや学長キーホルダー、オリジナルのお菓子などフェスを楽しむグッズを全15種販売！",
    pr: "フェスオリジナルグッズが満載♪オンラインで売り切れていたあのアイテムも復活！初日で売り切れが出ないよう、各日数量限定で販売します。",
    for_whom: "家やオフ会のお土産としても大活躍！ グッズで交流が生まれたら嬉しいです♪",
    night_content: null,
    flyer_url: "https://drive.google.com/file/d/1pUUgsbs8_-e7y89eGj3ssBt1vpl5yRs1/view?usp=sharing",
    area: "G",
    owner: "リベ大フェス運営",
    owner_url: "https://libecity.com/user_profile/RxlAIRPCQzO7QqFSblmYyxXcmu23",
    co_owners: null,
    categories: "販売・セミナー, 交流, リベ大フェス公式",
  },
  {
    num: 204,
    id: 202,
    search_id: 202,
    name: "【公式】リベシティファーマーズお結び",
    type: "飲食ブース",
    css_type: "RB",
    content:
      "【リベシティのファーマーズと、来場者を結ぶ、おむすび】をコンセプトに、至福のおむすびをご提供します！",
    pr: "リベシティのお米生産農家さんのお米を使用！子どもが大好きなおかずや、大人も大満足の一品、学長のオススメまで数種類のおむすびをご用意します。一口食べるだけで思わず笑顔に！",
    for_whom: "リベ生産者さんのお米を味わいたい方、ご家族でいろんな味を楽しみたい方にも！",
    night_content:
      "夜フェス限定の特別なおむすび「両子ママの小悪魔おむすび」を提供予定！締めの一品にいかがですか？",
    flyer_url:
      "https://drive.google.com/file/d/1E4bq_j-ZNTqOdCiwL73rCE7htMCnB7oD/view?usp=drive_link",
    area: null,
    owner: "リベ大フェス運営",
    owner_url: "https://libecity.com/user_profile/RxlAIRPCQzO7QqFSblmYyxXcmu23",
    co_owners: null,
    categories: "フード【飲食ブース】,交流,リベ大フェス公式,夜フェス企画あり",
  },
  {
    num: 3,
    id: 1,
    search_id: 1,
    name: "プラナリアの顕微鏡観察〜驚異の再生力の秘密〜",
    type: "一般無料ブース",
    css_type: "NB",
    content: "驚異的な再生力をもつ『プラナリア』という生物の切断実験及び顕微鏡観察の体験。",
    pr: "真っ二つに切断したら2匹に増えるという驚異的な再生力をもつ『プラナリア』。当ブースでは、プラナリアの切断実験と顕微鏡観察を体験できます。",
    for_whom: "生物や生命科学の研究に興味がある方",
    night_content: null,
    flyer_url:
      "https://www.canva.com/design/DAFhjxrGKaE/q8ooQ9AVmuNw-0S-ZMTkQg/view?utm_content=DAFhjxrGKaE&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
    area: null,
    owner: "ノウキン",
    owner_url: "https://libecity.com/user_profile/e5jKr58xd8U6EDIiMHpWCEInnhy1",
    co_owners: null,
    categories: "いきもの",
  },
  {
    num: 4,
    id: 2,
    search_id: 2,
    name: "今日が一番伸びる日！無料姿勢診断ブース",
    type: "一般無料ブース",
    css_type: "NB",
    content:
      "身体を正面、横から撮影。\n画像を元に誰でもできるストレッチ指導を行い、健康資産を守ります。",
    pr: "姿勢診断を行い自分では分からなかった問題に気づいてもらう。\n人間は新たな視点を得なければ行動しない。\n行動させるために気づきを与えることが大事。",
    for_whom: "・無料なら受けてみたいという人\n・痛みはない人\n・姿勢が悪いと薄々感じている人",
    night_content: "ゲームのプレゼントが豪華になる！",
    flyer_url:
      "https://www.canva.com/design/DAFiPfzANV8/Nbzw4qalsvpePApBqQB4Qg/view?utm_content=DAFiPfzANV8&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
    area: null,
    owner: "むらしん",
    owner_url: "https://libecity.com/user_profile/aR45pk6YWeZPO0E1zTYSOSKc79n1",
    co_owners: "しん\nごー",
    categories: "健康, 体験, 占い・診断, 夜フェス企画あり",
  },
  {
    num: 5,
    id: 3,
    search_id: 3,
    name: "得意苦手発見の性格分析相談＆幸運へ導くタロット占い",
    type: "一般有料ブース",
    css_type: "PB",
    content:
      "1，2日目：①自分発見、②〇〇との関係改善策\n\n3日目：タロットで幸せな未来へとアドバイス",
    pr: "1，2日目\n\n①統計データから本当の自分を発見！\n\n②〇〇に響く言い換えフレーズは？\n\n3日目\n\nタロット占い歴30年の専門家が、誰よりも詳しく丁寧にあなたを占います。\n\n",
    for_whom:
      "1，2日目：自分発見、〇〇との関係を良くしたい方\n\n3日目：今より更に幸せになりたい方\n\n",
    night_content: null,
    flyer_url:
      "https://www.canva.com/design/DAFhhi5rF-8/DI63knIZJHKOkuzuEKtmow/view?utm_content=DAFhhi5rF-8&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
    area: null,
    owner: "ドキドキくろ助産師",
    owner_url: "https://libecity.com/user_profile/L5hYYpgaBRfOrZzOaS0CAlTz9Cy2",
    co_owners: "猫山リコ",
    categories: "占い・診断",
  },
  {
    num: 6,
    id: 4,
    search_id: 4,
    name: "四柱推命で背中を押させていただきます！",
    type: "一般無料ブース",
    css_type: "NB",
    content: "【無料】潜在＆顕在意識の素質・性格がわかる統計学の四柱推命で、楽しくぷち鑑定します♪",
    pr: "生年月日と性別で鑑定。\nグループぷち鑑定ではお互いの違いや反応を見て盛り上がる！ワイワイ交流しながら楽し\nめます。個別ぷち鑑定では一対一で落ち着いて鑑定できます。",
    for_whom: "何が向いているのか分からない、自分を変えたい、もっと自由に生きたい方はぜひ！",
    night_content: "一部変更になる可能性あり",
    flyer_url: "https://drive.google.com/file/d/13kE-SLeDh2iyrgR8g10cz13k_NgM8Hbx/view?usp=sharing",
    area: null,
    owner: "はっすい@四柱推命",
    owner_url: "https://libecity.com/user_profile/u0zkL7hYOEZmZHGcqp7J5C9wUmH3",
    co_owners: null,
    categories: "体験, 交流, 占い・診断, 夜フェス企画あり",
  },
  {
    num: 7,
    id: 5,
    search_id: 5,
    name: "メンタルケアもボディケアもやるorめっちゃやる！？",
    type: "一般有料ブース",
    css_type: "PB",
    content:
      "声と心の深い関係性からボイストレーナーがメンタルケアを、犬の整体を行うトレーナーがボディケアをします",
    pr: "心と身体が整えば声は出るようになる！脳科学を学んだボイストレーナーと、人も観れる犬の整体師が心と身体をほぐし、ポジティブ＆前向きなあなたになれるお手伝いをします！",
    for_whom: "これから何かにチャレンジしたい方！ポジティブ思考を手にしたい方！",
    night_content: null,
    flyer_url: "https://drive.google.com/file/d/1qFciJBC_-sceotAIKDW6e5sB6kq0xdSM/view?usp=sharing",
    area: null,
    owner: "みゆき",
    owner_url: "https://libecity.com/user_profile/6eDCcizvknUjJYg0lWJTFbKeCpq2",
    co_owners: "ちいこ",
    categories: "健康, 体験, 占い・診断",
  },
  {
    num: 8,
    id: 6,
    search_id: 6,
    name: "うっかり無意識の扉を開き人生の羅針盤が見つかる星読みメソッド",
    type: "一般有料ブース",
    css_type: "PB",
    content:
      "グループで占星術をベースとしたワーク型セッションをしてシェアし合い、自己理解と他者理解を深めます。",
    pr: "自分を深掘りして原動力となる根っこの部分を探って言語化していきます。\n自分一人では見つけ出せない答えを皆んなで一緒に紐解いていきましょう♪\n思わぬ自分を発見できるはず！",
    for_whom:
      "やりたい事が分からない方やもっと自分を知りたい、より良い人生を歩みたい方にオススメです！",
    night_content:
      "タロットや星読みに興味のある方へ、初心者向け無料質問コーナーを開催します！\n(最新情報はwebチラシをご確認ください)",
    flyer_url:
      "https://www.canva.com/design/DAFhvfFvUfo/pK55YdLiVXk8KlEsVXsu2g/view?utm_content=DAFhvfFvUfo&utm_campaign=designshare&utm_medium=link&utm_source=homepage_design_menu",
    area: null,
    owner: "みど",
    owner_url: "https://libecity.com/user_profile/N0qFWbWUuVNz8FlM5mnI0gnf13t1",
    co_owners: "おまさ@第1回フェスおむすび屋・中国輸入",
    categories: "ワークショップ, 交流, 占い・診断, 夜フェス企画あり",
  },
  {
    num: 9,
    id: 7,
    search_id: 7,
    name: "知って作って使おう！「布ナプキン」",
    type: "一般有料ブース",
    css_type: "PB",
    content: "リバティプリント生地を使用した「布ナプキン」の製作ワークショップと販売を行います！",
    pr: "可愛い柄の布ナプキンで、女性特有のブルーな日々も普段の日も心地よく！生理の話をしながら布ナプキンを製作するWSでは見える世界がちょっと変わるような話が聞けちゃうかも!?",
    for_whom:
      "自分の「心地いい」選択をしたい女性と、女の子のママ、そして家族をハッピーにしたい方へ",
    night_content:
      "ブースにてWSご参加の方、もしくは￥2000ご購入の方に先着で簡単に布ナプキンのお手入れができる「お手入れセット」を差し上げます！",
    flyer_url:
      "https://www.canva.com/design/DAFiNb7yZDk/sPm89x6B0_9OFE2H1DfRnw/view?utm_content=DAFiNb7yZDk&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
    area: null,
    owner: "あやみスーリール",
    owner_url: "https://libecity.com/user_profile/Pvz3A5hqhlYc6LsU5YdA8XEMln92",
    co_owners: "ピクサン",
    categories: "ハンドメイド販売, ワークショップ, 子供向け, 夜フェス企画あり",
  },
  {
    num: 10,
    id: 8,
    search_id: 8,
    name: "哲学・せどり・看護　日替わりブース",
    type: "一般無料ブース",
    css_type: "NB",
    content:
      "15日:哲学を通じた交流ブース、16日:せどりの無料セミナー、17日看護学生向けセミナーです！",
    pr: "毎日違うブースが登場します！",
    for_whom: "毎日ジャンルが変わりますので、いろんな方が楽しめます！",
    night_content: null,
    flyer_url:
      "https://www.canva.com/design/DAFgIvIafkg/kDEfPm6G7v4UHHr8R_9IGg/view?utm_content=DAFgIvIafkg&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
    area: null,
    owner: "たばえもん(YouTuber)",
    owner_url: "https://libecity.com/user_profile/d8f3HhGs7kUxILQNmJaQTqG0yNR2",
    co_owners: "ももも",
    categories: "ワークショップ, 体験, 交流",
  },
  {
    num: 11,
    id: 9,
    search_id: 9,
    name: "ユーザー車検 無料相談ブース",
    type: "一般無料ブース",
    css_type: "NB",
    content: "学長ライブでも度々出てくる「ユーザー車検」を無料でアドバイスするブースです。\n",
    pr: "「節約！セルフ車検部」が初出店！ベテラン車検相談員が直接ユーザー車検をアドバイスしますよ！5万円節約と点検品質をあげちゃいましょう！",
    for_whom: "ユーザー車検やってみたいけど、怖くてなかなか勇気が出ない方はおすすめです！",
    night_content: null,
    flyer_url:
      "https://drive.google.com/file/d/1iGk7jPrSyvXCErd3I56kvnZuq-CABGuK/view?usp=drive_link",
    area: null,
    owner: "ジュンイチ",
    owner_url: "https://libecity.com/user_profile/SodnpESEXqhGgjl81bGQk3QoA9B3",
    co_owners: "あけみんす",
    categories: "家計・税金",
  },
  {
    num: 12,
    id: 10,
    search_id: 10,
    name: "事業所得毎月５万円を実現！中国輸入×フリマアプリ 相談ブース",
    type: "一般無料ブース",
    css_type: "NB",
    content: "中国輸入せどり、ラクマート、メルカリの使い方について疑問、質問の相談にお答えします。",
    pr: "中国輸入せどりは、利益率が高い為、小さい資金（2～3万円）でスタートできます。始め方、代行業者の仕組み、フリマアプリへの出品、在庫・利益管理など丁寧にレクチャー致します。",
    for_whom:
      "・事業所得毎月５万円を実現したい方\n・中国輸入せどりに興味のある方\n・他の副業でなかなか結果が出ない方",
    night_content: "ラクマート限定 仕入原価の計算方法公開 ＆ Excelフォーマット無料提供",
    flyer_url:
      "https://www.canva.com/design/DAFgf4ag_Zc/mgPEBqYVHeWA0rUq6m8mRA/view?utm_content=DAFgf4ag_Zc&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
    area: null,
    owner: "ロッキー@中国輸入→リベ大フェス出展",
    owner_url: "https://libecity.com/user_profile/3iOFTDOz5fVWL6mcJyGlyl1PG3C3",
    co_owners: null,
    categories: "ワークショップ, 写真・動画, 交流, 夜フェス企画あり",
  },
  {
    num: 13,
    id: 11,
    search_id: 11,
    name: "☆スマホでフォトラボ☆",
    type: "一般無料ブース",
    css_type: "NB",
    content:
      "スマホのカメラアプリを提供。\n一度の撮影で通常の写真と顔ペタで加工した写真を同時に作成。",
    pr: "一度の撮影で普通の写真と、SNS用の顔バレ防止写真を二次加工無しで同時作成！\n簡単操作で素早く仕上がり。思い出作りの新感覚、みんなで楽しもう！",
    for_whom: "・リベ大生\n・オフ会の開催者やSNSに写真を上げる頻度の高い方",
    night_content: "Webチラシ参照",
    flyer_url: "https://drive.google.com/file/d/1RaE1fKe3OQocHUlbVbMry-Aaf0NV7cXG/view?usp=sharing",
    area: null,
    owner: "たかまさ",
    owner_url:
      "https://libecity.com/user_profile/LG9D0L7tpAMPWAnTfWaapxB5nAA2?redirect=/room_list?comment_id=1tGwwBTnPMjAFeZNceb1%26room_id=jPtIwPqtZLuMrr2W3Hlt",
    co_owners: "ゆきま",
    categories: "IT・WEB, 体験, 写真・動画, 夜フェス企画あり",
  },
  {
    num: 14,
    id: 12,
    search_id: 12,
    name: "あなたの副業パワポ助けます！～パワポ職人の流儀～",
    type: "一般有料ブース",
    css_type: "PB",
    content:
      "パワーポイントがテーマのブースです。パワポでのものづくり体験、グッズの販売を行います！",
    pr: "来場者の副業に役立つパワポの使い方や、デザインの悩みを解決する方法について発信します！\nブース装飾もすべてパワポで作成予定。パワポの力を、ブース全体で感じてください！",
    for_whom: "資料作成にお困りの方・デザインにお困りの方",
    night_content: "Webチラシを参照ください",
    flyer_url:
      "https://drive.google.com/file/d/1iwt4fyGD285spfnrn_JxEcRqTch_4Lob/view?usp=share_link",
    area: null,
    owner: "きい",
    owner_url: "https://libecity.com/user_profile/2jo7n7zWyEQw3bpjN6DMt8czrkL2",
    co_owners: "あずきー\nはな",
    categories: "販売・セミナー, ワークショップ, IT・WEB, 夜フェス企画あり",
  },
  {
    num: 15,
    id: 13,
    search_id: 13,
    name: "【非公式】リベ大検定~あなたのリベ力を測定しよう~",
    type: "一般有料ブース",
    css_type: "PB",
    content:
      "リベに関する選択式の検定を受けていただき、リベのことを楽しみながら学んでいただけます♪",
    pr: "リベ愛（変態）・リベ大（ノーマル）・お子さまの３種類のコースをご用意。参加者全員にハガキサイズの認定証（コースごとに異なるデザイン）をプレゼント！",
    for_whom: "お一人様からお友達・ご家族連れまで、リベのことが好きな方はどなたでも！",
    night_content: null,
    flyer_url:
      "https://drive.google.com/file/d/1os-wQi9zcVq7OU-TTETtCnXMnP6xFXJ9/view?usp=share_link",
    area: null,
    owner: "はるつん。＠踊る中受塾講師",
    owner_url: "https://libecity.com/user_profile/QH7saXcZQLbGRWvqoPeWDKxDuZj1",
    co_owners: "きよ｜Web制作\n\nこんどうむさし@和柄いらすと侍\nふみたろ",
    categories: "子供向け, 交流, ゲーム",
  },
  {
    num: 16,
    id: 14,
    search_id: 14,
    name: "日替わりアクセサリー〜石から畳、そして土へ〜",
    type: "一般有料ブース",
    css_type: "PB",
    content:
      "石（天然石）から畳、そして土（ポリマークレイ）へ1日ずつ日替わりでアクセサリーを販売します。",
    pr: "3日間それぞれ違ったハンドメイドアクセサリーが楽しめることに加えて、お子さまから大人まで楽しめるワークショップを展開します！",
    for_whom: "お子さま〜ハンドメイド作品が好きな全ての皆さんへ〜お待ちしております！",
    night_content:
      "16時よりワークショップにて、店長とジャンケン勝負開催！勝ったらワークショップのお値段割引！",
    flyer_url:
      "https://www.canva.com/design/DAFggo0ljJM/ZikPk0j6FepmtBYcBlUBew/view?utm_content=DAFggo0ljJM&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
    area: null,
    owner: "ふう@畳縁ハンドメイド",
    owner_url: "https://libecity.com/user_profile/AjSs90GGASemTRkTdcJw4hLJenO2",
    co_owners: "ぶっちー\nminmin",
    categories: "ハンドメイド販売, ワークショップ, 交流, 夜フェス企画あり",
  },
  {
    num: 17,
    id: 15,
    search_id: 15,
    name: "3日間楽しめる！参加型・ボディーシールブース",
    type: "一般有料ブース",
    css_type: "PB",
    content: "擦らなければ３日間取れずらいボディーシールで腕などを装飾できるブースです。",
    pr: "リベ大フェスをマッチョライオンで埋め尽くせ！日替わり学長シールコンプリートで\nユニコーンGET\nスペシャルフォトスペース\nヘアメイクkumikoフォトウェディング展\nセリタ画伯展",
    for_whom: "フェスで盛り上がりたい全ての人",
    night_content: "3日間16:00から数量限定で両子ママボディーシールを出品",
    flyer_url:
      "https://www.canva.com/design/DAFgdegfFCY/MPrv3VToeBmh6STTHHPe_Q/view?utm_content=DAFgdegfFCY&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
    area: null,
    owner: "kumiko@ブライダルヘアメイク",
    owner_url: "https://libecity.com/user_profile/dDi4rYbtdjW8jj0sAMWuYuATTAr2",
    co_owners: "とめ＠自分の強み探し中",
    categories: "販売・セミナー, 交流, 美容・ファッション, 夜フェス企画あり",
  },
  {
    num: 18,
    id: 16,
    search_id: 16,
    name: "サナ@介護ハンドメイド&アロマ／カビネット@名札制作／物販",
    type: "一般有料ブース",
    css_type: "PB",
    content:
      "📛さな@介護ハンドメイド物販📛＆アロマトリートメント\n🔥カビネット@有料名札＆非公式グッズ販売",
    pr: "【さな】アロマで癒されませんか？👐✨\n介護用ハンドメイド＆限定🦁のび～る📛ストラップ\n【カビネット】オリジナル名札📛＆ケース&出力\n委託販売等\n時間帯を分けて提供いたします",
    for_whom: "アロマで癒されたい方♡\nオリジナル名札を作りたい方♡",
    night_content: null,
    flyer_url: "https://drive.google.com/file/d/16t6IKs0wUQHBAWOCs9LyJzLwZoHIDBI4/view",
    area: null,
    owner: "さな",
    owner_url: "https://libecity.com/user_profile/yOf3XPaJAiMFCzK3NFOfiIoxPS43",
    co_owners: "カビネット",
    categories: "ハンドメイド販売, ワークショップ, 体験",
  },
  {
    num: 19,
    id: 17,
    search_id: 17,
    name: "板割り始めました！空手道の成功体験をあなたに！",
    type: "一般有料ブース",
    css_type: "PB",
    content:
      "板割り体験であなたの勇気、やる気、元気のマインドチェンジになる成功体験を経験してみませんか！！",
    pr: "空手道で行う板割りを体験し、気迫あふれる「突き」に挑戦し板を割ってもらいます。板を割る成功体験を得て、自信や勇気、高揚感あるいはストレス発散することができます。\n",
    for_whom: "リベ大フェスに参加される全ての親子(子ども)、女性、男性",
    night_content: "板割り体験ダブル(2倍2枚）※一部変更になる場合があります",
    flyer_url:
      "https://www.dropbox.com/s/frfe81nlnfps7um/%E3%83%AA%E3%83%99%E5%A4%A7%E3%83%95%E3%82%A7%E3%82%B9_%E5%BA%83%E5%91%8A_%E4%BF%AE%E6%AD%A3.png?dl=0",
    area: null,
    owner: "らいぞー",
    owner_url: "https://libecity.com/user_profile/7xeCm24klAasEvhR2S9xaiAOaox2",
    co_owners: "ラン。",
    categories: "体験, 子供向け, ショー・パフォーマンス, 夜フェス企画あり",
  },
  {
    num: 20,
    id: 18,
    search_id: 18,
    name: "リベ大ミュージックステーション(非公式)",
    type: "一般無料ブース",
    css_type: "NB",
    content: "楽器/音楽を体験する場→三味線/篠笛/三線/ギター/ジャズピアノ/ハモリ/ボイパ/DTM",
    pr: "無料で様々なジャンルの楽器/音楽に触れる！普段はできない音楽体験で音楽の楽しさを\n知ろう。音楽好きなら誰でも参加OK！初心者でも楽しめる、かんたんなレッスンもあります。",
    for_whom: "楽器や音楽に興味があるのに、楽器に触れたりレッスンを受ける機会のない方",
    night_content: null,
    flyer_url: "https://drive.google.com/file/d/1oHi4J7UA70pu9DhWYco2jMQLEQ9jKdUN/view?usp=sharing",
    area: null,
    owner: "やまたけ",
    owner_url: "https://libecity.com/user_profile/IlNlrxU2LzZvgBiR2KTm753XTuS2",
    co_owners:
      "新田知代\nやまたけ\nシン\nコージ\nゴマ\n秋雲ゆう\nつまびく\nアイリス\nうにゃら\nきょん\nまっくろしっぽ\nホミノレンジ\nヤマブキ\nよもぎきなこ\nひろたか",
    categories: "体験, 交流, ショー・パフォーマンス",
  },
  {
    num: 21,
    id: 19,
    search_id: 19,
    name: "🍊わかやま宝地図チームのふるさと納税相談室🍊",
    type: "一般有料ブース",
    css_type: "PB",
    content: "ふるさと納税についての解説と和歌山のおいしい返礼品・特産品をご紹介するブースです。",
    pr: "和歌山ならではの逸品、歴史や伝統を感じる名産から最新の技術が作り出す特産品まで、自然豊かな和歌山の美味しくて高品質な特産品を 生産者の想いとともにご紹介します！",
    for_whom: "ふるさと納税に興味がある方はもちろん和歌山のリベ大生と交流したい方も♪",
    night_content: "未定です。",
    flyer_url:
      "https://www.canva.com/design/DAFh9a8hKZU/Ksy0oxt-dTExuA2mZ-BrAw/view?utm_content=DAFh9a8hKZU&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
    area: null,
    owner: "シャム/和歌山ふるさと納税ブース出店",
    owner_url: "https://libecity.com/user_profile/qYrHhCr5EXN50AbGmnUNyvbjKTB3",
    co_owners: "いりちゃん\nいの＊はな\nきょう\nじゅんこ\nちなりん\nはったん\nダニエル",
    categories: "販売・セミナー, 国・地域密着, 家計・税金, 夜フェス企画あり",
  },
  {
    num: 22,
    id: 20,
    search_id: 20,
    name: "もりのみ色の国",
    type: "一般有料ブース",
    css_type: "PB",
    content: "沖縄の伝統工芸 琉球びんがたの染め体験ができます！びんがたの展示と販売もございます。",
    pr: "お好きなデザインを選んで、かんたん色塗り♪鮮やかな色でティーマットやマイバッグを染めます。夏休みの宿題にぴったり！家族割もありますのでご家族でぜひご利用ください！",
    for_whom: "ものづくりが好きな方、沖縄が好きな方、お子さんと一緒に体験してみたい！",
    night_content:
      "限定の「両ちゃんデザイン」が登場！リベ大フェスの記念にいかがですか？（※Webチラシ参照）",
    flyer_url:
      "https://www.canva.com/design/DAFh4Qfbpo0/C_cvlvbCwilt1o9jElCjXg/view?utm_content=DAFh4Qfbpo0&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
    area: null,
    owner: "もりのみ",
    owner_url: "https://libecity.com/user_profile/s22c3PjUxnhJ37HuXxXrWwHCufd2",
    co_owners: "もりのみ",
    categories: "ハンドメイド販売, ワークショップ, 子供向け, 夜フェス企画あり",
  },
  {
    num: 23,
    id: 21,
    search_id: 21,
    name: "台灣遊藝館（たいわんゆうげいかん）",
    type: "一般有料ブース",
    css_type: "PB",
    content:
      "台湾珈琲豆・台湾雑貨の販売と電鍋を使ったパイナップルケーキのワークショップを提供します！！",
    pr: "「台湾」の文化と魅力は台湾在住者だからこそ伝えられる！現地から文化を引っ提げ、リベ大フェスに乗り込みます！夜フェスも「台湾夜市風」で参加予定です！ブースは【21番】です！",
    for_whom: "台湾好き、可愛い雑貨好き、珈琲好き、海外旅行好きの方にぴったりです！！",
    night_content: "台湾夜市風で参加予定！\n※詳細はWebチラシ参照",
    flyer_url:
      "https://www.canva.com/design/DAFhNVDpoeE/YvdGpbjrIlRHzeORhHI26g/view?utm_content=DAFhNVDpoeE&utm_campaign=designshare&utm_medium=link&utm_source=homepage_design_menu",
    area: null,
    owner: "しー",
    owner_url: "https://libecity.com/user_profile/TC1ntV6lXjQNhGsLhtabireteUQ2",
    co_owners: "Emi\nロメオ\nとよ\nまい\nえん\nこめとぎ",
    categories: "販売・セミナー, ワークショップ, 国・地域密着, 夜フェス企画あり",
  },
  {
    num: 24,
    id: 22,
    search_id: 22,
    name: "タイ王国ブース  〜そうだ！ タイ行こう！〜",
    type: "一般有料ブース",
    css_type: "PB",
    content:
      "タイの魅力を紹介し、旅行の質問や相談にお答えします。\nタイ雑貨やアパレルの販売を行います。",
    pr: "タイが大好きなメンバーとタイ在住者によるブースです。可愛いタイ雑貨やアパレルを販売し、観光やグルメ・バンコクオフ会など、タイに関する情報を発信します。",
    for_whom: "タイの好きな方。タイに行ってみたい方。タイに興味のある方。",
    night_content: "個別の旅行相談コーナーの開設・夜フェス限定グッズの販売",
    flyer_url:
      "https://www.dropbox.com/s/3zx2mhekmocit9q/A4%E6%A8%AAweb%E3%83%81%E3%83%A9%E3%82%B70507.png?dl=0",
    area: null,
    owner: "かっぴー",
    owner_url: "https://libecity.com/user_profile/OusGNaOeQEOuJpqoCD92WmxQtHj1",
    co_owners: "マイルズ\nモーリー\nトン\nぱやお\nゆりえ\nSG",
    categories: "販売・セミナー, 交流, 国・地域密着, 夜フェス企画あり",
  },
  {
    num: 25,
    id: 23,
    search_id: 23,
    name: "ニュージーランドwithバラまみれの備後支部",
    type: "一般有料ブース",
    css_type: "PB",
    content: "ニュージーランドとオーガニックな商品の紹介、備後支部のハンドメイド作家の作品紹介",
    pr: "天然のニュージーランド産オーガニック製品。化学薬品や添加物不使用 、みんなに優しいをお届けします！  バラにまみれたハンドメイド作品も見て下さい。",
    for_whom: "ニュージーランドに興味ある方、バラまみれのハンドメイドが気になる方",
    night_content: null,
    flyer_url:
      "https://www.canva.com/design/DAFiPgL-UUM/mZ_6lc2ow2rLNVNBv6TSHQ/view?utm_content=DAFiPgL-UUM&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
    area: null,
    owner: "しー",
    owner_url: "https://libecity.com/user_profile/sZPPrVjnQmecV5pOuol7fQawOdE2",
    co_owners:
      "月のティラミス　　　　リペイント\nがう\nもち\nみるく\nきらりんつやこ\nハイブリ\nぴぴまめ\n弥生\nひつじ\n悠々自適\nこーきっぺ\nWizard\nかなママ\nウサ原ウサ子",
    categories: "ハンドメイド販売, 販売・セミナー, 国・地域密着",
  },
  {
    num: 26,
    id: 24,
    search_id: 24,
    name: "地球部が作るガイドブック・エコボトル・惑星チャーム",
    type: "一般有料ブース",
    css_type: "PB",
    content: "地球部🌎は物販！\n📖ガイドブック🪐惑星チャーム🧴エコボトル\n地球と！地球で！遊ぼう🙌",
    pr: 'あの地球部がオススメするスポットを1冊のガイドブックに！\n購入特典も満載💁‍♂️\n現地と"つながる"を🤝\nフェス限定地球部エコボトルや惑星チャームもお楽しみに！',
    for_whom: "経験にお金を使う『使う力』を身につけて人生の幸福度UPを目指そう！",
    night_content: "地球部🌏夜フェス限定グッズ販売中！\n名札やエコボトルと一緒に注目度UP🙌",
    flyer_url:
      "https://www.canva.com/design/DAFhkRvsUYw/phy97rwjB-ynvjbWXoVVoA/view?utm_content=DAFhkRvsUYw&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
    area: null,
    owner: "kensei",
    owner_url: "https://libecity.com/user_profile/zNihtMSnbjXPeVXjgJbzM43fldp1",
    co_owners:
      "ベティ\nkensei\nぽんたろう\nkit\nryu\nがっくん\nたき\nみき\nMOZA\nきなこもち\nシオン\nライス\nこののおまち\nしゅうしゅう\nひとみん\nひつちー\nぷっちょ\n想\nさんぽのひ\nみなみ\nむっきー\nはなっち\nとうか\nhana\nしゃち\nはるちん\nまさ\nラムバンディ\n元気のwako",
    categories: "ハンドメイド販売, 販売・セミナー, 夜フェス企画あり",
  },
  {
    num: 27,
    id: 25,
    search_id: 25,
    name: "サウナ・ステーション",
    type: "一般有料ブース",
    css_type: "PB",
    content:
      "サウナのような癒しの空間で、サウナデビューのはじめの一歩、サウナ仲間作りを応援します。",
    pr: "ととのうだけじゃない！サウナは仲間との交流を広げて人生を豊かにします。あなたのサウナデビューを後押し！サウナ仲間を見つける全国リベ大サウナーマップを作りましょう！",
    for_whom: "サウナを愛するすべての方へ",
    night_content: "お買い上げ特典企画などを準備中（一部変更になる可能性があります）",
    flyer_url:
      "https://www.canva.com/design/DAFgysg2FZI/ZOVLXckGu24B5WKdYL7gfg/view?utm_content=DAFgysg2FZI&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
    area: null,
    owner: "あざみ",
    owner_url: "https://libecity.com/user_profile/DnMMgISjpTYYCLRpMkVTDnR1jzn2",
    co_owners: "ジャック\nうーさん\nおすけ\nキンクマ\nさとま\nどあら\nはっくん",
    categories: "販売・セミナー, 健康, 交流, 夜フェス企画あり",
  },
  {
    num: 28,
    id: 26,
    search_id: 26,
    name: "ふじみ銃砲店　〜リアルシューティング＆鉄砲系ハンドメイド〜",
    type: "一般有料ブース",
    css_type: "PB",
    content:
      "モデルガンとシミュレータを使った本格射撃体験💥ガチの銃弾を使った職人手作りの記念品も販売中🥇",
    pr: "プロ顔負けのハイクオリティなレッスンによる射撃を限定100名様へ❗️オリンピック競技のクレー射撃も体験してみよう🙌他とは違うフェスでしか買えない記念品も絶賛販売中😊",
    for_whom: "誰でも気軽に楽しく射撃を体験😆鉄砲・狩猟ワールドに飛び込もう🐻🐗🦌🦆🦁",
    night_content:
      "鉄砲の弾をご自身の手で製造体験及びリアルハンター講座も企画中☪️詳しくは当日までのお楽しみ❗️",
    flyer_url:
      "https://www.canva.com/design/DAFhTsAEqR8/4u_cE5DseqYv32dD4mIyug/view?utm_content=DAFhTsAEqR8&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
    area: null,
    owner: "ふじみ",
    owner_url: "https://libecity.com/user_profile/6ljTDqEJhkbEYOHR4S4CPysqjK82",
    co_owners: "きよと",
    categories: "ハンドメイド販売, 体験, ゲーム, 夜フェス企画あり",
  },
  {
    num: 29,
    id: 27,
    search_id: 27,
    name: "レーシングシミュレーターブース",
    type: "一般有料ブース",
    css_type: "PB",
    content: "本格的レーシングシミュレーターでのサーキット走行をバーチャル体験していただけます！",
    pr: "サーキットでのスポーツ走行をバーチャル体験していただけるブースとなっております！本格的機材と臨場感あふれる映像により、貴方を非日常へとご案内します。",
    for_whom: "小さなお子様からお年寄りの方までどなたでも楽しめるブースとなっております。",
    night_content:
      "わっきーに挑戦！と題し、わっきーのタイムに勝てた人はプレイ料金無料とさせていただきます。",
    flyer_url: "https://drive.google.com/file/d/1cfop8PgLLz5SAW_IKsJekgpNhBAz1iwa/view?usp=sharing",
    area: null,
    owner: "わっきー",
    owner_url: "https://libecity.com/user_profile/8aILo5qwtNPYTus8YJhR5mNv8g12",
    co_owners: "ろな＠睡眠術 歯科衛生士/せどり",
    categories: "体験, 子供向け, ゲーム, 夜フェス企画あり",
  },
  {
    num: 30,
    id: 28,
    search_id: 28,
    name: "美しい素数の世界",
    type: "一般有料ブース",
    css_type: "PB",
    content:
      "素数講演で学び、素数ゲームで遊び、素数グッズを購入することができる素数が溢れるブースです!",
    pr: "素数には天才数学者達を魅了する美しくて不思議な性質がたくさんあります。素数ブースでは素数大好きスタッフから素数の魅力を直接、深く、好きなだけ聞くことができます!",
    for_whom: "・素数の美しい世界を知りたい方\n・数学の勉強仲間、先生を見つけたい方",
    night_content: null,
    flyer_url:
      "https://www.canva.com/design/DAFiO4gggUQ/b9LvbjaISZf4F3UCLyub1g/view?utm_content=DAFiO4gggUQ&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
    area: null,
    owner: "素数まつなか",
    owner_url: "https://libecity.com/user_profile/Ry5o8sOdi2hgQNpHKXhj9qJvoiz2",
    co_owners:
      "素数さとし\nねっこあっこ\nえりもん\nひよこ\nみき\nゆき\nラムバンディ\nチョコ\nあいみ\nナノハナ\nゆうな\nみー",
    categories: "販売・セミナー, ゲーム, 展示",
  },
  {
    num: 31,
    id: 29,
    search_id: 29,
    name: "おしりちゃんのぬいぐるみ服",
    type: "一般有料ブース",
    css_type: "PB",
    content:
      "海でぬい撮り、自己紹介チェキ撮影、ぬい服ワークショップができるお店。みんなで交流・お尻会い♡",
    pr: "幅1ｍの巨大撮影スポットでみんなでぬい撮り！自分やぬいをチェキ撮影して壁に貼り自己紹介！会えなかった人、未来の友達とも繋がれる！人と人とが出会い、交流を楽しむブースです！",
    for_whom:
      "ぬいぐるみが好き\n学長が好き\n交流したい\n自己紹介したい\n当てはまる方、集まれー！！",
    night_content:
      "晩酌にぴったりなエビフライ服、ビール刺繍バッチの販売。海撮影スポットを花火背景にチェンジ、花火大会を開催します。",
    flyer_url:
      "https://drive.google.com/file/d/1yERWM5Oz835DNa1J8NXpgZpaJgUMM4Oh/view?usp=drive_link",
    area: null,
    owner: "おしりちゃん",
    owner_url: "https://libecity.com/user_profile/sjC5OkDlbhMTELh3UFnKVBNEz0s2",
    co_owners: "おしりちゃん\nnico\nしぃ\nかりん",
    categories: "ハンドメイド販売, ワークショップ, 写真・動画, 夜フェス企画あり",
  },
  {
    num: 32,
    id: 30,
    search_id: 30,
    name: "ぬい活で始める愛おしいの連鎖",
    type: "一般有料ブース",
    css_type: "PB",
    content:
      "学長ライオンのぬいぐるみを、独特な世界観で可愛がるブースです。ぬい活を通しての交流ができます。",
    pr: "学長ライオンのぬいぐるみを存分に楽しめるブースです！着せ替えの服やお家で楽しめるグッズの販売、ぬいぐるみを置いて撮影できる独特なフォトブースも設置いたします。",
    for_whom: "学長ライオンが大好きな方や、個性を出したい方にピッタリです！",
    night_content:
      "レインボーなカラーが可愛いユニコーンなお洋服を販売いたします！\n特別なパンティも販売されるかも？！",
    flyer_url:
      "https://www.canva.com/design/DAFhRYwtbdE/X8ma5WqgW3bZBDJ21Wqweg/view?utm_content=DAFhRYwtbdE&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
    area: null,
    owner: "モチキチ",
    owner_url: "https://libecity.com/user_profile/wbPUVDUGixQSiRnttimaKnX4ore2",
    co_owners: "ラムバンディ\nMOZA\nきりみ\nその\nコカ\nぶぶ",
    categories: "ハンドメイド販売, 展示, 似顔絵・イラスト, 夜フェス企画あり",
  },
  {
    num: 33,
    id: 31,
    search_id: 31,
    name: "漫画家が描く！マッチョな似顔絵屋さん",
    type: "一般有料ブース",
    css_type: "PB",
    content: "漫画家が似顔絵であなたをマッチョにします。今日からあなたもマーッチョ！",
    pr: "漫画家がお一人1000円であなたをマッチョにした似顔絵を紙に描きます。今日の楽しかった笑顔、残したくないですか？残したいでしょう。漫画家の書籍も購入できます。",
    for_whom: "マッチョになりたい紳士淑女の皆々様のための似顔絵屋さんです。",
    night_content: null,
    flyer_url:
      "https://drive.google.com/file/d/1SejJ7xWF4r5-A-O34OEVmUOFvT8DDbhe/view?usp=drive_link",
    area: null,
    owner: "あんじゅ先生☆漫画家",
    owner_url: "https://libecity.com/user_profile/z8XAkJsqi5dmgvMIxALKkP7EJRP2",
    co_owners: "大塚浩史\n千葉朝日\n若林アスカ",
    categories: "似顔絵・イラスト",
  },
  {
    num: 34,
    id: 32,
    search_id: 32,
    name: "リベ大フェス限定 謎解きゲーム！～捕らわれた学長を救い出せ～",
    type: "一般有料ブース",
    css_type: "PB",
    content:
      "会場内の多様なブースを巡りながら謎解き体験！フェスを楽しみつつ同時に謎解きも遊べます！",
    pr: "謎解きを通して、参加者が会場内のブースを回ることで多くのリベ大生と交流するキッカケとなります。また謎解きが初めての方やお子様でも楽しめるようにヒント機能も充実！",
    for_whom: "謎解きに少しでも興味のある方やいろんなブースを巡りたい方にお勧めです！",
    night_content:
      "16時以降限定で謎を販売！メインの謎解きほど時間をかけず短時間で軽めに遊んでいただけます。",
    flyer_url: "https://drive.google.com/file/d/10rh4qiUDiRf06alXJXArk8DnPC1XYRSl/view?usp=sharing",
    area: null,
    owner: "ふくろう",
    owner_url: "https://libecity.com/user_profile/1ov7rjcCRwcDUiWxRnRxx6ZvGgJ3",
    co_owners:
      "シャーレ\n石野きつね\nかわ\nかわなぽ\nきいろ先生\nくらにこ\nさとま\nスクイズ\nネロ\n888(はちみつ)\nはんち\nぽてこ\nみさまる\nやそ\nやま\nりこ\nりず",
    categories: "体験, 交流, ゲーム, 夜フェス企画あり",
  },
  {
    num: 35,
    id: 33,
    search_id: 33,
    name: "低カフェイン抹茶とおとんの庭の茶道体験",
    type: "一般有料ブース",
    css_type: "PB",
    content:
      "低カフェイン抹茶の茶道体験。抹茶を点てるほか〇〇を比較、お茶室の小さな入口から入る体験も楽しめます！",
    pr: "おとんの庭の風変わりな茶道体験は、大正浪漫の雰囲気に包まれた空間で、一期一会のひとときを提供いたします。低カフェイン抹茶と鉄瓶で沸かしたお湯を使い健康にも配慮しています。",
    for_whom:
      "低カフェインに興味のある方、伝統文化に触れてみたい方への非日常的な空間です。お一人おひとり大切におもてなしいたします。",
    night_content:
      "【一部変換になる可能性があります。】\n光をダウンライトして夜空や蛍を彷彿させる非日常的な空間をお届けいたします。また、椅子を除きゴザを敷いて昼間とは違った雰囲気に！",
    flyer_url:
      "https://www.canva.com/design/DAFikL0e53k/eDNuE5o8efFQkrr_app7zQ/view?utm_content=DAFikL0e53k&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
    area: null,
    owner: "おとんの庭の茶道体験🍵",
    owner_url: "https://libecity.com/user_profile/EbOPkXV01RgtfdiDU3R7MXPgFiw1",
    co_owners: "ちよみ",
    categories: "健康, 体験, 夜フェス企画あり",
  },
  {
    num: 36,
    id: 34,
    search_id: 34,
    name: "ゆかたで思い出♪着物好きブース",
    type: "一般無料ブース",
    css_type: "NB",
    content: "ゆかた姿で記念撮影。\n水引などの和小物作り体験。\nスマホで参加ノベルゲーム。",
    pr: "ゆかたをご用意していますので着物好きスタッ\nフが着つけと撮影します。ゲームはオリジナル\nストーリーで展開、来場者全員参加できます。\nスタッフ一同、ゆかたで皆様をお迎えします。",
    for_whom: "ゆかたを自分で着られるようになりたい方はぜひご相談ください♪",
    night_content:
      "写真館では両子ママと撮影できるフレームを用意します。他は「Webチラシ参照」ください。",
    flyer_url: "https://drive.google.com/file/d/1NxXhtX4eB1GYAraDJykgCb1PEO2kXV6Z/view?usp=sharing",
    area: null,
    owner: "あやぽん",
    owner_url: "https://libecity.com/user_profile/jiOBVOtbxnbuld37BkQ0WvhSZ8n2",
    co_owners:
      "るーふぁ\nみみさん\nあい\nあっこちゃん\nあり\nアリコ\nアルボ\n兎月（うづき）\nえん\nおから\nオケイ\n尾張（おわり）ますまろ\nきほ\nごろ猫\nさちょ\nさらさ\n純子\nすずね\nタイトン\nちぃちゃんラボ\nちどりん\nちひろ\nつくも\nなーちん\nなたほ\nにこナオ\n花緒（はなお）\nばばっち\nまなみん\nみさってぃ\nみつ子\nみつばうむ\nメリー\nりん",
    categories: "ワークショップ, 写真・動画, ゲーム, 夜フェス企画あり",
  },
  {
    num: 37,
    id: 35,
    search_id: 35,
    name: "和喜逢々〜和小物と刺繍と蜜蝋ラップのお店〜",
    type: "一般有料ブース",
    css_type: "PB",
    content:
      "ハンドメイド商品(つまみ細工、刺繍、蜜蝋ラップ、羽織紐)の販売とワークショップを行います。",
    pr: "小学１年生のメンバーもゼロ⇨イチ達成に向けて、奮闘中！\n小さな手から生まれる心温まる作品をお届けします！\nワークショップもあるので気軽に遊びに来てくださいね🎶",
    for_whom: "ハンドメイドの販売を目指している方！\n老若男女関係なくゼロ⇨イチはできますよ！",
    night_content: null,
    flyer_url:
      "https://www.canva.com/design/DAFh5ooSRBY/G5DcxwJWKFjTae3Uaufosw/view?utm_content=DAFh5ooSRBY&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
    area: null,
    owner: "REIRIN",
    owner_url: "https://libecity.com/user_profile/N2vetwbYTNXGDecsKxYgjZa7qZo2",
    co_owners: "天才てんとう虫\nあや\nリュータ\nれいか\nよしはる",
    categories: "ハンドメイド販売, ワークショップ",
  },
  {
    num: 38,
    id: 36,
    search_id: 36,
    name: "宝石の絵と書〜色と墨のハーモニー〜",
    type: "一般有料ブース",
    css_type: "PB",
    content:
      "宝石の絵、書の展示グッズ販売\n水彩画体験\n学長の名言うちわ書道体験\n学長を宝石にしてみた展示、動画有",
    pr: "10年の宝石検品者が描く宝石の絵と書道家さんとコラボ作品\n宝石の水彩画体験\n書で学長の名言うちわ制作体験\n学長を宝石にしてみた！（油彩で描いた宝石に学長の顔）展示と制作過程動画",
    for_whom: "絵画、書道、宝石、天然石、学長名言、水彩画体験に興味のある方へ\n",
    night_content: null,
    flyer_url:
      "https://www.canva.com/design/DAFhB7Tklwk/157CDbEt73HxxE_1JWS7Zw/view?utm_content=DAFhB7Tklwk&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
    area: null,
    owner: "ともあ@宝石絵師/36番ブース",
    owner_url: "https://libecity.com/user_profile/ln111b3h2oQ4MTjlT31vrB46VFe2",
    co_owners: "ハレミブース36、師範の上！成範書家",
    categories: "ワークショップ, 展示, 似顔絵・イラスト",
  },
  {
    num: 39,
    id: 37,
    search_id: 37,
    name: "リベ大書道部",
    type: "一般有料ブース",
    css_type: "PB",
    content: "プロによる実演や書道体験、作品の展示販売などをする書道を身近に感じられるブースです",
    pr: "プロの書道家の筆捌きを直近で感じたり、誰でも書道体験ができたりします。オリジナルの作品も多数取り揃えており、書道の魅力を体験して素晴らしい作品を手に入れてください！",
    for_whom: "書道作品の展示販売、書道家による展示販売、プロの書道家によるワークショップ",
    night_content: null,
    flyer_url: "https://drive.google.com/file/d/1B15eNnHlhYT4ty1olduuCG9Qqc1YkbEx/view?usp=sharing",
    area: null,
    owner: "ほりほり@書道",
    owner_url: "https://libecity.com/user_profile/OcDMV4HymvVf8Io5ZhJwgqoySDx1",
    co_owners:
      "けいこう@書道\nけいこう\nまゆふく\nジーヤマ\nNotたくや\nミノタマゴ\nともこ\nkappa\nうゆう\nほうれん草娘。\nまちゃ\nみお\nぽん太\nあやぽん\nのっち\nみんせい\nコ　ハ　ナ\nばくちゃん\nティー \nよんにゃ\nまんぼう",
    categories: "ハンドメイド販売, ワークショップ, 展示",
  },
  {
    num: 40,
    id: 38,
    search_id: 38,
    name: "使う力のお稽古場🔥水引アクセサリー＆温浴刺繍＆はんこ彫り体験",
    type: "一般有料ブース",
    css_type: "PB",
    content:
      "ハンドメイド品(水引アクセサリー、温浴刺繍、限定グッズ)の販売とはんこ彫り体験を行います！",
    pr: "ハンドメイドを副業にする2名と印章店を営む店主1名のブースです。学長モチーフの作品もフェス限定で販売。こちらは原価や制作時間を開示し、お値段は皆様に決めていただます！",
    for_whom: "ハンドメイドが好きな方、はんこ屋さんから話を聞きたい方、使う力を鍛えたい方",
    night_content:
      "両子ママのハンコを顔や手におして楽しもう♪インクは肌に優しく水で落ちやすいものを用意しています。",
    flyer_url:
      "https://www.canva.com/design/DAFhNIt1tjs/cOsxVMWbwQvVip2ve2yV7Q/view?utm_content=DAFhNIt1tjs&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
    area: null,
    owner: "Miroku",
    owner_url: "https://libecity.com/user_profile/8ZDPvv6ngMfrCQ2haNdmMm1VNVs2",
    co_owners: "ツバキ\nふろまど",
    categories: "ハンドメイド販売, ワークショップ, 交流, 夜フェス企画あり",
  },
  {
    num: 41,
    id: 39,
    search_id: 39,
    name: "【ブログ部でおなじみのブロガー大集合】 初心者相談ブース",
    type: "一般有料ブース",
    css_type: "PB",
    content:
      "ブログチャットでお馴染みのメンバーが質問会やプチ勉強会を開催！初心者の悩みを解決します。",
    pr: "10名を超えるブロガーがフェス限定で大集結！様々な経歴を持つブロガーからアドバイスがもらえるブースです。フェスでしか聞けないプチ勉強会でモチベ爆上がり間違いなし",
    for_whom: "ブログの稼ぎ方、モチベ維持の方法、成功体験を知りたい初心者向け",
    night_content:
      "ブログで累計36億稼いだブロガーこまめさんによる夜フェス限定の勉強会を開催します。（一部変更になる可能性があります）",
    flyer_url:
      "https://www.canva.com/design/DAFh0egMmAI/Ycs865gWVVlvMIf0NvUEFw/view?utm_content=DAFh0egMmAI&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
    area: null,
    owner: "ひとみみ",
    owner_url: "https://libecity.com/user_profile/UAt4PZAeDyZxqjDQBFqfcHs9Jj23",
    co_owners:
      "スーヒロ\nひとみみ\nおんた\nたけA\nりょうこ\ntaka\nタケB\nぽこ\nこまめ（河野裕）\nくまはち\n西山哲太郎\nNむら\nやびー",
    categories: "ワークショップ, IT・WEB, 交流, 夜フェス企画あり",
  },
  {
    num: 42,
    id: 40,
    search_id: 40,
    name: "Youtubooth！",
    type: "一般無料ブース",
    css_type: "NB",
    content:
      "YouTubeで月5万円稼ぎたい人、大きく稼ぎたい人に対して、経験者が稼ぎ方の相談に乗るブースです",
    pr: "「YouTubeで月5万円を稼ぐ！」をコンセプトに、YouTubeで月5万円を稼ぐ方法の相談に乗ります！\n実際に稼いでいる人から直接話を聞けるので是非お立ち寄りください。",
    for_whom: "これからYouTubeで月5万円稼ぎたい方\nYouTubeで月100万稼ぎたい方",
    night_content: "実際にYouTubeでチャンネル運営をしている方を集めて座談会を行います。",
    flyer_url:
      "https://www.canva.com/design/DAFgjD4vfmk/0QTXwPo33I8zZFT04OQ7bg/edit?analyticsCorrelationId=c6f62226-d36f-4ff1-bfda-3c5b4a2a0db4",
    area: null,
    owner: "ジナン",
    owner_url:
      "https://libecity.com/user_profile/e54k6zVZqIdp5QcyXTaUUhz1G5X2?redirect=/search_user?",
    co_owners: "アニィ\nミニマリストゆみにゃん\nおいちゃん\nあ～どう\nともや\nRuirui\nすな",
    categories: "販売・セミナー, ワークショップ, 夜フェス企画あり",
  },
  {
    num: 43,
    id: 41,
    search_id: 41,
    name: "チームグラレコ　名札グラレコ持ってオフ会行きや〜！",
    type: "一般無料ブース",
    css_type: "NB",
    content:
      "名札の裏に入れる自己紹介カードをその場で描いてお渡し。フェスだけでなく、オフ会にも活用できます！",
    pr: "名札の裏が真っ白な人、必見！あなたの強み、目標をその場でわかりやすく描いてお渡しします。稼ぐ力を高めるグラレコ付き講座も実施！フェスの後でも役立つこと間違いなし！",
    for_whom: "名札の裏を充実させたい方、グラレコの効果について知りたい方",
    night_content: "16時以降はゆっくりとお話を伺いながら名札グラレコを作成することができます。",
    flyer_url:
      "https://www.canva.com/design/DAFgBIrF0nA/Nn3fu1kuSPtLjg1z0AhroQ/view?utm_content=DAFgBIrF0nA&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
    area: null,
    owner: "フェニ/名札グラレコブース✍🏻🎉",
    owner_url: "https://libecity.com/user_profile/M6HAPesiIBRVSwsYRyz1hvZl7Hm1",
    co_owners:
      "コロコロネ\nフェニ\nいとりん\nじゅんき\nあーちん\nすみれ\nやそえ\nおかわり\nももんが",
    categories: "ワークショップ, 交流, 似顔絵・イラスト, 夜フェス企画あり",
  },
  {
    num: 44,
    id: 42,
    search_id: 42,
    name: "せどCafe リベ大フェス店",
    type: "一般無料ブース",
    css_type: "NB",
    content:
      "稼ぐ力(主にせどり)実践中の先輩が「交流」「相談会」「勉強会」を通じてあなたを応援します♪",
    pr: "せどりに取り組む人たちのコワーキングスペースをコンセプトにシティ内で営業中の「せどCafe」リアル版！誰でも気軽に交流できる優しい雰囲気をぜひ体験しに来てください♪",
    for_whom: "稼ぐ力に興味がある方、せどりを頑張っている/これから頑張りたい方",
    night_content: null,
    flyer_url:
      "https://www.canva.com/design/DAFiNuSXtPo/jLWmElJlC4TJicFgBR1VKA/view?utm_content=DAFiNuSXtPo&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
    area: null,
    owner: "シゲオ",
    owner_url: "https://libecity.com/user_profile/8T2ltVWESbezMXSrfvg9QgsZftX2",
    co_owners: "まつ１\nさーち",
    categories: "ワークショップ, 交流",
  },
  {
    num: 45,
    id: 43,
    search_id: 43,
    name: "薬局がおすすめするスキンケアと肌に優しい化粧品",
    type: "一般有料ブース",
    css_type: "PB",
    content:
      "防腐剤など添加剤の少ないCAC化粧品を販売します。体を洗う際のスキンケア教室も予定しています。",
    pr: "CAC化粧品は1回使いきりで防腐剤未使用の肌に優しい商品です。当日は化粧水と洗顔フォーム・泡立てネットなどをセットにしたスターターキットを販売予定です",
    for_whom: "敏感肌で化粧品やシャンプー選びに困っている人にスキンケアを含めて紹介します。",
    night_content: null,
    flyer_url:
      "https://www.canva.com/design/DAFiJye_W8g/77nAVuoqoCPv_e700ko0jw/view?utm_content=DAFiJye_W8g&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
    area: null,
    owner: "るうき",
    owner_url: "https://libecity.com/user_profile/jUZk4SbchAbHllxnUUFyy5hnqWw2",
    co_owners: "るうき\nやー",
    categories: "販売・セミナー, 体験, 美容・ファッション",
  },
  {
    num: 46,
    id: 44,
    search_id: 44,
    name: "【稼ぐ力UP】第一印象を上げる写真撮影とプロフ添削ブース",
    type: "一般有料ブース",
    css_type: "PB",
    content:
      "「第一印象で損してませんか？」稼ぐ力を高めるプロフ写真&自己紹介文で貴方の魅力を引き出します！",
    pr: "第一印象で心をつかむ❗️プロ集団による「プロフィール写真撮影」「身だしなみアドバイス✨」「SNS・HPのプロフィール・エントリーシート添削」で全力応援します！",
    for_whom: "稼ぐ力を高めたいリベ大生。オフ会・イベント等で良い印象で覚えて欲しい方",
    night_content:
      "①友達複数人で記念撮影OK！②写真撮影注文でフォトフレーム合成無料サービス③悩み相談室（SF解説・副業・美容）",
    flyer_url: "https://drive.google.com/file/d/1S3JsawS6gkE0K3p-fkw1R7QhorSMitKS/view?usp=sharing",
    area: null,
    owner: "えりた",
    owner_url: "https://libecity.com/user_profile/MPfx4QoVRkOEG9HJ2hD7jnYbPHq1",
    co_owners: "めめちゃ\nさよ\nみずの\nこじま\nさらり\nくんさん\nヨーコ\nぶっさん\nはっぱ\nもりみ",
    categories: "写真・動画, 交流, 美容・ファッション, 夜フェス企画あり",
  },
  {
    num: 47,
    id: 45,
    search_id: 45,
    name: "健康資産と美的資産を増やそう♬輝く人生のための健美ブース！",
    type: "一般有料ブース",
    css_type: "PB",
    content: "1日目は健康資産を高める施術体験、2〜3日目は美的資産を増やす施術体験を提供します！",
    pr: "身体メンテナンスのプロ集団が辛い体の症状を改善し健康な状態にして、色彩と美姿勢のプロが似合う色を診断し美しい姿勢へと導きます！",
    for_whom: "肩こり腰痛など辛い症状でお悩みの方、似合う色が知りたい方、猫背が気になる方",
    night_content:
      "16時以降ご来店の方は施術後にお好きなポーチプレゼント、もしくは施術時間を10分延長させていただきます！",
    flyer_url:
      "https://www.canva.com/design/DAFiszs7JcA/5GRFymueRY2tgIdJbqkNgA/view?utm_content=DAFiszs7JcA&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
    area: null,
    owner: "はせ",
    owner_url: "https://libecity.com/user_profile/RXdJU0E4TlYka3OMD5EqX8gWRh32",
    co_owners: "ベル\nあや\nこたけん\nはりゅまま",
    categories: "健康, 体験, 美容・ファッション, 夜フェス企画あり",
  },
  {
    num: 48,
    id: 46,
    search_id: 46,
    name: "ブティックかっぱちゃん",
    type: "一般有料ブース",
    css_type: "PB",
    content:
      "ファッションコーディネートの相談、パーソナルカラー診断。オリジナルデザインの洋服の物販",
    pr: "プロによるファッションコーディネートやパーソナルカラー診断で魅力を引き出します。現役ファッションデザイナーによるリベフェス限定のオリジナル洋服も販売！",
    for_whom: "普段着る洋服に悩んでいる方、自分に似合うスタイルを見つけたい方",
    night_content:
      "「恋愛xデートコーデ」恋愛トークをしながらデート服をアドバイスしちゃいます。甘口でアドバイスしたり、希望の方は両子ママばりの辛口トークも炸裂するかも笑（一部変更になる可能性があります）",
    flyer_url:
      "https://www.canva.com/design/DAFiMwBVU4U/lrzJRDkcKAqqMkawY047pQ/view?utm_content=DAFiMwBVU4U&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
    area: null,
    owner: "かっぱちゃん",
    owner_url: "https://libecity.com/user_profile/ngghbEPvCHUp0GbuflWvVUNv8Hn2",
    co_owners: "たぬちゃん\nエル\nけの\nみく\nmima",
    categories: "販売・セミナー, 体験, 美容・ファッション, 夜フェス企画あり",
  },
  {
    num: 49,
    id: 47,
    search_id: 47,
    name: "【ブランディングラボ】骨格診断・個性診断・プロフ商品撮影",
    type: "一般有料ブース",
    css_type: "PB",
    content:
      "スタイリスト・フォトグラファー・個性診断のプロが集まったブースです。よりよく見せる方法を伝えます！",
    pr: "写真のクオリティーで商品の価格が変わるのを知っていますか？身だしなみを変えると信頼度が増すことを知っていますか？自分をブランディングする事による変化を体験してください。",
    for_whom: "商品やサービス、SNSのブランディング、ご自身の方向性に悩んでいる方。",
    night_content: null,
    flyer_url:
      "https://drive.google.com/file/d/1VUAptxWaaQWHk4QD55HbeuQI4kO4Cy5F/view?usp=share_link",
    area: null,
    owner: "Chika@フォトグラファー",
    owner_url: "https://libecity.com/user_profile/9wn9a8UqLeNf03yVwKuCEDQa7om2",
    co_owners:
      "ひとみん👗👕専属スタイリスト\n\nえりぽん@副業宅建士&個性診断士\nみっつ♪パーソナルスタイリスト",
    categories: "写真・動画, 美容・ファッション, 占い・診断",
  },
  {
    num: 50,
    id: 48,
    search_id: 48,
    name: "漫画でわかる！副業初心者が月5万円稼ぐ方法【稼ぐマンガ部】",
    type: "一般有料ブース",
    css_type: "PB",
    content:
      "各事業で稼いでいる6名のアドバイスをもとに、副業初心者が月5万円稼ぐ方法を漫画にして販売！",
    pr: "副業初心者さん必見！月5万円稼ぐためのノウハウ満載！副業でつまづくポイントやその解決策、稼ぐためのマインドをわかりやすく漫画で解説！この漫画で人生が変わるかも？",
    for_whom: "副業で月5万円稼げない＆副業選びに悩んでいる＆自由を手に入れたい方々",
    night_content: null,
    flyer_url:
      "https://www.canva.com/design/DAFiGseXf_I/bgPeQBri8P7IzCm_gvfUrQ/view?utm_content=DAFiGseXf_I&utm_campaign=designshare&utm_medium=link&utm_source=viewer",
    area: null,
    owner: "ぷみら",
    owner_url: "https://libecity.com/user_profile/gi3Aevr74CQSh2F7i3T9ze0tz9J2",
    co_owners:
      "ババレオ\nタネコ\nアカルパ\nわらぎ\nえふろく\n薫子\nあさい\nハルマキ\nもりかわichi\nちょこみんと\nえりぼん\nよよい\nウバタミ\nゑ宮",
    categories: "販売・セミナー, 写真・動画, 似顔絵・イラスト",
  },
  {
    num: 51,
    id: 49,
    search_id: 49,
    name: "ビジネスパートナーを見つけながらプロのセミナーも聞ける交流会",
    type: "一般無料ブース",
    css_type: "NB",
    content: "ビジネスパートナーを求める方必見！\n23名のプロによる勉強会&交流会を開催します！",
    pr: "テーマに興味がある方々との交流会&勉強会を実施。\n23名のプロから学び交流を深めていくブースをお届け。\n一人では生み出せないビジネスを誕生させるチャンスがここにある！",
    for_whom: "新しいビジネスパートナーを探している方、ビジネススキルを向上させたい方",
    night_content: null,
    flyer_url:
      "https://www.canva.com/design/DAFfjZ9EBzY/PtJyTH4CMs2vWdfOV3g8kQ/view?analyticsCorrelationId=0324f136-e64a-4d43-a2b3-20c8367a38f7",
    area: null,
    owner: "よし",
    owner_url: "https://libecity.com/user_profile/qMpZskvyejMN3mSzvog6p4W3fuR2",
    co_owners:
      "くるみん\nまめ\nたかの\nはなこ\nグリフィー\nMASA\nババレオ\nバッチョ\nあーい\nギーニャス\nヤギシタ\nせいじぃ\nかんく\nブルーノ\nわってぃー\n༒クローム༒\nダスラー\nこーこー\nおけもん\nちょっちー\nかなこ",
    categories: "ワークショップ, 交流",
  },
  {
    num: 52,
    id: 50,
    search_id: 50,
    name: "【稼ぐ力をサポート！】ITお悩み相談by福岡クリエイターズ",
    type: "一般無料ブース",
    css_type: "NB",
    content:
      "あなたの事業のお悩み、聞かせてください！WebやITを活用して更に稼ぐ力をUPさせましょう！",
    pr: "①稼ぐ力をUPさせる様々な分野のプロが集結！\n②WebやITのプロに無料で質問し放題！\n③現役デザイナー、エンジニア、マーケターによる座談会（ぶっちゃけトーク）開催！",
    for_whom: "悩みが漠然としている方、WebやITの活用方法が分からない方も大歓迎！",
    night_content: "16時以降に日替わりテーマで座談会を開催予定！詳細はWebチラシのQRコードから。",
    flyer_url: "https://drive.google.com/file/d/1vkbSi-KR9E7ErQk2mRRwRSMjUSjQ9zIv/view",
    area: null,
    owner: "のっはー",
    owner_url: "https://libecity.com/user_profile/nnShWCzvM6XlZJc6T1db7NIjs0g2",
    co_owners:
      "吉岡\nのっはー\nとし\nおこじょ\nJin\nなおなお\nむね\nkei\nメケ\nもろいー\nてらさん\nゆのみん\nいくま\nじんちゃま\nザッキー\nみんみ\nももったい\nまるちゃん\nみゅんてぃん\nサト\nけんけん\nミオバンビ\nもちみな\nトノ\nあかりん\nAlice\nちゃー\nメープル",
    categories: "IT・WEB, 交流, 夜フェス企画あり",
  },
  {
    num: 53,
    id: 51,
    search_id: 51,
    name: "憩いのコミュニティスペース　feat.リアル自習室",
    type: "一般無料ブース",
    css_type: "NB",
    content:
      "・気軽にリベ市民と交流・雑談ができる！\n・各分野のスペシャリストにお悩み相談ができる！",
    pr: "『唯一、仲間ができる！』ブース。\nリラックスできる空間で、雑談を通じてオフ会気分を味わおう。\n「憩い×お悩み相談」で交流が生まれ、新しい価値観に出会えるかも！？",
    for_whom: "オフ会未経験・ROM専の方はリベ活の一歩目にぜひ！気軽に交流したい方も歓迎",
    night_content: null,
    flyer_url:
      "https://drive.google.com/file/d/1X7kRtqkyUbgKScmMYZSOUiHueU8sr9mB/view?usp=share_link",
    area: null,
    owner: "たかみ",
    owner_url: "https://libecity.com/user_profile/whCMqpPpCLVIhUG8Qa5wVEgdiSM2",
    co_owners: "あき\nかなみ\nいくま\nサンダー\nいちぱぱ\nちーまる\nおさしみ\nばん",
    categories: "交流",
  },
  {
    num: 54,
    id: 52,
    search_id: 52,
    name: "ぼっちのつどい「ひとり参加の方へ　ここで仲間が作れますよ♪」",
    type: "一般無料ブース",
    css_type: "NB",
    content: "ぼっちでフェス参加した人者同士をつなぎグループを作ります。仲間作りをサポートします！",
    pr: "明るい親しみやすい雰囲気で気軽に仲間作りを全力サポート！サイコロで話題を提供したりスタンプラリーで一緒に他ブースを回ります！楽しみながら自然と仲が深まります！",
    for_whom: "ぼっちで参加する人のための「素敵な仲間」に出会うサポートをするブース",
    night_content: null,
    flyer_url:
      "https://www.canva.com/design/DAFhBXSLhQs/9CMkSLefbOslIeuHcHg2mg/view?utm_content=DAFhBXSLhQs&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
    area: null,
    owner: "やむちゃライター",
    owner_url:
      "https://libecity.com/user_profile/jdKoInfTvihKyBdLnModiq6Q3AS2?redirect=/room_list?comment_id=jKzZJaQQLFoa6g4GMGBo%26room_id=ONotiTrbegKmvlOu0rfC",
    co_owners: "ふくちゃん\nちょり\n４４２３（ヨシフミ）\nゆずぽん",
    categories: "交流",
  },
  {
    num: 55,
    id: 53,
    search_id: 53,
    name: "【リベ大税理士法人】税金相談＆税金セミナー",
    type: "一般無料ブース",
    css_type: "NB",
    content:
      "優しい税理士による個別の無料税務相談と初心者でもわかりやすい講義形式の税金セミナー実施！",
    pr: "有料級の税務相談15分を無料で実施！\n些細なお悩みもこの機会に是非！\n税金に関するセミナー開催、3つのテーマを税理士が分かりやすく解説！",
    for_whom: "税金の悩みがある人や自分の事業が税理士に依頼すべき規模なのか知りたい人",
    night_content: null,
    flyer_url:
      " https://drive.google.com/file/d/1j1hF4CEEZnN7Q2MPHCx0qI4sljQgVNGN/view?usp=sharing",
    area: null,
    owner: "みちひろ@税理士",
    owner_url: "https://libecity.com/user_profile/9GAdcrJcltV7SNVodiz8qoqLACx1",
    co_owners: "マイルくん\n税務アシスタントチーム一同",
    categories: "販売・セミナー, 家計・税金, リベ大グループ",
  },
  {
    num: 56,
    id: 54,
    search_id: 54,
    name: "親子でまなぶ！お金のハナシ",
    type: "一般有料ブース",
    css_type: "PB",
    content: "親子が楽しくお金について学べるコンテンツを用意してお待ちしてます！",
    pr: "こどもたちによる事業主体験、お金のゲーム実践、桃鉄教育版体験会、また、全国各地の学校に呼ばれて登壇している大河内薫のお金の授業など、見どころ盛りだくさんです！",
    for_whom: "お金に強くなりたい親子…だけでなく、すべての親子へ！",
    night_content:
      "「税理士大河内薫のスナック臨時相談」in両子ママの夜フェス（一部変更の可能性あり）",
    flyer_url:
      "https://www.canva.com/design/DAFiMQRU4qc/qaQMtIajIUqW0BryBJ6PLg/view?utm_content=DAFiMQRU4qc&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
    area: null,
    owner: "大河内 薫",
    owner_url: "https://libecity.com/user_profile/QzvKN3spbNVCCL6EU2VQSxa8mIz1",
    co_owners: "みゆ",
    categories: "ワークショップ, 子供向け, 家計・税金, 夜フェス企画あり",
  },
  {
    num: 57,
    id: 55,
    search_id: 55,
    name: "独立系FPのお金の相談所",
    type: "一般有料ブース",
    css_type: "PB",
    content: "独立系FPと独立系FPを目指す面々が、個別相談とセミナーで、お金の悩みをズバッと解決！！",
    pr: "お金のモヤモヤ解決しませんか？\n誰に相談していいかわからないお金の悩み相談を、独立系FPが有料で承ります。\nお金に関する『聞かなきゃ損』なミニセミナーも開催！",
    for_whom: "お金のことで不安や悩みがあるけど、誰に聞いていいかわからない人",
    night_content: null,
    flyer_url:
      "https://www.canva.com/design/DAFiApofp18/9dOHyHIiaxbK3-ChV_Yyog/view?utm_content=DAFiApofp18&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
    area: null,
    owner: "きよみん",
    owner_url: "https://libecity.com/user_profile/qTrohb3jzpZUpmoF6DxePUVUzyn1",
    co_owners:
      "ばんせい\nちゃみ\nぐる\nやまさん\nオリーブ\nなかまる\nsugachan\nかなめ\nとびからいも\nまんまる\nケンケン\nなおぼん太郎\nまのん\nJUN\nべーよん\nピロシキ\nアツキ\nAKARI.M\nCFPねこか",
    categories: "販売・セミナー, 投資・不動産, 家計・税金",
  },
  {
    num: 58,
    id: 56,
    search_id: 56,
    name: "世界初！「アパレルステッカー」体験ブース！",
    type: "一般無料ブース",
    css_type: "NB",
    content:
      "好きなデザインを服の好きな場所に貼れる！\n独自開発の「アパレルステッカー」を1万枚無料配布！",
    pr: "薄くて洗濯しても剥がれない「アパレルステッカー」\n好きなアパレルステッカーを作れるアプリ「STIQ」は2023年内リリース予定！\n「圧倒的ワクワク」を世界初解禁します！",
    for_whom: "限定デザインのアパレルステッカーを貼ってリベ大フェスで目立っちゃおう！",
    night_content:
      "「夜フェス」時間帯限定のアパレルステッカーを配布予定！\n詳細はWebチラシでお知らせします♪",
    flyer_url: "https://www.cp.nship.net/libefes2023/web_flyer.png",
    area: null,
    owner: "ひさ",
    owner_url: "https://libecity.com/user_profile/ZIsywnPgIfhGHWS3sTnCW8CBvaw2",
    co_owners: "おかもん",
    categories: "販売・セミナー, ワークショップ, IT・WEB, 夜フェス企画あり",
  },
  {
    num: 59,
    id: 57,
    search_id: 57,
    name: "物販で稼ぐ力爆上げ！〜ブランド品販売・OEM・出品サポート〜",
    type: "一般有料ブース",
    css_type: "PB",
    content:
      "・ブランド品の直接販売会＆交流\n・ブランドせどりの出品〜販売サポート\n・ブランドOEM開発",
    pr: "ブランドじぇいそんとリアルに出会える”フェスならでは”のブース！\nブランド品を直接、仕入れ可能な絶好の機会！\nさらに！！！\n購入商品を即出品できるサポートブースを設置！",
    for_whom: "・ブランドせどりに挑戦したい方\n・せどりをはじめて0-1達成できてない方",
    night_content:
      "・『ブランドせどりガチャ』のワクワク仕入れ！\n→夜フェス時間は大当たりの確率UP！\n・ブランドじぇいそんグッズの限定販売！",
    flyer_url:
      "https://drive.google.com/file/d/153YL6gklO-EyyM2Z-TtPDD7-RVks9sn5/view?usp=share_link",
    area: null,
    owner: "ブランドじぇいそん",
    owner_url: "https://libecity.com/user_profile/vjpCLMXSDDT36lLurOSEgOqcoFt1",
    co_owners: "やし",
    categories: "販売・セミナー, 写真・動画, 交流, 夜フェス企画あり",
  },
  {
    num: 60,
    id: 58,
    search_id: 58,
    name: "まずは挑戦！ドローン釣堀体験２",
    type: "一般有料ブース",
    css_type: "PB",
    content:
      "ドローンを操作してお菓子をゲットしよう！お子様から大人まで楽しめるドローン体験ブースです！",
    pr: "昨年出店したドローン体験ブースが今回パワーアップして再登場！ドローンで釣堀の中にあるお菓子をゲットしよう！もしかするとお菓子以外にもゲットできるモノがあるかも？",
    for_whom: "気軽にドローンで遊んでみたい方や操作を体験したい方大歓迎！まずは挑戦！",
    night_content: "景品のお菓子の中にトイドローンプレゼント引換券を混ぜる予定です",
    flyer_url:
      "https://drive.google.com/file/d/1-ctM4UMBJwjd-5EQZBZlge4pqnGK_etQ/view?usp=share_link",
    area: null,
    owner: "ドローンおかだ",
    owner_url: "https://libecity.com/user_profile/sSfM96QDX4PSi6E29FCpQasIOH73",
    co_owners:
      "やんしみ選手\nケイト\nおかわり\nU.\nりょたポン🐕\nLee\nBONA\nまいのす\nオビト\nオルト\nたかな",
    categories: "体験, 子供向け, ゲーム, 夜フェス企画あり",
  },
  {
    num: 61,
    id: 59,
    search_id: 59,
    name: "「群馬よいとこ一度はおいで♨」🦗「コオロギの可能性」",
    type: "一般無料ブース",
    css_type: "NB",
    content: "🐴「群馬の魅力をお伝えします」🦗「コオロギの魅力をお伝えまします」",
    pr: "🐴「群馬支部メンバーがイチオシの観光地やグルメを紹介」🦗「コオロギマニアによる自由研究相談会。コオロギ牧場もあるよ。」",
    for_whom: "🐴群馬を知らないリベ大生🦗夏休みの自由研究に困っているご家族",
    night_content: "🐴【ガチャガチャの当たりの数を多めにする】",
    flyer_url:
      "https://www.canva.com/design/DAFh6veDKgI/ABs-0hYDu_dcZGQPRP6Ulw/view?utm_content=DAFh6veDKgI&utm_campaign=designshare&utm_medium=link&utm_source=homepage_design_menu",
    area: null,
    owner: "てぞう",
    owner_url: "https://libecity.com/user_profile/lVpsrFuwZFdx1maIMjHTtRXs0Xa2",
    co_owners:
      "ヘマ\n🐴たこ焼き\n\n🐴チワプー\n\n🐴タクモン\n\n🐴おり\n\n🐴遅咲きおじちゃん\n\n🐴Kazu\n\n🐴おまめ\n\n🐴しみー\n\n🐴みーたん\n\n🐴きゃんちゃん\n\n🐴うさこ\n\n🐴ぐみ\n\n🐴タナッキー\n\n🐴nami\n\n🐴たぼ\n\n🐴あゆま\n\n🐴しずしず\n\n🐴ケン\n\n🐴こっしー\n\n🐴mimi\n\n🐴ぱっぷ\n\n🐴189\n\n🐴くましろ\n\n🐴ぐんまこ\n\n🐴ゆう\n\n🐴群馬のきむら\n\n🐴マロン\n\n🐴たけ\n\n🐴まっくろしっぽ\n\n🦗BAL\n\n🦗うどん\n\n🦗らる",
    categories: "交流, いきもの, 国・地域密着, 夜フェス企画あり",
  },
  {
    num: 62,
    id: 60,
    search_id: 60,
    name: "ちんあなご水族館",
    type: "一般有料ブース",
    css_type: "PB",
    content: "ちんあなごの展示、グッズの販売をするち〜ん！ちんあなご族とブースで待ってるち〜ん♪",
    pr: "ちんあなごを通じて「共通の好き」を共有できるような、ゆらゆらはっぴ〜なブースだちん！\n実際のちんあなごを見ながら学んだり♪癒されたり♪\nちんあなご族との交流もたのちんでね！",
    for_whom:
      "生き物好きはもちろん、ちんあなごが気になる！楽しいことが大好きなおともだちんにオススメだちん♪",
    night_content: "両子ママの夜フェスに参加予定だちん！詳細はWebチラシをチェックだちん♪",
    flyer_url:
      "https://drive.google.com/file/d/1t1jrWKhwOfDQZYTOJhsk-isb5n-YDlMR/view?usp=share_link",
    area: null,
    owner: "じぇん",
    owner_url: "https://libecity.com/user_profile/qVynxgqvxdXsDLLoZLM89AvSxCG3",
    co_owners: "エビンギ\nあでぺん、おかに、じょー、せたろう、チータラ、めぐ",
    categories: "販売・セミナー, いきもの, 展示, 夜フェス企画あり",
  },
  {
    num: 64,
    id: 62,
    search_id: 62,
    name: "さあ！学校いこか！にゃんこ校長による、ねこの時間割",
    type: "一般無料ブース",
    css_type: "NB",
    content: "多くの人と交流し、ねこから学ぶ「自由とは何か」を見つける特別授業",
    pr: "さまざまなスキルを持った猫好きのメンバーで贈る、特別授業。雑談から生まれる「自由とは何か」「稼ぐ力とは何か」を見つけてもらう場を提供いたします。",
    for_whom: "特に猫好きさんに向けてのブースです。",
    night_content: "内容",
    flyer_url:
      "https://www.canva.com/design/DAFgRC2XH84/lN94hP0HpS-VrzAAebqw3w/view?utm_content=DAFgRC2XH84&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
    area: null,
    owner: " yuri🐑@ねこ学校😸ブースNo.62",
    owner_url: "https://libecity.com/user_profile/0wYFiP2unLTNU2pOnPpZDcDYfMZ2",
    co_owners:
      "のりのり♥62猫♥ハンドメイド126\nオクト＠八本の腕で＃62「ねこ学校」出展\nのりリン癒し手@ブースNo62ねこ学校\nもふもふ\nRisaネコ\nヨミカ",
    categories: "ワークショップ, 体験, 交流, 夜フェス企画あり",
  },
  {
    num: 65,
    id: 63,
    search_id: 63,
    name: "ねこリベ",
    type: "一般有料ブース",
    css_type: "PB",
    content:
      "猫好きメンバーによるハンドメイド作家の作品販売、猫のフォト教室、猫の住宅相談、猫の写真展、保護猫展",
    pr: "ブース内の猫濃度はフェス随一！猫愛あふれる作家のこだわりの一品を見つけたり、猫にまつわるお悩み相談など猫好きにはたまらない企画満載です。リベ友と一緒に猫友も作りましょう。",
    for_whom: "猫好きさんも、猫と暮らしている人も、これから猫と暮らしたい人も楽しめます。",
    night_content:
      "ブース内にねこ絵を10匹配置。全部見つけたらおまけが貰えます。両子ママもいるかも！？",
    flyer_url:
      "https://www.canva.com/design/DAFiOQPPDVw/s7pe5WUT8kGi9Iwc_8qeYg/view?utm_content=DAFiOQPPDVw&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
    area: null,
    owner: "いのねこ",
    owner_url: "https://libecity.com/user_profile/kRLxebYedvSx4ZaxJqRfgPWrBqo2",
    co_owners: "あさき",
    categories: "ハンドメイド販売, ワークショップ, いきもの, 夜フェス企画あり",
  },
  {
    num: 66,
    id: 64,
    search_id: 64,
    name: "動物好きを活かして稼ぐ力をつける！ワークショップ&展示販売",
    type: "一般有料ブース",
    css_type: "PB",
    content:
      "飼育に掛るネーリテラシーで「守る力」、動物好きを活かして「稼ぐ力」つくるワークショップ＆展示販売",
    pr: "ワークショップを通じて、動物好きを「稼ぐ」に繋げるための具体的なロードマップを提供します！ 好き＆得意なことを活かして、動物との豊かな未来を想像＆創造できるブースです。",
    for_whom: "「動物好き」「ハンドメイド好き」「稼ぐ力を身につけたい人」",
    night_content: null,
    flyer_url:
      "https://www.canva.com/design/DAFf_YXpZBc/AtKoegZZ5vVypidfZcj8Mg/view?utm_content=DAFf_YXpZBc&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
    area: null,
    owner: " らいでん⚡ソーシャル転職術🦁動物の家具",
    owner_url: "https://libecity.com/user_profile/ehTTrPRlshQsqfEv47jW5WnS1MU2",
    co_owners:
      " ちえ🐰うさぎのアクセサリー教室💓東京\n\nちえ🐰うさぎのアクセサリー教室💓東京\nたびえ@フェス出店/陶芸/ジュエリー仕入\nこま＠動物好きで稼ぐ力✨リベフェス出店\nラム＠パラコード首輪作家🐶OEM🔰\nあみ🧶猫首輪ハンドメイドと写真＠奄美",
    categories: "ハンドメイド販売, ワークショップ, 展示",
  },
  {
    num: 67,
    id: 65,
    search_id: 65,
    name: "タッチでつながる！！世界で一つのオリジナルNFCキーホルダー",
    type: "一般有料ブース",
    css_type: "PB",
    content: "「NFCタグ入りキーホルダー」をはじめ、いろいろなNFCタグ関連商品を販売します！",
    pr: "QRコードの代わりに利用される、「NFCタグ」。\nスマホでタッチするだけで、NFCタグに書き込んだURLに一瞬でジャンプ！\n便利な機能を入れた、世界で唯一のキーホルダー作成します！",
    for_whom: "リベやSNSのIDを入れて、リベ友を増やしたい人にオススメです！",
    night_content: "夜フェス限定！スペシャルグッズを販売！！（詳細はWebチラシでお知らせします。）",
    flyer_url: "https://murara-life.com/libefes2023-booth65/",
    area: null,
    owner: "むら＠NFCタグらー",
    owner_url: "https://libecity.com/user_profile/is4kGUBcP2hst8h29eSq5yb4tFt1",
    co_owners:
      "鬼神はじめ\nむら\nはるかどん\nユウスケ\nおじじ\nおじーる\nチャコ\nあすか\nオペカン\nたおる\nさきえ\nキアノ\nくまごろう",
    categories: "ハンドメイド販売, 展示, 似顔絵・イラスト, 夜フェス企画あり",
  },
  {
    num: 68,
    id: 66,
    search_id: 66,
    name: "1~2日目：3DXRワールド、3日目：アートな提灯屋さん",
    type: "一般有料ブース",
    css_type: "PB",
    content:
      "1・2日目は3DのXR体験！3日目はポップアートと和の伝統のコラボ！提灯製作も体験できます。",
    pr: "1・2日目は3DのXR映像で魔法のような\n空間が現れます。3日目は祭を彩る和ポップ\nな提灯屋さんが登場！世界に一つだけのオリ\nジナル提灯製作も体験できます。",
    for_whom: "老若男女・1人でもグループでもフェスを満\n喫したいアナタに来てほしい。",
    night_content: "WEBチラシ参照　※一部変更になる可能性があります。",
    flyer_url:
      "https://www.canva.com/design/DAFg4ixCpZw/9WtvBTC9X1CcJ9W5RouK9g/view?utm_content=DAFg4ixCpZw&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
    area: null,
    owner: "KOSHIRO(ｺｰｼﾛｰ)",
    owner_url: "https://libecity.com/user_profile/e6c5XJ8fOmWkYEeDdlOfygNPWbF2",
    co_owners: "提灯屋ひな\nヒヨシ＠「アートな提灯屋さん」フェス出店",
    categories: "ハンドメイド販売, ワークショップ, 展示, 夜フェス企画あり",
  },
  {
    num: 69,
    id: 67,
    search_id: 67,
    name: "メタバース体験！　東京オフィスが幕張にやってきた？！",
    type: "一般無料ブース",
    css_type: "NB",
    content: "メタバースで再現した東京オフィス！ VRゴーグルでバーチャル空間を体験しましょう♪",
    pr: "実物そっくりの東京オフィスで、オリジナルアバターを使って来場者同士で交流したり、楽しいミニゲームで遊んでバーチャル空間の魅力を存分に味わってください！",
    for_whom: "メタバース体験や、Unity・Blenderでの制作に興味のある方におすすめ！",
    night_content:
      "16時以降、ブース利用者にオリジナルステッカーをプレゼント（一部変更になる可能性あり）",
    flyer_url: "https://drive.google.com/file/d/1-hYA18yMTy1NKSUipE90rObajHWR1tU7/view",
    area: null,
    owner: "ブラックバード",
    owner_url: "https://libecity.com/user_profile/Cc9F6lBDnGXRh41olFfSSD1TUOn2",
    co_owners:
      "ケットディー\nあまがみ\nうろく\nキジトラ\nろみにこ\nわらぎ\nおづき\n満\nバンブー\nとむとむ\nカケル\nコバラン\nせき\nひゅうま\nきとちお\nきんろー\nしょうま\nねここ\nもり\nめんま\n駿",
    categories: "IT・WEB, 交流, ゲーム, 夜フェス企画あり",
  },
  {
    num: 70,
    id: 68,
    search_id: 68,
    name: "わいわい小物販売",
    type: "一般有料ブース",
    css_type: "PB",
    content:
      "総勢14名の作家による\n『ハンドメイド作品の販売ブース』\n様々なジャンルの「かわいい」がいっぱい♪",
    pr: "ハンドメイドアクセサリーや\nお部屋に飾れるオブジェ、\n目からうろこのお役立ちアイテムなどなど\n思わず欲しくなっちゃう作品の数々！\nこだわりのお品物を揃えてお待ちしております",
    for_whom: "30代女性向けの装飾品、お部屋のオブジェが中心です",
    night_content:
      "通常の商品とはことなる、少し大人の雰囲気に変わった限定品を販売予定です\n※一部変更になる可能性があります",
    flyer_url: "https://drive.google.com/file/d/1VeVjztUzOcATtumBMoV2JGewcvno5UkG/view?usp=sharing",
    area: null,
    owner: "ゆるぴより",
    owner_url: "https://libecity.com/user_profile/ffUDKW6velVYvDbGiw8qNGVZ2GI3",
    co_owners:
      "しばみた\nあやレザー\nうえいー\nかおペン\nきら\nここあ\nしし\nたびえ\nのっち\nぷえお\nみいお\nみっく\nみらい\nもる\nゆーーーこ\nゆるぴより\nりらく",
    categories: "ハンドメイド販売, 販売・セミナー, 美容・ファッション, 夜フェス企画あり",
  },
  {
    num: 71,
    id: 69,
    search_id: 69,
    name: "ハンドメイドの森",
    type: "一般無料ブース",
    css_type: "NB",
    content:
      "ハンドメイド作品の展示ブース。心を込めて作られた作品たちがあなたとの出会いを待っています。",
    pr: "作家さんの作品を見た後アンケートに答えるとお礼の記念品をを進呈。お好きなハンドメイド作家さんの作品か、体験型ヨーヨー釣りの中からおひとつプレゼントいたします。\n\n",
    for_whom: "お子様連れの方から、お一人で来場の方まで、皆さまが楽しめるブースです♪",
    night_content: null,
    flyer_url:
      "https://www.canva.com/design/DAFhZHrxI6w/WRRH5d0U9CcGLwyo8GrjbQ/view?utm_content=DAFhZHrxI6w&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
    area: null,
    owner: "ほたて",
    owner_url:
      "https://libecity.com/user_profile/t9ZnZ2bVAuSYmd8L9bYA4M97J7Y2?redirect=/room_list?comment_id=xgIYoMutRe6L7INvnGH0%26room_id=HgqhQGVkzCY5MJUzwbUz",
    co_owners:
      "くるみーる\nyuka\nキャン\nたま\nにゃんぴ～\nゆきたま\nかわちゃん\nマット\nちゃーこ\nヨーメイズ\nひろりん\nまめしば\nアロエちゃん",
    categories: "子供向け, 展示",
  },
  {
    num: 72,
    id: 70,
    search_id: 70,
    name: "心と体を整えよう！赤ちゃんママのにっこりマルシェ",
    type: "一般有料ブース",
    css_type: "PB",
    content:
      "育休ママプレゼンツ♪足揉み、栄養相談、うきうきヘアアクセとお悩み相談で、足先から頭までリフレッシュ！",
    pr: "本業・副業・子育て・遊びと、満足度MAXのママたちが「自分の得意」でフェス出店！心と体がまるっと整う、家族にっこりの楽しいマルシェ。子どもOKのワークショップ常設。雑談しに来てね！",
    for_whom: "赤ちゃん、子ども、パパ、ママ、癒しを求めるすべての方Welcome♡",
    night_content:
      "福岡サロンの足揉み姉さん、特別メニュー♡フェスで疲れた足を揉みほぐします！最新情報はWebチラシにて。",
    flyer_url:
      "https://www.canva.com/design/DAFiPnM6jhA/htxwUVOkFs9CMs_d4kWTxg/view?utm_content=DAFiPnM6jhA&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
    area: null,
    owner: "みさちゃん",
    owner_url: "https://libecity.com/user_profile/51sgVlpe9hSrS2tIv9sPTq0ywHm1",
    co_owners: "まーりん\nしょこ\nななみ",
    categories: "ワークショップ, 体験, 子供向け, 夜フェス企画あり",
  },
  {
    num: 73,
    id: 71,
    search_id: 71,
    name: "KOSAERU~新米ハンドメイド作家たちの挑戦~",
    type: "一般有料ブース",
    css_type: "PB",
    content: "12名のハンドメイド作家で3日間入れ替わりながらハンドメイド作品を出品します。",
    pr: "ハンドメイド作品の素晴らしさだけでなく、対面販売経験やリベ会員歴も浅い12名のハンドメイド作家達が、仲間と協力しながら共同出店を通して挑戦することの大切さを伝えます！",
    for_whom:
      "お子さんから大人、ワンコに向けた作品を揃えております。また個性豊かな作家達ですので、お話好きな方もぜひいらしてください。",
    night_content:
      "16時以降、出品作品を割引させていただきます。また、両子ママの夜フェス向けの作品も出品予定です。",
    flyer_url:
      "https://drive.google.com/file/d/1a5yxmknpoUDaA5tS5gMjsz3SvHb3i5Bx/view?usp=drive_link",
    area: null,
    owner: "AKARI.M@KOSAERUブース71",
    owner_url: "https://libecity.com/user_profile/FVOrf72dadTsSPTS5OLzRDMvG9q1",
    co_owners:
      "まここま@KOSAERUブース71\nパー子@オフィスde部屋ぞーりフェス出店\nあにま～る@スマホポシェット・出店№71\nみわっち＠ミニチュア制服＆あみぐるみ\nハンドメイドあや@フェス出店バッグ作家\nヒイロ@レジンアクセサリーKOSAERU\nにあちゃん@KOSAERUブース71🌷\nきなこぺん＠ウッドレジン/フェス出店\nみす＠ミシンで縫う人♡フェス出店№71\nチャイ@パティシエ🍞パンで雑貨こさえる\nひつじちゃん＠あむ編むKOSAERU71",
    categories: "ハンドメイド販売, 販売・セミナー, いきもの, 夜フェス企画あり",
  },
  {
    num: 74,
    id: 72,
    search_id: 72,
    name: "リベ大フェスでお花と遊びましょう",
    type: "一般有料ブース",
    css_type: "PB",
    content:
      "誰でも作れる手作りハーバリウム。５つの力色のハーバリウムやプリザーブドフラワーの販売です。",
    pr: "ご家族やカップル、お友達やおひとり様の方も楽しめる、簡単手作りハーバリウムを中心にお花の販売もございます。当日募集の１時間子ども店長（小中学生対象）もございます。お楽しみに。",
    for_whom: "綺麗やお花がお好きな方、フェスの思い出をお土産にされたい方にお勧めです。",
    night_content: "１時間店長体験（大人バージョン）。花屋を体験されたい方へ。",
    flyer_url: null,
    area: null,
    owner: "花屋のはなはな",
    owner_url: "https://libecity.com/user_profile/pSAqdSMHchQrtop70qDTjljz15W2",
    co_owners: "COCO @ヘアスタイリスト",
    categories: "ハンドメイド販売, 販売・セミナー, ワークショップ, 夜フェス企画あり",
  },
  {
    num: 75,
    id: 73,
    search_id: 73,
    name: "絵が描けない人でも楽しめるアートの世界",
    type: "一般有料ブース",
    css_type: "PB",
    content:
      "アルコールインクアート「体験」やアルコールインクアートの「ハンドメイド作品販売」を行います",
    pr: "アルコールインクアートは、インクを垂らし、風で模様を描くアート！自分の好きな色のインクを垂らし、偶然に起こる色の広がりや、混ざり合いを楽しむことができます",
    for_whom: "どんな人でも、気軽に「アートと触れる楽しさ」を体験することができます",
    night_content:
      "夜限定の体験で「光る学長ライオンキーホルダー」を作ることができる\n（内容が変更になる可能性あり）",
    flyer_url:
      "https://drive.google.com/file/d/1T6R1E8CzqmEyRLrCVKq4SrFFeS2mCNWr/view?usp=share_link",
    area: null,
    owner: "clear",
    owner_url: "https://libecity.com/user_profile/gOvmsvy5vJXvOCX9BxrNWDaxt813",
    co_owners:
      " 徳望 (とくぼー)\nあーちゃん\nきんせいちょう（雄金盛）\nぽそ\nメリナ\nかかまる\nつぼすみ",
    categories: "ハンドメイド販売, ワークショップ, 子供向け, 夜フェス企画あり",
  },
  {
    num: 76,
    id: 74,
    search_id: 74,
    name: "Webライター’s ミニオフィス～Webライターの交流ブース～",
    type: "一般無料ブース",
    css_type: "NB",
    content: "Webライターの始め方や稼ぎ方を、先輩や仲間との交流を通じて学べるブースです。",
    pr: "あなたに合った稼ぎ方が最短で見つかる！\nSEOやシナリオ、コピーライティングなど幅広いジャンルのライターが在籍✨ママライターや副業ライターなど同じ境遇で稼ぐ人の話が直に聞けるよ！",
    for_whom: "Webライターに興味がある方、自分に合ったライターのジャンルを見つけたい方など",
    night_content: null,
    flyer_url:
      "https://www.canva.com/design/DAFhpRScgE0/7xh8T9NKFGxmyrW-fU4Vrw/view?utm_content=DAFhpRScgE0&utm_campaign=designshare&utm_medium=link&utm_source=homepage_design_menu",
    area: null,
    owner: "はるちょん",
    owner_url: "https://libecity.com/user_profile/oTsU6Q8jIUXFIH6MY3Fleri4PSA3",
    co_owners:
      "ミナト\nあいぴょん\nぺらお\nさくら\nへいへい\nほりぼー\nねこまつげ\nあや\nなっつん\nあおしろ\nまーくん\n岡埼渉（ワタル）\nくらら\nてるてる\nすい",
    categories: "IT・WEB, 交流",
  },
  {
    num: 77,
    id: 75,
    search_id: 75,
    name: "集まれ！超初心者プログラマー！！",
    type: "一般有料ブース",
    css_type: "PB",
    content: "4種類のワークショップ、プロフィール装飾販売",
    pr: "プログラミングに少しでも興味がある人が、プログラミングをやろうと思えるきっかけを作ります🔥",
    for_whom: "プログラミングに興味を持っている超初心者たち子供〜大人まで可能🙆‍♂️！",
    night_content: null,
    flyer_url:
      "https://www.canva.com/design/DAFgoLR74L8/Yrnx-nKmZdS0JO2uc6vU0Q/view?utm_content=DAFgoLR74L8&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
    area: null,
    owner: "くまプログラマー🐢",
    owner_url: "https://libecity.com/user_profile/5rXOCiQezVQeHPouBs29cOqMpyO2",
    co_owners:
      "ひとぴー\nふじぴょん\nうろく\nすいすい\nきなこのけだま\n主夫ダンナ\nにむりす\nふぎん",
    categories: "ワークショップ, IT・WEB, 子供向け",
  },
  {
    num: 78,
    id: 76,
    search_id: 76,
    name: "おいで、おいで、あそぼ",
    type: "一般無料ブース",
    css_type: "NB",
    content:
      "子ども～大人まで、誰もが楽しめる『おたのしみ公演』と②『ＩＴ「０→１」体験コーナー』を開催！",
    pr: "『子どもが楽しめるブースでフェスを盛りあげたい！』とベテラン保育士まゆたんとパソコン大好き夫と息子がコラボし、幕張メッセを笑いの渦に巻き込もうと燃えています。",
    for_whom: "小さなお子様連れの家族。動画・音楽・プログラミングを体験したい人。",
    night_content: "出店内容は16時以前と同じですが、両子ママ専用電飾とBGMで参画したいと思います。",
    flyer_url: "https://drive.google.com/file/d/1itGDXJvOxt7J1Ptok6k-JU70yC8cHPKI/view?usp=sharing",
    area: null,
    owner: "まゆたん",
    owner_url: "https://libecity.com/user_profile/SspPLo7IBYQ8cTOr7KyIduI9fEV2",
    co_owners: "やるなら今しかねぇ",
    categories: "IT・WEB, 子供向け, ショー・パフォーマンス, 夜フェス企画あり",
  },
  {
    num: 79,
    id: 77,
    search_id: 77,
    name: "どんな出店・人がいる？おいでよチラシブース！【ぼっち大歓迎】",
    type: "一般無料ブース",
    css_type: "NB",
    content:
      "各出店ブースのご案内や、気になるリベシティ民をインタビューチラシでご紹介！オフ会も開催♪",
    pr: "たくさんブースがあるなあ…どこに行こう？とお悩みですか？\n各出店のチラシを見ながらブースのご案内＆ご紹介します！\nぼっちの人には、タイプ別に他の初心者ブースへもご案内します♪",
    for_whom: "どんなブースがあるか知りたい方・手持ち無沙汰な方・ぼっちで不安な方…などなど♪",
    night_content:
      "ブース内で少人数のオフ会を開催します！空きがあれば当日参加OKですので、当ブースまで遊びにきてくださいね♪",
    flyer_url: "https://drive.google.com/file/d/15TaELnJwtTS5o8hdt0HPLSSdWnrMZPyb/view?usp=sharing",
    area: null,
    owner: "ざわ",
    owner_url: "https://libecity.com/user_profile/h035g3tbG4hD8RZn6zQGCCgBLT02",
    co_owners: "ガイア",
    categories: "交流, 展示, 夜フェス企画あり",
  },
  {
    num: 80,
    id: 78,
    search_id: 78,
    name: "アロマブース　香りの空間　『アトリエ　癒』",
    type: "一般有料ブース",
    css_type: "PB",
    content:
      "アロマオイルを用いたグッズ販売、香りを使ったものづくりと癒しのトリートメントを提供します。",
    pr: "薬剤師のリーダーをはじめ香りに魅了された私たちが作り出す空間をぜひ体感しにいらして下さい。香りのある日常の楽しさと豊かさをリアルに味わえます。奄美の自然も持っていきますよ！\n",
    for_whom: "たくさんのブースを楽しむ合間にあなただけの香りに出会っちゃいませんか💗",
    night_content:
      "フェスで疲れた後はゆるりとアロマトリートメントで休憩できますよ～！大人の休み時間始めます。",
    flyer_url:
      "https://www.canva.com/design/DAFhNSMeEkU/xtyPsnatvYcTCqDxtnvm7A/view?utm_content=DAFhNSMeEkU&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
    area: null,
    owner: "シトラス",
    owner_url:
      "https://libecity.com/user_profile/DHzHlrc9owewLjJOeRhMnOhdfIO2?redirect=/room_list?room_id=YYnHUN7cYRhpuDdq1KMw",
    co_owners: "おとも",
    categories: "ハンドメイド販売, ワークショップ, 体験, 夜フェス企画あり",
  },
  {
    num: 81,
    id: 79,
    search_id: 79,
    name: "リラックス整体ブース",
    type: "一般有料ブース",
    css_type: "PB",
    content:
      "リフレッシュ・疲労回復を目的としたリラクゼーションマッサージ、足つぼ、整体を行います。",
    pr: "北は福島県から南は福岡までの全国各地から、腕に自信のある施術者が勢ぞろい。心身共に整えて稼ぐ為の土台である健康資産をアップさせて頂きます。",
    for_whom: "普段から疲れが取れにくい方や、フェスで歩き疲れてしまった方。",
    night_content: null,
    flyer_url:
      "https://www.canva.com/design/DAFh3KSHdBQ/t8mD6gQXeWEwOV04POdVWg/view?utm_content=DAFh3KSHdBQ&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
    area: null,
    owner: "ベル",
    owner_url: "https://libecity.com/user_profile/7PhGEgY5irNRoJvnoo89n2Vdmyk2",
    co_owners: "とうか\nわたゆき\nケイシ\nこうへい\nふう\nおもカズ\nらんてぃえ\nうえやま\n足つぼ",
    categories: "健康, 体験",
  },
  {
    num: 82,
    id: 80,
    search_id: 80,
    name: "美人のコツ教えます！",
    type: "一般無料ブース",
    css_type: "NB",
    content: "メイクのプロが無料でメイクをアドバイス\n化粧品成分プロが無料で成分を解説",
    pr: "虎会員のちょっちー、Pepeの２名で運営する美容ブースです。\nメイクのプロのちょちょーが無料でメイクをアドバイス\n化粧品成分のプロのPepeが無料で成分を解説",
    for_whom: "メイクで悩んでいる女子・男子、成分を知りたい万人は是非お立ち寄り下さい",
    night_content: null,
    flyer_url:
      "https://www.canva.com/design/DAFgy70L7PM/pCMfu7HE9BVawAshs4Nbgg/view?utm_content=DAFgy70L7PM&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
    area: null,
    owner: "ちょっちー",
    owner_url: "https://libecity.com/user_profile/Okf8fpCNwMauxtIPKJpBNInZIEt2",
    co_owners: "Pepe\nAiko\nみんと\nいがっち\n花子\nニーナ",
    categories: "交流, 美容・ファッション",
  },
  {
    num: 83,
    id: 81,
    search_id: 81,
    name: "楽しく体験！アパレルせどり＆ お金の漫画・絵本オンライン販売",
    type: "一般有料ブース",
    css_type: "PB",
    content:
      "無料説明会＆有料コンサル！さらに洋服も売っちゃうよん♪/お金の漫画・絵本オンライン販売。",
    pr: "初心者も中級者もみんなサポート！！稼ぐ力アップへ向けてのお悩み相談＆利益商品販売/金融教育の漫画・絵本を販売します。",
    for_whom: "早く稼ぎたい！稼ぎたいけど方法が分からない！/子どもの金融教育に興味のある方。",
    night_content: "割引クーポンガチャ＆売り尽くしセール/noteでの未使用画像を無料公開。",
    flyer_url: "https://drive.google.com/file/d/1xGtCdY12GJ1QAbD067km0l1MV1YaTM12/view",
    area: null,
    owner: "yukino",
    owner_url: "https://libecity.com/user_profile/rwD0WopTyAdGG7u0ZtG4tWYEjq12",
    co_owners: "ある\nふぉあみぃ",
    categories: "販売・セミナー, 子供向け, 夜フェス企画あり",
  },
  {
    num: 84,
    id: 82,
    search_id: 82,
    name: "コーチングブース 本当の自由が見つかる！対話型価値観マップ",
    type: "一般有料ブース",
    css_type: "PB",
    content:
      "①5種類のコーチングセミナー\n②体験型ワークで深掘り\n③交流会、インタビュー、ミニコーチング",
    pr: "あなただけの自由を見つける冒険に出かけよう！\n 様々なスキルを持つコーチ達が仲間に加わり\n 対話を通してあなたの中に眠る自由を一緒に探しにいきます\n 稼ぐ力のヒントも隠れているかも",
    for_whom: "17日は貴方の綺麗になりたいをお手伝いするアクセサリーと美容ブースです",
    night_content:
      "夜フェス時間はお酒を片手に気軽に語り合えるコーチとの交流タイムやコーチングを使った恋愛術指南などを行います（一部変更になる可能性アリ）",
    flyer_url:
      "https://drive.google.com/file/d/1xcLTNnNrn657qdawJ985QM26_wWeXDIn/view?usp=share_link",
    area: null,
    owner: "たかみや",
    owner_url: "https://libecity.com/user_profile/8n6AafZFR1fU8oaJTHTaSQFEOxG3",
    co_owners:
      "KID\nおけもん\nりょうこ\nあやころん\nおにちゃん\ntakusam\nちひろ\nトラけい\nけい\n碧紗",
    categories: "販売・セミナー, ワークショップ, 占い・診断, 夜フェス企画あり",
  },
  {
    num: 85,
    id: 83,
    search_id: 83,
    name: "稼ぐ力を鍛えるで！動画編集体験ブース！！",
    type: "一般無料ブース",
    css_type: "NB",
    content:
      "学長おすすめ副業の1つ、動画編集を無料で体験！動画編集仲間と座談会や、PCスペック比較会も同時開催！",
    pr: "最初のハードルが高い動画編集を無料体験、安心のマンツーマン指導！初期費用は？難しい？いくら稼げる？等の疑問に答えます！経験談をまとめたガイドブックも無料配布！",
    for_whom: "副業を悩む動画編集「未経験者」〜仲間が欲しい「中級者」向けです！",
    night_content: null,
    flyer_url:
      "https://www.canva.com/design/DAFiDPezoKo/0bnNUSuuwZlcc2Hoio60fA/view?utm_content=DAFiDPezoKo&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
    area: null,
    owner: "てんてん💻動画編集者🎥",
    owner_url: "https://libecity.com/user_profile/X7VEuOuPmtYqrbQqOH8ua04H5Ti1",
    co_owners: "たなかひろ笑\nこま吉\nずほ\nのりまき\nモッシュ\nさわとも\nPeko\nやま\nカッツ",
    categories: "IT・WEB, 体験, 写真・動画",
  },
  {
    num: 86,
    id: 84,
    search_id: 84,
    name: "あなたが素敵に思う住まいを形にするお手伝い",
    type: "一般無料ブース",
    css_type: "NB",
    content:
      "あなたの住まいへの想いを伺い、形にするためのコンサルティングをお一組30分無料でさせて頂きます。",
    pr: "一級建築士の住まいづくりのレシピをもとに、理想の住まいを形にするお手伝いをさせて頂きます。おしゃべりの中でこそ、本当の想いや良いアイデアが出ると思います。お気軽にお話しください。",
    for_whom: "リフォームを含めいつか住まいづくりをしようと考えている方、または始めている方",
    night_content:
      "工務店や建築士の方、職人さんやインテリア好きな方など、是非おしゃべりにお集まりください !!",
    flyer_url:
      "https://www.canva.com/design/DAFh1ouh5dU/rqmSlAazIWSiU0kHRB56mA/view?utm_content=DAFh1ouh5dU&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
    area: null,
    owner: "コロたま",
    owner_url: "https://libecity.com/user_profile/Wi78oVbbvZXxk7DYhMwMZVJ5NGh2",
    co_owners: "カプチーノ",
    categories: "交流, 夜フェス企画あり",
  },
  {
    num: 87,
    id: 85,
    search_id: 85,
    name: "15.16日株式・不動産投資道場/ 17日大人も楽しめる手裏剣体験",
    type: "一般有料ブース",
    css_type: "PB",
    content: "(15・16日)投資\nFIRE投資家と不動産マニアの相談\n\n(17日)ゴム手裏剣体験\n的当てゲーム",
    pr: "FIREシミレーションや物件の選び方など経験者から聞けるチャンス！書斎から学べない情報も得られます。\n\nプロの忍者5人が集結。忍び界で有名な忍者も‼\n\n３日間、限定グッズ販売します",
    for_whom:
      "株式や不動産投資　初～中級者\n月3～5万円の資産所得が欲しい人\n不動産を始めてみたい方\n\n手裏剣を投げてみたい方\n本業の忍者と交流したい者",
    night_content: "(15・16日) 限定グッズ割引♪　(17日) 1分間500円で手裏剣投げ放題♪",
    flyer_url:
      "https://www.canva.com/design/DAFiqRkNYOs/ZQj8SkvIN1yRVtfdywl0QQ/view?utm_content=DAFiqRkNYOs&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
    area: null,
    owner: "なみへい",
    owner_url: "https://libecity.com/user_profile/lU7NBTtqujTDCJwCQJ6XAL9BL4P2",
    co_owners:
      "ミツ\n投資太郎(とし)\n馬田ひろし\nなおぽん\nはる\nテナベ\nてるる\nドラミちゃんハウス",
    categories: "子供向け, ゲーム, 投資・不動産, 夜フェス企画あり",
  },
  {
    num: 88,
    id: 86,
    search_id: 86,
    name: "【15日:外壁塗装無料相談】【16/17日:動画編集ミニセミナー】",
    type: "一般有料ブース",
    css_type: "PB",
    content: "【15日：外壁塗装の無料セミナ＆無料相談】【16・17日：動画編集で5万円達成セミナー】",
    pr: "【15日：外壁塗装の無料セミナ＆相談】知識を付けて「守る力」を強化しましょう！【16・17日：動画編集で5万円達成セミナー】動画編集で月5万円を稼ぐメンバー12名が集結！",
    for_whom: "【7/15：戸建住宅に住んでいる人】【7/16・7/17：動画編集で月5万円以上、稼ぎたい人】",
    night_content: null,
    flyer_url:
      "https://drive.google.com/file/d/1ZQkq93yH1dCIka_Tg1yZJ0zuU-SpeDdi/view?usp=share_link",
    area: null,
    owner: "みずぴー",
    owner_url: "https://libecity.com/user_profile/cMS6GAzTHrYlY5YJ8HdHMGr7l122",
    co_owners:
      "なか\nもが\nあまみ\nオードリー\nkaz\nでわん\nとらさん\nはなゆみ\nHiro\nもぐすた\nもりし\nやかん",
    categories: "販売・セミナー, 写真・動画, 投資・不動産",
  },
  {
    num: 89,
    id: 87,
    search_id: 87,
    name: "【公式】リベ大不動産～物件検索相談会＆役立つ賃貸クイズ～",
    type: "一般無料ブース",
    css_type: "NB",
    content: "リベ大不動産初🎉対面型リアル物件検索相談開催🎊＆知って得する賃貸クイズ👓🏡",
    pr: "普段なかなか会えない不動産スタッフと楽しくお部屋情報ゲット🎉専門家アドバイス＆お役立ちクイズでみなさまの賃貸生活をサポート🏠✨皆で一緒に盛り上がろう🌟",
    for_whom: "お部屋探しや知って得する賃貸情報に興味のある皆さんへ🔎🌈",
    night_content: null,
    flyer_url:
      "https://www.canva.com/design/DAFiCJaPNzM/2efPpkyPkJ9wWvb7Pkk_ZA/view?utm_content=DAFiCJaPNzM&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
    area: null,
    owner: "サウナ",
    owner_url: "https://libecity.com/user_profile/o0Y7gv7ZaMQMrk44GbkHTwNuKOl1",
    co_owners: "Miho",
    categories: "国・地域密着, 投資・不動産, リベ大グループ",
  },
  {
    num: 90,
    id: 88,
    search_id: 88,
    name: "歯科の細かいお仕事体験",
    type: "一般無料ブース",
    css_type: "NB",
    content: "歯科の機材を使って細かい作業(学長コイン製作)体験",
    pr: "ここでしか手に入らない学長コインに自分オリジナルの色付けをして一生の思い出にしましょう！",
    for_whom: "リベを愛する方すべて",
    night_content: "学長コイン夜フェスバージョン製作",
    flyer_url:
      "https://www.dropbox.com/s/jyx0volqvhpwztr/%E5%AD%A6%E9%95%B7%E3%82%B3%E3%82%A4%E3%83%B3%E8%A3%BD%E4%BD%9C%E4%BD%93%E9%A8%93.pdf?dl=0",
    area: null,
    owner: "tre(とれ)パンダ",
    owner_url: "https://libecity.com/user_profile/7mHANpUeYqaShNqNmIoIR6cyg4N2",
    co_owners: "たか",
    categories: "ワークショップ, 体験, 夜フェス企画あり",
  },
  {
    num: 91,
    id: 89,
    search_id: 89,
    name: "HaHaHa no 歯ぁ〜♪",
    type: "一般有料ブース",
    css_type: "PB",
    content: "口腔知識が身につくセミナーやデンタルエステや歯磨き指導、電動歯ブラシが当たるクジ引き",
    pr: "みなさまが健康に対するお口の重要性を実感し、自分の歯で一生食べる事により『豊かな人生』を手に入れることが出来るブースです",
    for_whom: "歯やお口の事で悩んでいるけど誰に相談したら良いか困っている方にピッタリです",
    night_content:
      "３種類のくじ引きから”変態さん用”が”両子ママ用”に大変身✨\nもちろん景品もバージョンUP🥳👍",
    flyer_url:
      "https://drive.google.com/file/d/1kjrOoHUfkj7vaq1KkPY3ZQzWY9j7KjPX/view?usp=drivesdk",
    area: null,
    owner: "げん",
    owner_url:
      "https://libecity.com/user_profile/ASFPUv0nGAODWqpaV87A5HufBB23?redirect=/room_list?room_id=LyMpZKJZt9vXaHC49aOP",
    co_owners:
      "まるみん\nガネ嫁\nガネ夫\nけいこ\nSIMA\nすぅ\nテケ\nとうま\n名古屋のすずき\nパイレレ\nぴかちゃん\nまさ\nゆりしす\nろな",
    categories: "販売・セミナー, ワークショップ, 健康, 夜フェス企画あり",
  },
  {
    num: 92,
    id: 90,
    search_id: 90,
    name: "想い出作りのラベル屋さん　ご縁をカタチに・三重支部ース",
    type: "一般有料ブース",
    css_type: "PB",
    content:
      "自分の写真やアイコン、お気に入りのイラストや文字を活用した、オリジナルフレームをご提供します！",
    pr: "①三重のイラストレーターによる限定フレームでオリジナルシール作成！\n②作成時には三重メンバーと交流！\n③アイコン作成・マッサージ・アクセサリー・名札制作など追加メニューも充実！",
    for_whom: "フェスの想い出を残したい方、手軽なお土産が欲しい方におすすめです！",
    night_content:
      "食べ物が持込可能に！\n限定フレームや手提げ袋に両子ママも登場予定♪\n※一部変更になる可能性があります",
    flyer_url: "https://drive.google.com/file/d/1otYFNLdGi6m8gRdRsHSd5I3y0tAmbisu/view?usp=sharing",
    area: null,
    owner: "まゆみさん。",
    owner_url: "https://libecity.com/user_profile/gf0HcfAvAJR5m4bmOINrqXp9B893",
    co_owners:
      "もとあ\nまゆみさん。\nみーあ\nオカメ\nみかちょ\nみっちー\nきよみはん足療師\nあやーど\nなかこ\nぐるー\nまたね\nこまっちゃん\nたか\nあくあ\nのっち\nCocco\nカビィ\nいまわか\nもちっこ\nベイト\nグロム\nじんたん\nえび\nイチマキ\nしん\nしょー",
    categories: "ハンドメイド販売, 販売・セミナー, 交流, 夜フェス企画あり",
  },
  {
    num: 93,
    id: 91,
    search_id: 91,
    name: "あなたのアイコンシール製作所！",
    type: "一般有料ブース",
    css_type: "PB",
    content: "あなたのアイコンで、フェス限定のシールをその場で作ります！",
    pr: "フェスに来た記念に！\n名札に貼って、初めましての話題づくりに！\n名刺がわりにプレゼント！\nアイコンシール製作所は、あなたの有意義な出会いのお手伝いをします！",
    for_whom:
      "出会いの背中を押してほしい方！\nアイコンに愛着がある方！\nお気に入りの画像をシールにしたい方！",
    night_content:
      "16時以降限定！購入して頂いた方にお配りする、シールの持ち帰り用小袋が「両子ママ」仕様に！",
    flyer_url:
      "https://drive.google.com/file/d/1QdtbzeIdst9feR4Y3kGpn1DxBh-_V__1/view?usp=share_link",
    area: null,
    owner: "コアラン",
    owner_url: "https://libecity.com/user_profile/0nQGTU1RBzc3RKCD05sL8l8xZBF2",
    co_owners: "トミー\nコアラン\nやー\nもじを\nすう",
    categories: "販売・セミナー, 交流, 似顔絵・イラスト, 夜フェス企画あり",
  },
  {
    num: 94,
    id: 92,
    search_id: 92,
    name: "AIアイコンの名ふだ屋さん",
    type: "一般有料ブース",
    css_type: "PB",
    content:
      "楽しい自己紹介のきっかけをご提供！ステキな出会いを彩るオリジナル名札作りのブースです♪",
    pr: "・フェス限定デザインでお祭りを盛り上げる\n・SNSアイコンがなくても大丈夫！\n　AIイラストレーターで簡単に作成します\n・あなただけの名札で個性を演出しましょう♪",
    for_whom: "名札を持っていない人、忘れちゃった人も、ここにくれば安心だよ！",
    night_content: null,
    flyer_url: "https://drive.google.com/file/d/1WzlS7WI9m1Zzf0LQoZW2szwRAIDHvJZN/view?usp=sharing",
    area: null,
    owner: "ぱぱくま",
    owner_url: "https://libecity.com/user_profile/QSmbhlc5UyVqQzLfnY9viVTgrUj1",
    co_owners: null,
    categories: "似顔絵・イラスト",
  },
  {
    num: 95,
    id: 93,
    search_id: 93,
    name: "未来の自分体験　介護施設　りべしちぃホーム",
    type: "一般有料ブース",
    css_type: "PB",
    content: "高齢者疑似体験で学ぶ健康資産を\n貯めるヒント＆癒しの介護ハンド\nケアでリラックス",
    pr: "高齢者の身体を疑似体験！専門職から健康資産\nを貯めるヒントを学び、老後におすすめの介護\nハンドケアを体験。今からできる老いても豊か\nな時間を過ごす準備を楽しくお伝えします！",
    for_whom: "老後の自分が気になる、健康資産を意識してる、フェスで楽しい思い出作りたい",
    night_content:
      "16時以降のハンドケアを受けた方に気持ち良すぎて悶えるアロマバスソルトをプレゼント！",
    flyer_url:
      "https://www.canva.com/design/DAFiGHkSAnc/tEBtjPfiEM6lSXoWk1a_Iw/view?utm_content=DAFiGHkSAnc&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
    area: null,
    owner: "るみ",
    owner_url: "https://libecity.com/user_profile/tDun7i1XDCa1s0xfdQLxdgWudoo1",
    co_owners: "ぽてこ\nまきこむ\nゆーちゃん\nたーちゃん\n◯まる◯\nセキ亀\nぴ〜\nしばっち",
    categories: "健康, 体験, 交流, 夜フェス企画あり",
  },
  {
    num: 96,
    id: 94,
    search_id: 94,
    name: "介護無料相談ブース",
    type: "一般無料ブース",
    css_type: "NB",
    content: "介護の知識マッチョが集結！介護の悩み、不安、心配をやさしく、楽しく解決します！",
    pr: "・介護制度を分かりやすく解説！\n・介護あるある交流会（雑談OK）\n・ゲームやクイズもあるよ♪\n・グラレコを用いて介護の事を伝えます！\nブースに来て心を軽くしましょう♪",
    for_whom: "介護に悩みがある、興味がある\n介護が始まる前にリテラシーを持ちたい",
    night_content: null,
    flyer_url:
      "https://www.canva.com/design/DAFe0QPjsMg/l1gy9up1XzaIvQgHYHL04w/view?utm_content=DAFe0QPjsMg&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
    area: null,
    owner: "ちば君",
    owner_url: "https://libecity.com/user_profile/sfiar0lEFXPnDnRPcIY2fbM7Y3Y2",
    co_owners: "にんじん\nあめちょこ\n部長補佐\nまあこ\nゆずみかん\nらいすけ\nかな",
    categories: "健康, 体験, 交流",
  },
  {
    num: 97,
    id: 95,
    search_id: 95,
    name: "セルフ写真館&(非公式)リベクリエイターズ学長缶バッジガチャガチャ",
    type: "一般有料ブース",
    css_type: "PB",
    content:
      "家族や友達とプロの機材とカメラを使ってプリクラ感覚で記念写真が撮れる セルフ写真館です。",
    pr: "固定の高性能カメラと照明を使って 自分のタイミングでシャッターを切り、\n10分間自由に写真が撮れます。\nカメラマンがいないので緊張せず自然な笑顔が残せます。",
    for_whom: "家族連れや友人、自撮りをしたい人。恥ずかしがり屋さんもお越しください♪",
    night_content: null,
    flyer_url:
      "https://drive.google.com/file/d/1XHEKqqKHQGEGAwL3Ineh3MYKcFPYbWM_/view?usp=drive_link",
    area: null,
    owner: "前ちゃん＠ファッションカメラマン",
    owner_url: "https://libecity.com/user_profile/vwQpdrcPtBhIXpxzzOXkqVT46sZ2",
    co_owners: "キノコ＠着物撮影が得意なフォトグラファー",
    categories: "ハンドメイド販売, 体験, 写真・動画",
  },
  {
    num: 98,
    id: 96,
    search_id: 96,
    name: "幸せを運ぶ写真館",
    type: "一般有料ブース",
    css_type: "PB",
    content:
      "家族やカップルや友人と一緒に撮影するスタジオです！撮影した写真はあなたの携帯の待ち受けに設定します！",
    pr: "“家族で写真を撮っている人は\n幸福度が高く社会的成功を収めている人が多い”\nという研究成果があります！\nお写真が苦手な方でも大丈夫！必ず素敵な表情で大切な人との想い出を残します！",
    for_whom:
      "家族やカップルや友人など、”大切な人との絆をより深くして、幸せな人生を過ごしたい！！”と思う人に向けてのブースです！",
    night_content:
      "撮影させていただいたお写真と全く同じものをチェキにしてプレゼント！是非大好きな人との思い出を家にも飾ってください✨",
    flyer_url: "https://drive.google.com/file/d/1PRzwqy2_KJSfqB1l7PVXLXfdaedvyEZ5/view?usp=sharing",
    area: null,
    owner: "てつ",
    owner_url: "https://libecity.com/user_profile/qE5hmgCw3FRDmp8WpXiEJjFfzqA2",
    co_owners: "なっちゃん\nばばっち\ntaka",
    categories: "体験, 写真・動画, 夜フェス企画あり",
  },
  {
    num: 99,
    id: 97,
    search_id: 97,
    name: "音楽講師によるピアノ・エレクトーン教室",
    type: "一般無料ブース",
    css_type: "NB",
    content:
      "ピアノ・エレクトーン体験\n親子でリトミック体験\nミニコンサート\nストリートピアノ・エレクトーン",
    pr: "✅レッスン体験\n15分で完結！すぐ弾けて楽しい！0→1を応援\n\n✅ちびっこ大興奮の音楽リズム遊び！\n\n✅先生の生演奏♫感動をお届け\n\n✅ストリートピアノ・エレクトーンで演奏しよう♫",
    for_whom:
      "ピアノ・エレクトーンに興味のある方♫\n音楽を通じて交流を広げたい方♫\n講師に気軽に相談したい方♫",
    night_content:
      "16時以降の体験時間を5分延長✨\n15分→20分に✨\n\n17:30〜のコンサートでは夜フェスの雰囲気に合う楽曲をお届け🍸\nお酒×音楽で贅沢な30分に🥂",
    flyer_url:
      "https://www.canva.com/design/DAFfXe7ehB4/n4SYR_RSPOyv680vVnVN1Q/view?utm_content=DAFfXe7ehB4&utm_campaign=designshare&utm_medium=link&utm_source=viewer",
    area: null,
    owner: "Erika♪エレクトーンとピアノの先生♪",
    owner_url: "https://libecity.com/user_profile/ToO5FNxFj8XxAzEbH0U1gCV3FjL2",
    co_owners: "ピアノのきよ\nにこりんご\nあやこ\nあーのん\n心",
    categories: "体験, 交流, ショー・パフォーマンス, 夜フェス企画あり",
  },
  {
    num: 100,
    id: 98,
    search_id: 98,
    name: "レザークラフト部",
    type: "一般有料ブース",
    css_type: "PB",
    content: "革の匠が集結！誰でも楽しめる革職人体験と、フェスでしか買えない数量限定アイテムも？！",
    pr: "刻印体験で気軽につくれるあなただけのネームタグ！\n切って、縫って、一から作る本気のコインケース！\n9人の革職人が作るこだわりのレザーアイテム",
    for_whom: "革製品が好きな人！\nレザークラフトを始めたい人！\n学長を愛している人！",
    night_content:
      "革職人による実演会を開催！\n一緒に作ろう巨大トリック編み＆みんな大好きレインボー染め",
    flyer_url:
      "https://www.canva.com/design/DAFfn3NhZp4/zNU-s8JehCskM_G7La_LSQ/view?utm_content=DAFfn3NhZp4&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
    area: null,
    owner: "いっしー",
    owner_url: "https://libecity.com/user_profile/towUkn1SteUel7nCjqVCbBNmpOQ2",
    co_owners: "のりすけ\n鯱旗\nフウタロウ\nばりとーん\nいとやん\n小さなエビ\nこきなこ\nかっぴー",
    categories: "ハンドメイド販売, ワークショップ, 展示, 夜フェス企画あり",
  },
  {
    num: 101,
    id: 99,
    search_id: 99,
    name: "レーザー刻印堂",
    type: "一般有料ブース",
    css_type: "PB",
    content:
      "レーザー刻印であなただけのオリジナルグッズを製作します！当日描いたイラスト等その場で刻印可能です。",
    pr: "ご自身のアイコンや当日描いたイラスト等をレーザー刻印して、世界にひとつだけのオリジナルグッズを作成できます！ロボット操作を体験できるゲーム（賞品有り）も開催します。",
    for_whom: "オリジナルグッズを作成してみたい方、ロボットゲームに興味のある方",
    night_content: null,
    flyer_url:
      "https://docs.google.com/presentation/d/1A0-zKl--ai6WFSBCgCIiWKHuzwhJqCYFL24cZHffR50/edit#slide=id.p",
    area: null,
    owner: "あきら@ﾌｪｽ代表|ﾚｰｻﾞｰ刻印堂",
    owner_url: "https://libecity.com/user_profile/EJFQgUmKmWbSOJUHLzTiDgO6KNb2",
    co_owners: "ドンキチ\nなかじ工場長\nちゃり\nかっつん\nぐっ\nかんずー",
    categories: "ハンドメイド販売, ワークショップ, ゲーム",
  },
  {
    num: 102,
    id: 100,
    search_id: 100,
    name: "【世界で１つの言葉を刻みます】レーザーでハンドメイド❤︎ロゴ入れ店",
    type: "一般有料ブース",
    css_type: "PB",
    content:
      "レーザー彫刻機を使って、スプーンやコースターなどに、世界でたった１つの言葉を彫刻します。",
    pr: "印刷と違い、半永久的に消えない文字やイラストをコルクやステンレスに彫刻します。また、せどりへのチャレンジ応援で、中国せどりの原価や手順なども大公開！！",
    for_whom: "フェスの思い出になるオリジナル商品やハンドメイド、中国せどりに興味のある方。",
    night_content: "Webチラシ参照",
    flyer_url:
      "https://drive.google.com/file/d/1VkfSNwWHDmsYTvQWI46MWfe2o1_qe1AT/view?usp=drive_link",
    area: null,
    owner: "橋本（妻まぁこ）",
    owner_url: "https://libecity.com/user_profile/YZbSyihqFufcjjnvHU8tgGDqCjF3",
    co_owners: "橋本まぁこの夫\nなかにゃん♪フェスでプチ出店💛",
    categories: "ハンドメイド販売, 販売・セミナー, 子供向け, 夜フェス企画あり",
  },
  {
    num: 103,
    id: 101,
    search_id: 101,
    name: "占いの森〜ワクワクをあなたへ〜",
    type: "一般有料ブース",
    css_type: "PB",
    content:
      "各種占術を使った占いや日替わりミニイベントに参加したりリベ金言開運くじも引くことができます",
    pr: "占いが初めての方でもコンシエルジュが楽しみ方をご提案して森をイメージした空間でリラックスしながらいろいろな占いを気軽に楽しんで頂けます",
    for_whom: "ワクワクして希望の光が見えてくる体験を味わってもらいたいです",
    night_content: "16時以降にもお楽しみイベントを開催予定です",
    flyer_url:
      " https://drive.google.com/file/d/1y2A0dh_AVxsK20OwcXn5iQpK2Acjb8gc/view?usp=drivesdk",
    area: null,
    owner: "わらびー",
    owner_url: "https://libecity.com/user_profile/gfnGbU2hNCXveDwPm8WFmHIupp32",
    co_owners: "りったん\nリヲヂ\nいぇん\nきよ\n夏子\nちゃぴこ\nはこりん\nあこ",
    categories: "ワークショップ, 占い・診断, 夜フェス企画あり",
  },
  {
    num: 104,
    id: 102,
    search_id: 102,
    name: "お腹ソムリエ研究所",
    type: "一般有料ブース",
    css_type: "PB",
    content: "気になる脂肪も希望に変わる✨お腹のエコーであなたの健康状態がわかります！",
    pr: "お腹にエコーを当てるだけ！たった5分🖐️であなたの健康を数値化します！内臓脂肪、腹筋、皮下脂肪を測定し生活習慣を分析！結果を専門家がお一人ずつにわかりやすく解説します😊",
    for_whom:
      "脂肪が付きやすい・やせにくいなどお腹周りが気になる方！健康資産をアップする秘訣を知りたい方！など、自分のお腹のことが知りたい方に!",
    night_content: null,
    flyer_url:
      " https://www.canva.com/design/DAFkq6UKkys/ST2PF709p5Vc0gAK38x9rg/view?utm_content=DAFkq6UKkys&utm_campaign=designshare&utm_medium=link&utm_source=viewer ",
    area: null,
    owner: "ちー/開業ナース",
    owner_url: "https://libecity.com/user_profile/n0nqNdv8kaQWSKBTNctoMEvoVcI3",
    co_owners:
      "お腹ソムリエ\nせいじぃ\nはった\nりせりくる\nえむ\nりい\nミック\nえちゅ\nあそ\nさくら\nまりか\nクローム\nすん\nまるえ",
    categories: "健康",
  },
  {
    num: 105,
    id: 103,
    search_id: 103,
    name: "リベシティトレーナーブース🏋️‍♀️",
    type: "一般有料ブース",
    css_type: "PB",
    content:
      "1日目：ダイエットお悩み相談所\n2日目3日目：クイック股関節矯正\n3日間共通：懸垂チャレンジ",
    pr: "正しいダイエットで理想を叶えるためのサポート\n姿勢のお悩みを取り除くための施術と1人でも簡単にできるエクササイズ\n懸垂チャレンジやくじ引きでイベントとしても楽しめる空間",
    for_whom:
      "健康寿命にこだわりたい方や食事に困っている方\nスマホやPCの多用により姿勢や痛みで悩んでいる方\nリベ大フェスを楽しみたい方",
    night_content: null,
    flyer_url:
      "https://www.canva.com/design/DAFhR1CTp1Y/cjAXiH2ms2V-XoVVZzrXWQ/view?utm_content=DAFhR1CTp1Y&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
    area: null,
    owner: "トモキ@フィットネス経営者🏋️‍♀️",
    owner_url: "https://libecity.com/user_profile/b4RN8yw8OocYC4kJt7WFkDmMmna2",
    co_owners:
      "シメサバ🔥インスタ万垢ダイエットコーチ\nRavi💪🏾湘南パーソナルトレーナー\nみづき🧘‍♀️リベヨガチャット東京\nこば@骨の写真のお姉さん\nみりにゃん@リベヨガチャット名古屋\nNick@超ダイエットコーチ\nチャミー＠広告デザイナー×トレーナー\nくまタン6パック専門家‼️インスタ挑戦中\nLV（えるぶい）@美容系インスタ\nなっち@メイク×パーソナルカラー診断🌈\nカエデ@小学校教諭",
    categories: "健康, 体験, 交流",
  },
  {
    num: 106,
    id: 104,
    search_id: 104,
    name: "自分の魅せ方教えます！自己肯定感があがる占い",
    type: "一般有料ブース",
    css_type: "PB",
    content:
      "\n手相・タロット・易(筮竹)・九星気学を利用した占いを提供します。占い未経験の方こそお越しください！",
    pr: "占いでこれから稼ぎたい人を応援したい！という思いで出店したブースです！占いを体験したい方はもちろん、占い師になりたい方もぜひお立ち寄りください！リベならではの内容でお届け致します！",
    for_whom: "誰かに聞いて欲しい思いのある方、この先の運勢を知りたい方！",
    night_content: null,
    flyer_url: "https://drive.google.com/file/d/1q_g0ERxidBpQ96EbOhjwkRZkM0P6xITb/view?usp=sharing",
    area: null,
    owner: "占いカメラマンたちばなゆうき",
    owner_url: "https://libecity.com/user_profile/77lmkNoF3tVJPY7PqIoBgao3F4w2",
    co_owners: "石原一華＠九星気学鑑定士",
    categories: "占い・診断",
  },
  {
    num: 107,
    id: 105,
    search_id: 105,
    name: "指紋スイッチ®︎ONで動物の体を呼び覚まそう！",
    type: "一般有料ブース",
    css_type: "PB",
    content:
      "足の指に触れるだけで、不調知らず！ケガ知らず！全身を動物の体に整えるメソッドを提供します",
    pr: "足指に存在する秘密のスイッチをONするだけで、誰でも健康資産をゲット出来ます！不調知らずの動物の体、ぜひ体感してみてください！",
    for_whom: "健康資産を手に入れたい方、不調にお悩みの方、生きる力をアップしたい方",
    night_content: "お腹引き締め効果抜群の“腹圧セッション”プレゼント♪　※Webチラシ参照",
    flyer_url:
      "https://www.canva.com/design/DAFhvbDbptY/BVEqWQUxLqciBwxKIQBWtg/view?utm_content=DAFhvbDbptY&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
    area: null,
    owner: "指紋",
    owner_url:
      "https://www.google.com/url?q=https://libecity.com/user_profile/5QzvdzAIgjQWj0ePykTJ6EpEUfj1&sa=D&source=editors&ust=1683008494190219&usg=AOvVaw2Yxt5enWkISt10Z1JUf9QO",
    co_owners: "アサミ\n悦びラフター\nしなやか骨盤\nみよ",
    categories: "健康, 体験, 美容・ファッション, 夜フェス企画あり",
  },
  {
    num: 108,
    id: 106,
    search_id: 106,
    name: "武術を気軽に体験ブース",
    type: "一般有料ブース",
    css_type: "PB",
    content: "力を全く使わない「武術の技」を、誰でもその場で体験して楽しんでもらえるブースです。",
    pr: "目的はただ一つ。「身体的自由」を手に入れてもらうことです。力を使わない、気付いたら転がされているような身体操作を体験出来るだけでなく、その場で会得出来るまでを楽しめます。",
    for_whom: "今まで体験したことのない健康資産を知りたい方向けです。",
    night_content: "夜フェス専用の体験技を行います。",
    flyer_url:
      "https://www.canva.com/design/DAFg-p-4MDE/leKIWLNHY5K0uoPg-trGjQ/view?utm_content=DAFg-p-4MDE&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
    area: null,
    owner: "リツ＠武術の達人養成家",
    owner_url: "https://libecity.com/user_profile/XLLFnBRMhPZbEVDoytLr7gF3eD02",
    co_owners:
      "魚沼マイコ\n空手のハリー\nりょん\nけい\nかめのすけ\nkaz\nタィチー\n塾長\nゆき\nみくる∞\nみるも\n梅子\nひろせ\n指紋\nまえしょー\n春織（はお）\n鍼灸師ことぶき\nトニ〜\nてるま\nmai\nKisuke",
    categories: "ワークショップ, 健康, 体験, 夜フェス企画あり",
  },
  {
    num: 109,
    id: 107,
    search_id: 107,
    name: "ケン先生の！InBodyで身体チェックと簡単姿勢評価",
    type: "一般有料ブース",
    css_type: "PB",
    content:
      "凄い機械を使って身体を測定し見えないものを数字化します。また、ケンが簡単な姿勢評価をします。",
    pr: "I nBodyという機械を使い、見た目では解らない内容を数字で評価！姿勢評価は自分の姿勢が解らない方、猫背や反り腰、腰痛、肩こりの方、理由が知りたい方にお勧めです💪",
    for_whom: "姿勢が気になる、筋肉、脂肪の量が気になる、健康になりたい方にお勧め",
    night_content: null,
    flyer_url:
      "https://www.canva.com/design/DAFijaDbgKY/hJMmclU1i8rvxTdzCamdCQ/view?utm_content=DAFijaDbgKY&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
    area: null,
    owner: "ケン＠学長の🥊の先生",
    owner_url: "https://libecity.com/user_profile/KyQV2jFXlaeoAQRjilYcURUDGTf2",
    co_owners: "AYU\nあも\nてぃー\n迷子のひー\nなえたん\nアラタ\nはせ先生",
    categories: "ワークショップ, 健康, 体験",
  },
  {
    num: 110,
    id: 108,
    search_id: 108,
    name: "【フェスメモリー】フェスの思い出を学長に共有しよーぜ！",
    type: "一般有料ブース",
    css_type: "PB",
    content:
      "動画編集のプロと一緒に学長にお礼や今後の意気込みを伝えるメッセージムービーを作成し共有するブース✨",
    pr: "動画編集初心者でもプロのクオリティが手軽に作れます！\n編集マニュアルとともに準備済のアイテムでカンタンにメッセージ動画制作が可能です♪",
    for_whom: "学長への感謝や意気込みをキレイな動画に残したい方！大歓迎！",
    night_content:
      "夜の時間専用のアイテムで、両子ママの夜フェス仕様の動画を作成することができるので、昼・夜どちらも要チェック！",
    flyer_url:
      "https://www.canva.com/design/DAFiDKPiuEE/SgWrsbtSY8Q5H2Y0MIvRFw/view?utm_content=DAFiDKPiuEE&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
    area: null,
    owner: "つかさ",
    owner_url: "https://libecity.com/user_profile/2chnfSzHdjMtMsDgOc67EAgkqtB3",
    co_owners:
      "ちぃ@動画/合宿所にいた元パン屋さん🍞\nあんにん@動画編集\nやんだ🌷動画編集\nあまみ🌸@動画編集×YouTube\nたかしちゃん＠成長中のフリーランス\nばび@動画編集するフリーランス\nふゆ＠動画編集/撮影\nしょーご@動画編集\nつよし@動画編集\nろしごん動画編集@スタジオヒカリエ\nくりお@動画編集\nじゅんや@動画編集\nジャッジ＠動画編集📸近畿管理人👾\nやっすー@動画編集で月5万！不動産勉強中\nフォトるぽんず@動画編集\nかっしー　動画編集-SNS勉強中\nTEL@再船出🚢をする動画編集者📹\nやかん@動画クリエイター\nしも@千葉幕張150人オフ会頑張る😆\n奈子（なこ）@大阪移住計画✨\n主夫ダンナ＠愛媛No1幸せ主夫/動画編集\nエイチ＠営業/インスタ/動画編集\nまる@Midjourney\nあす＠ブライダルネイル✖️動画編集\nけーず＠動画編集挑戦中\nあまえび＠動画編集 元合宿所\nペコちゃん@動画編集\nなか@ビデオグラファー\nあんば〜",
    categories: "ワークショップ, 体験, 写真・動画, 夜フェス企画あり",
  },
  {
    num: 111,
    id: 109,
    search_id: 109,
    name: "大分から真剣売りにきたけん、おおいた手づくり工房見てっちゃ！",
    type: "一般有料ブース",
    css_type: "PB",
    content:
      "リベ活が１００倍楽しくなる♪\n　　～リベ活応援グッズの販売～\nフェス満喫♪～ワークショップ～",
    pr: "ミニ学長フィギュアの販売やフェスで目立っちゃうTシャツプリントもできるっち！\nゆるかわいい、消しゴムはんことつまみ細工のワークショップもするけん、遊びにきてな～。",
    for_whom: "「親子」でも、「ぼっち」でも楽しめるワークショップを企画しちょんで～！",
    night_content:
      "夜フェス限定商品と、特別なワークショップを考えちょんけん、Webチラシ確認してくりぃ～。",
    flyer_url:
      "https://www.canva.com/design/DAFiIKK9Qjo/0Lj9d0VLG91LRAH8sEsuJw/view?utm_content=DAFiIKK9Qjo&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
    area: null,
    owner: "ももまお",
    owner_url:
      "https://libecity.com/user_profile/URWr4GAczueW9RKUP00grc2lrRO2?redirect=/room_list?comment_id=yOLoTdDl3JF3OnkBUwkA%26room_id=orwNjgRURPtC1fYV8mEA",
    co_owners: "nico\nるりたまあざみ\nさなぎ",
    categories: "ハンドメイド販売, ワークショップ, 子供向け, 夜フェス企画あり",
  },
  {
    num: 112,
    id: 110,
    search_id: 110,
    name: "Hub Fun!!  ～あなたとハンドメイドが繋がるスポット～",
    type: "一般有料ブース",
    css_type: "PB",
    content:
      "①10名のオリジナル作品販売\n②5つの無料ミニ撮影スポット\n③ハンドメイド活動応援ワークブック",
    pr: "「ハンドメイドと繋がって欲しい」\nそんな想いから、来場者全員が楽しめるようフェスの思い出として『限定グッズ』や『ぬいぐるみ撮影スポット』など様々な企画を用意しました♪",
    for_whom: "ハンドメイド作品に興味がある方。\nフェスを楽しみたい方。\n作家を目指す方。",
    night_content:
      "「経験豊富な作家とじっくり話せる！井戸端ミニオフ会！」\nゆっくり話せる夕方の機会に是非お越しください！",
    flyer_url: "https://drive.google.com/file/d/1qs-au8JQx87Axm7N4tmbcoAcfArmkEY_/view?usp=sharing",
    area: null,
    owner: "さいふぉん",
    owner_url: "https://libecity.com/user_profile/kNahDsn7ZwdfR4WyN0h394SqLTs2",
    co_owners: "Risa\nくるみん\nりん\nこゆき\nまっす\nラボ\nゆあ\nぷりん\nルアン",
    categories: "ハンドメイド販売, 写真・動画, 交流, 夜フェス企画あり",
  },
  {
    num: 113,
    id: 111,
    search_id: 111,
    name: "メダカ掬い＆メダカ副業相談～掬ったメダカで年収1000万～",
    type: "一般有料ブース",
    css_type: "PB",
    content: "リベ大フェスに縁日を！メダカ掬い&メダカ展示販売&アクアリウム副業相談を提供します！",
    pr: "①掬い用のメダカを3,000匹以上ご用意！\n②メダカ専門店の店長やメダカ副業で稼いでいるメンバーが出店！\n③ペア数万円で取引されている品種を展示・販売！",
    for_whom: "メダカ掬いを楽しみたい子供からメダカ副業に興味ある大人まで楽しめるブースです！",
    night_content: null,
    flyer_url: "https://drive.google.com/file/d/1cJ7C4lio86VRVdeCLXM3y8PwM7hAkrHo/view?usp=sharing",
    area: null,
    owner: "タオ@メダカのYouTuber",
    owner_url: "https://libecity.com/user_profile/GKipERuIj8Xwrj9KGF9SccoRpuy1",
    co_owners:
      "とみ@セカンドアクア/熱帯魚屋/買取専門\nYuri@らめっちメダカ\nさくらリウム@メダカ飼育YouTuber\nたかもり@メダカ＋αで個人事業主になる\nプラチナメダカ島根県のメダカ屋さん\nメダカ屋•ブラックメダカ\nねずみいぬ@メダカ、せどり物販\nたかゆき　専業ブログ・アフィリ",
    categories: "販売・セミナー, 子供向け, いきもの",
  },
  {
    num: 114,
    id: 112,
    search_id: 112,
    name: "お金の大学〜夏期講習〜各界のスペシャリストが大集合",
    type: "一般無料ブース",
    css_type: "NB",
    content:
      "「士業チーム（弁護士・税理士・社労士など）」がお贈りする「5つの力」を高めるための特別セミナー",
    pr: "メインプレゼン（30～40分）・ミニッツプレゼン（10～15分）・総合座談会・カフェスタイル相談会など、様々な形式で学ぶ「ひと夏限りの夏期講習」みんなで楽しく５つの力を高めよう！",
    for_whom: "「リベ大・リベシティ」に参加しているすべての皆様に楽しく学んでいただけます！",
    night_content: null,
    flyer_url: "https://drive.google.com/file/d/1WKKhgnw4pc_imGpWAwyHro8IE_eGZSc-/view?usp=sharing",
    area: null,
    owner: "たかさん",
    owner_url: "https://libecity.com/user_profile/gjl408Tjoxc37kO4ODZXXzX2Afr2",
    co_owners:
      "セイケイ\nひなた\nかずぅ\nきかわ\n経理のけいちゃん\nまるさん\nせいちゃん\nまさし\nまめべん\nおみっち/小美野達之\nたけちゃんまん\nnick\nちぇる\nわたあめ\nコーリ\nきゃっしい\nあやか\nのりこ\nリコ\nニケ\nミムロ\nひまり\n右京\nテニシバ",
    categories: "販売・セミナー, ワークショップ, 家計・税金",
  },
  {
    num: 115,
    id: 113,
    search_id: 113,
    name: "つながる喫茶　〜バーチャルオフィスも活用しよか〜",
    type: "一般無料ブース",
    css_type: "NB",
    content: "バーチャルオフィス体験、目標設定、朝活体験談を通して参加者と交流を深めていきます！",
    pr: "カフェのようにホッとできる空間で交流を楽しめます。朝活をしている総勢60名のメンバーが、oViceの使い方や朝活の始め方、目標設定方法についてレクチャーします！",
    for_whom: "誰とも交流できずに帰りたくない方、oViceを活用したい方、朝活を始めたい方",
    night_content:
      "【朝礼スピーチ神7】朝活のモチベーションが高まるoVice朝礼。過去スピーチの人気TOP7を発表します！（7/16のみ実施）",
    flyer_url:
      "https://drive.google.com/file/d/11wmo6Mdf0Pm6V5e6Ve5IEZ4Fq9XVXIOS/view?usp=share_link",
    area: null,
    owner: "ソウソウ",
    owner_url: "https://libecity.com/user_profile/MfY6FJ5Qu4gYUpIzHfQeJ7DCZwB2",
    co_owners:
      "ぐりどん\nまり\nメカバイオ\nトウリ\n浜崎遥翔\nブルーノ\nれおこり\nコロコロネ\nぽん太\nともや\nキッシュ\nまこナッツ",
    categories: "交流, 夜フェス企画あり",
  },
  {
    num: 116,
    id: 114,
    search_id: 114,
    name: "アプリ開発&運営で月5万円稼ぐ！相談会・体験会",
    type: "一般有料ブース",
    css_type: "PB",
    content:
      "ワークス・スキルシェアで出会った３人が、アプリ開発・運営について、お話するブースです。",
    pr: "アプリ開発者と運営者の両方の視点から相談会と座談会を行います。現在運営中の２種類のアプリを体験することも可能。知ってるようで知らないアプリの世界へぜひお越しください。",
    for_whom: "アプリのアイディアがある人や開発したい人・してみたい人をお待ちしてます",
    night_content:
      "ブース内で運営者３名との酒あり雑談会を行います！約１時間程度行う予定※WEBチラシ参照",
    flyer_url:
      "https://www.canva.com/design/DAFg3Y3ju7U/-oh8-2LcOr7eBBv9WTsByg/view?utm_content=DAFg3Y3ju7U&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
    area: null,
    owner: "マサト",
    owner_url: "https://libecity.page.link/LhsaED4562e3LBaTA",
    co_owners: "しゅういち\nのざき",
    categories: "IT・WEB, 交流, 展示, 夜フェス企画あり",
  },
  {
    num: 117,
    id: 115,
    search_id: 115,
    name: "脱毛器メーカーインストラクターと脱毛サロン店長が教える脱毛の真実",
    type: "一般無料ブース",
    css_type: "NB",
    content: '脱毛サロン店長や脱毛器メーカーインストラクター達が、脱毛の"真実"を教えます！',
    pr: "脱毛について短時間で知識を得る事ができ、その知識を用いて自分に合った脱毛店を選択する事が出来ます！\nまた、お試し脱毛コーナーを設けておりますので是非立ち寄ってみて下さい！",
    for_whom:
      "どの脱毛店に通えば良いか悩んでいる、脱毛トラブルを避けて自分に合った脱毛店を見つける知識を付けたい方！",
    night_content: null,
    flyer_url: "https://shinagawa-mens-labo.com/libefes/",
    area: null,
    owner: "ななむ",
    owner_url:
      "https://libecity.com/user_profile/TAVNWZoZzHU4bZyYZWc3W4CchLc2?redirect=/search?comment_id=TdWh9xE1LQlZZWEZHXYE",
    co_owners: "ヒロjoint\n平澤\n根本\n内田\n杉原",
    categories: "ワークショップ, 体験, 美容・ファッション",
  },
  {
    num: 118,
    id: 116,
    search_id: 116,
    name: " SENSE(センス)30分で姿勢が変わる！体験",
    type: "一般有料ブース",
    css_type: "PB",
    content:
      "✅猫背さん集まれ！30分で姿勢が変わるレッスンと雑談会\n✅リベ会員開発の骨格分析アプリで姿勢チェック",
    pr: "30分の体験で、猫背・タレ尻を変える❗️\nレオタード学長と待ってるよ❗️\nリベ会員開発の姿勢分析アプリで姿勢変化を確認♩\n姿勢の雑談会でほねことほねおに身体の悩み相談ができる♩",
    for_whom: "猫背やタレ尻などのお悩みがある人、姿勢が気になる人、忙しくて運動不足な人",
    night_content: "\n16時からの夜フェス時限定\n両子ママの秘密の美尻レッスン💋開催します",
    flyer_url:
      "https://www.canva.com/design/DAFg-QfeasY/GWO6pvbTOm1Kj3xyIDYgKg/view?utm_content=DAFg-QfeasY&utm_campaign=designshare&utm_medium=link&utm_source=homepage_design_menu",
    area: null,
    owner: "ほねこ@フェス出店30分で姿勢が変わる",
    owner_url: "https://libecity.com/user_profile/UXqkpZk7GfTNVUUMuLpq74eCB2b2",
    co_owners:
      "ほねお@フェス出店30分で姿勢が変わる\nいちさん\n元気なさくらさくさん\nはねだから世界へさん\nるんさん\nまるえさん\nケンさん\nライムミントさん\nSENSEスタッフ",
    categories: "健康, 体験, 夜フェス企画あり",
  },
  {
    num: 119,
    id: 117,
    search_id: 117,
    name: "みんなのお絵描き屋さん",
    type: "一般無料ブース",
    css_type: "NB",
    content:
      "イラスト展示、イラストに関する相談コーナー、絵描きによるデジタルライブペイントを行います！",
    pr: "40名の絵描きのイラストが一面に飾られた楽しいブースです！さらにイラストで稼ぐ方法、描き方が学べて、制作依頼のポイント・効果的な活用法も知ることができます！",
    for_whom: "イラストで稼ぎたい方、イラストを使いたい方の疑問を解消いたします♪",
    night_content:
      "昼のライブペイントに加えて、夜のライブペイントを行います～♪ぜひ見に来てください！",
    flyer_url: "https://www.canva.com/design/DAFiCLqLNY0/j5YrJznXWawf3retpAvGrA/view",
    area: null,
    owner: "さんどおいっち",
    owner_url: "https://libecity.com/user_profile/eCXMhp05bmXHboA1d9PegQyhV6e2",
    co_owners:
      "こなか\nさんどおいっち\nBYN(べやん)\n葉月ねここ\nぜろki\nさぶちゃ\n本梨こなか\nすあま\nいなか\nマルコ\nきりん\nエイジュ\nmimi\nはまち\n海辺\nコウヨウ\nふか丸\nイロ(塗り師)\n魚路えりん\nゆずこ\nさやか\n柚木ロウ\nしまペンギン\n田中あや\nわだきみ\nたかちゃん♬\nももこ\nえみねむ\n兎子（うさこ）\nきょん\nえりぼん\nにーに\nまゆみさん。\nめぐ\nあさい\n海山らな\n紅 シロド\nむつ\nやまみ\nショルハン\nなつ\nたさに（ボランティア）\n節子（ボランティア）",
    categories: "交流, 展示, 似顔絵・イラスト, 夜フェス企画あり",
  },
  {
    num: 120,
    id: 118,
    search_id: 118,
    name: "稼ぐ力UP⤴ホームページ大改造!!劇的ビフォ→アフター",
    type: "一般有料ブース",
    css_type: "PB",
    content: "Web制作のプロが、相談会・勉強会・公開添削を開催！HPの改善案や、Web制作のコツを伝授！",
    pr: "Web業界で活躍中のプロ集団が集結🌟\n事業者向け：HP改善や発注のコツを伝授💡\nWeb制作者向け：添削・キャリア等のお悩み相談✨\n★必見★HPの公開添削会を開催🎉",
    for_whom: "稼げるHPを作りたい事業者\nWeb制作で稼げるようになりたい初学者",
    night_content:
      "勉強会スライドやヒアリングシートなどのお役立ち情報をプレゼント！\n昼間帯に比べてお得な料金枠が登場！",
    flyer_url: "https://flyer.hpgekiteki2023.com/flyer.png",
    area: null,
    owner: "さとし",
    owner_url: "https://libecity.com/user_profile/h2Twivzxkwems3LThlEhOHMZmpg2",
    co_owners:
      "じゅんき\nJumi\nにしティー\nジュンイチ\nケンシロウ\nちい\nまこメル\nはせがわ\n☆ゴー\nしず\nYK\nたすく\nとらまる\nエミ",
    categories: "販売・セミナー, ワークショップ, IT・WEB, 夜フェス企画あり",
  },
  {
    num: 121,
    id: 119,
    search_id: 119,
    name: "全国ブロガー交流会inリベ大フェス",
    type: "一般有料ブース",
    css_type: "PB",
    content:
      "ブロガー交流会や推敲会で稼ぐヒントを見つけよう♪\nブログに興味がある人、おしゃべりしよ～！",
    pr: "ブログで稼ぎたい人に40回以上実施した交流会を幕張で開催！\n学長の「人に会おう」で悩みが解決し、行動した人は結果が出ています。\nブログの不安をみんなで話し合いましょう！",
    for_whom: "ブログを始めたい方や、今のブログに悩んでいる方を全力サポートします♪",
    night_content:
      "マネーリテラシーが上がるタイピングゲーム大会を開催！\n3日間限定のゲームをプレイしに行こう！",
    flyer_url:
      "https://www.canva.com/design/DAFiInSctIc/bSU5OCSDyrvYiSA62xDFDw/view?utm_content=DAFiInSctIc&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
    area: null,
    owner: "ゆう＠全国ブログ交流会inフェス",
    owner_url: "https://libecity.com/user_profile/3fUuBaHr6Xai7WFdX9ZmtaOOsfv2",
    co_owners:
      "はじめ＠全国ブログ交流会inフェス\nみちひろ＠全国ブログ交流会inフェス\nカズ@全国ブログ交流会inフェス\nかな＠全国ブログ交流会inフェス\nスミレ＠全国ブログ交流会inフェス\nTaku@全国ブログ交流会inフェス\nジョニー@全国ブログ交流会inフェス\nまこ@全国ブログ交流会inフェス\nみや#あおいメダカ@通信費アドバイザー\nisisi(いしし)＠スマホ画面ブロガー\nカピゾー@ライブ図解・カタン・ブログ\nじんる＠ブログ40代育ち盛り\nはるはら🌸フェス英語ブース【133】\nAIT カン＠青ひげ隠しコスメブロガー",
    categories: "販売・セミナー, IT・WEB, 交流, 夜フェス企画あり",
  },
  {
    num: 122,
    id: 120,
    search_id: 120,
    name: "WEBライターはじめの一歩応援隊",
    type: "一般無料ブース",
    css_type: "NB",
    content: "WEBライターを始めたい方、WEBライターで副業収入5万円↑を達成したい方の背中を強く押す",
    pr: "様々なライターが講師となって、WEBライティングの「楽しさ」「やりがい」をお伝えします！実際にライターとしての一歩を踏み出せるワークショップや、川柳・ツボ押し（？）も体験できます！",
    for_whom: "WEBライターになりたい方や書くことに興味のある方",
    night_content: null,
    flyer_url:
      "https://drive.google.com/file/d/1ie_-4dAK50yy8pyZKJmIBJA8aSG9rTYW/view?usp=drivesdk",
    area: null,
    owner: "駒井真",
    owner_url: "https://libecity.com/user_profile/I0TX7uhQ3RV7PEKgizE39ZfHjGa2",
    co_owners: "いません\nカズキ\n沙沙\nモッシー\nちはる\nツバメ",
    categories: "ワークショップ, 交流, 展示",
  },
  {
    num: 123,
    id: 121,
    search_id: 121,
    name: "＼初心者大歓迎／万垢集団が教える月5万稼げるインスタ運用",
    type: "一般有料ブース",
    css_type: "PB",
    content: "フォロワー1万人以上のインスタグラマー集団がInstagram運用の極意を伝授！",
    pr: "Instagramでフォロワーを伸ばす・バズる極意をギュギュッと濃縮して３種類のワークショップにしました！初心者から中級者まで、必ず成長につなげます。来なきゃもったいないです。",
    for_whom: "Instagramで副業をしたい初心者・中級者向けのワークショップ",
    night_content: null,
    flyer_url:
      "https://drive.google.com/file/d/1EJ5YVQMu5Xm1SdCTYBR3hjTI-kBa3hl6/view?usp=share_link",
    area: null,
    owner: "りこぴん",
    owner_url: "https://libecity.com/user_profile/RLRjCRNhb6PUcFA75elUFDzIfEf1",
    co_owners:
      "しゃけ\nすもも、Yuya、てら丸、あっくん、りお、スターピー、さな、パス、きき、まさと、ぽーやん、rikopin、はる、nami、まっすー、みらい、まちお、Annie、レイジパパ、すいちゃん、ぴな、まるちゃん、さっさん、くっきー、さっこ、しほ、えちゃ、リナ、テナベ、なっちゃん、にじ、やす、みーママ、イチ、ショーマン、ヒロ",
    categories: "販売・セミナー, ワークショップ, 写真・動画",
  },
  {
    num: 124,
    id: 122,
    search_id: 122,
    name: "ペットとの生活力向上へ〜ペットとの生活に守る力と稼ぐ力を〜",
    type: "一般有料ブース",
    css_type: "PB",
    content:
      "ペット特化ブース。ペットとの生活の「健康」から「経済」まで！無料体験・無料相談、有料セミナーあり。",
    pr: "飼い主・獣医師・専門家の【各プロ】目線が作り上げたどうぶつさんとの生活力向上ブース。「健康」から「経済」まで様々なコンテンツを用意。人数限定のセミナーは必見です！",
    for_whom: "ペットを飼っている方・ペット関連での副業、事業者におすすめ！",
    night_content: "ペットとの生活をより良くしたい人、ペット関連事業者や挑戦したい人におすすめ！",
    flyer_url:
      "https://drive.google.com/file/d/1Bod7AoqyXSSuIU7QADny4q6PwhE5UL8C/view?usp=drivesdk",
    area: null,
    owner: "わたはる",
    owner_url:
      "https://libecity.com/user_profile/mCcr1AcfN6SDfNL5Ji7NSdZS9kV2?redirect=/search_user?",
    co_owners:
      "たぁ\nぽてこ\nあぽろる\nみそや庵\nひろ\nあゆみ\nだる\nにゃん太\nはざま\nnagi\nそらるん\nはな\nみーと\nミッキー",
    categories: "販売・セミナー, 体験, 交流, 夜フェス企画あり",
  },
  {
    num: 125,
    id: 123,
    search_id: 123,
    name: "あなたのSTRENGTH（強み）をナビゲートするセミナー",
    type: "一般無料ブース",
    css_type: "NB",
    content:
      "稼ぐ力を伸ばすためにストレングスファインダーなどを用いて自己理解を深めるセミナーを開催します！",
    pr: "ストレングスファインダーを受けたことのある人、ない人どちらでもセミナーを受けれる様に２種類のセミナーを開催します。また、チームビルディングのセミナーも開催します。",
    for_whom:
      "自分の強みを知り、活用したい人や、チームの強みを知り、活用したい人におすすめのブースです！",
    night_content: null,
    flyer_url:
      "https://www.canva.com/design/DAFgg0LI7iE/aO0090ijHaoL8jAUy5PoJw/view?utm_content=DAFgg0LI7iE&utm_campaign=designshare&utm_medium=link&utm_source=viewer",
    area: null,
    owner: "みく",
    owner_url: "https://libecity.com/user_profile/HEK8KGokfGVMlEdHCCONj0Pk4CA2",
    co_owners: "たいが\nきりん\nかつ\nおだんご\nアトム\nおかむー\nちぇる",
    categories: "販売・セミナー",
  },
  {
    num: 126,
    id: 124,
    search_id: 124,
    name: "転職相談ブース【てんしょく堂】プロの転職サポートを無料で提供",
    type: "一般無料ブース",
    css_type: "NB",
    content:
      "19名の転職相談のプロによる個別相談、セミナー・交流会を実施。参加者の天職を見つけるお手伝いをします",
    pr: "人事、転職エージェント、転職経験者、労務など、多角的な視点から来場者のお悩み解決の方向性を一緒に考えます。必ずあなたに合った転職サポーターがいます。是非、お気軽にご来場ください。",
    for_whom: "漠然とお仕事・転職に不安・不満を抱えている方",
    night_content:
      "酒処「てんしょく堂」フェスのおいしいお酒とお料理を持ち込んで。お仕事・転職のあれやこれやを楽しくお話・交流しませんか？",
    flyer_url:
      "https://www.canva.com/design/DAFiDuXKOjY/L5FovnCpykcNi2beEYqCzw/view?utm_content=DAFiDuXKOjY&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
    area: null,
    owner: "パロ",
    owner_url: "https://libecity.com/user_profile/aw0YMt4aDmRVYtSR5AffwayLCHc2",
    co_owners:
      "ぽてとら\nおんた\nきょじんくん\nこうじ\nじぇー\nしょーた\nしろ\nたいよ～\nたぎー\nちみぃ。\nテツロウ\nトウ\nトッティ\nのら猫\nnorikonorinori\nまるこい\nヨネ\nリョウイチロウ",
    categories: "販売・セミナー, ワークショップ, 交流, 夜フェス企画あり",
  },
  {
    num: 127,
    id: 125,
    search_id: 125,
    name: "稼ぐ力応援！LINE公式アカウントで月5万円達成ブース",
    type: "一般有料ブース",
    css_type: "PB",
    content: "稼ぐ力初心者カモン！LINE公式アカウントで稼ぐ先輩たちが、月5万円を稼ぐ秘訣を教えます♪",
    pr: "第1回リベ大フェスの公式LINEブースに参加して稼げるようになったメンバーが、月5万円を達成する秘訣を教えます！さあ、あなたも今アツい公式LINEで稼ぎませんか？",
    for_whom: "月5万円を達成したい人、公式LINEを活用したい事業者さまにオススメです♪",
    night_content:
      "公式LINEで鬼稼いでいる先輩たちのフリートークを開催！ぶっちゃけトークをお見逃しなく♪",
    flyer_url:
      "https://www.canva.com/design/DAFiIbOgW_8/6vxQWVslOxdz10AomnM20w/view?utm_content=DAFiIbOgW_8&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
    area: null,
    owner: "ぴかいち",
    owner_url: "https://libecity.com/user_profile/hqwFg88VMfO82EtzNvkLaWHvltL2",
    co_owners:
      "みつる\nぴかいち\nるちこ\nキッシュ\nケイシン\nしょうこ\nりんごママ\nしろくま\nたか\nけるやま\nたんぽぽ\nらむ\nにこ",
    categories: "販売・セミナー, IT・WEB, 夜フェス企画あり",
  },
  {
    num: 128,
    id: 126,
    search_id: 126,
    name: "メリーGo!自由ランド（by 50チャット）",
    type: "一般有料ブース",
    css_type: "PB",
    content: "50チャット約20人で挑戦するブースです！物販、ワークショップで楽しいが詰まっています♪",
    pr: "物販ではこだわりのハンドメイドや学長ライオングッズ、ワークショップでは楽しい体験が盛りだくさん！当日予約も出来るので是非来て下さいね♡",
    for_whom: "50チャットのパワーが気になる方はぜひお越しください！",
    night_content: "ブース内の両子ママを探せ♪両子ママに合言葉でを言うと良い事あるからお楽しみに！",
    flyer_url: "https://drive.google.com/file/d/1r1rsmJDBk4uHENRAqrha78oxnifMAYPf/view?usp=sharing",
    area: null,
    owner: "いちこ",
    owner_url: "https://libecity.com/user_profile/kvn5u7Ff0LZw5iunPdeDc4ZSxwe2",
    co_owners:
      "でみー\nちぃ\nのりのり\nその\nフジータ\nはるまさ\nルイーズ\nアラフィフさくら\nさとうしお\nもも\nひよっぴー\n乃映\nセツ子\nasuka3世\nウエスティン\nじゅん\nかずお\nmami",
    categories: "ハンドメイド販売, ワークショップ, 似顔絵・イラスト, 夜フェス企画あり",
  },
  {
    num: 129,
    id: 127,
    search_id: 127,
    name: "30人のハンドメイド⚒リベ工房",
    type: "一般有料ブース",
    css_type: "PB",
    content:
      "日本全国から集まった30名のハンドメイド販売＆ワークショップです。是非推しを探しにきてくださいね。",
    pr: "日本全国北は北海道、南は沖縄から集まった30人のハンドメイドブースです。\n初心者だからできること、多くの仲間の世界観。\n老若男女問わず気軽に楽しんで頂けるブースです。",
    for_whom: "ハンドメイドを取り入れて、日々の暮らしにちょっとした幸せをプラスしたい方",
    night_content:
      "夜フェス専用の大人なハンドメイド作品をご用意しています。※一部変更になる可能性があります。",
    flyer_url:
      "https://drive.google.com/file/d/1uOPV-EUIYHOGXzViPNclhcs1YLJWFDqZ/view?usp=share_link",
    area: null,
    owner: "みみ太郎",
    owner_url: "https://libecity.com/user_profile/FYVV7mtdLgeJBbVuBaGVZ4jCG173",
    co_owners:
      "みみ太郎\nゆきは\nマルコ\nあさひ\nぺえ\nミヤコヤ\nデイジー❁\nゆわ\n朔良\nくるくみ\nLIRICO❤︎\nこねこ\nみなみな\nなな\nみれい\nMOMO\n青丸\nはせ\nふたば\nじゃーこ\nメル\nドンキーちゃん\n大魔神\nきい\nみかん\nみずき\nままま",
    categories: "ハンドメイド販売, ワークショップ, 美容・ファッション, 夜フェス企画あり",
  },
  {
    num: 130,
    id: 128,
    search_id: 128,
    name: "学長ダービーチャットチーム",
    type: "一般有料ブース",
    css_type: "PB",
    content: "ポジティブ・アクティブなダビチャメンバー🐎がお送りするフェスの中のお祭りブース",
    pr: "前代未聞！学長ライブをこよなく愛すダビチャメンバー100名がフェスに大集合🐎\n交流あり！イベントあり！物販あり！個性あふれるメンバーに囲まれてキミもきっとポジティブに行動できる！",
    for_whom: "学長（ライブ）を愛する方、ポジティブに行動したいすべてのリベ大生へ",
    night_content: "ガチャガチャの当たり確率UP、専用商品提供 and more-san...",
    flyer_url:
      "https://www.canva.com/design/DAFiGZPgTCA/bosAz4VyEVYOGZpLFaZASw/view?utm_content=DAFiGZPgTCA&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
    area: null,
    owner: "SG",
    owner_url: "https://libecity.com/user_profile/fIaO0WskRlXNYIgwlZovbPKiWsm2",
    co_owners: "ホビ\nダービーチャットチームメンバー約100名",
    categories: "ハンドメイド販売, 販売・セミナー, 交流, 夜フェス企画あり",
  },
  {
    num: 131,
    id: 129,
    search_id: 129,
    name: "きつねの交流ブース〜フェスから広がる親切の輪〜",
    type: "一般有料ブース",
    css_type: "PB",
    content:
      "リベの人生論をベースに各種交流サービスを提供し、\n参加者の心が豊かになるブースを目指します！",
    pr: "「参加者はみんな仲間」を合言葉に、ブース内でオフ会体験、ブース外でフェス会場を案内しながら交流します！誰でも参加できるアイデア満載なので一人の方も気軽にどうぞ！",
    for_whom: "新たな出会いと豊かな人生のきっかけを求めている人はぜひお話ししましょう",
    night_content: null,
    flyer_url: "https://www.canva.com/design/DAFiMqOC1z4/RQbtWH-w_1X-mAytfYh23w/view",
    area: null,
    owner: "ぽめろん",
    owner_url: "https://libecity.com/user_profile/3BtIGW0wbYNfH8sGLmpDrAp4IsG2",
    co_owners:
      "だいご\nきつねとおもち\n山の中の口笛吹き\nあい\nももんが\nくら\nやぎ\nあかりん\nペンペン\nしゅう\nもえ\nカーネル\nちこちこ\nシンヤ\nヘマ\nはなくま\n大魔神\nやこ\nちょめ\nけんいち\nやんやん\nなかしー\nジャッジ\nダイヅ\nシンディ\nchoco\nハリウッドじゅん\nえんちゃん\nえりぽん",
    categories: "ワークショップ, 交流",
  },
  {
    num: 132,
    id: 130,
    search_id: 130,
    name: "リベ大医療チーム 自由と健康をめざして！内視鏡・電気メス体験",
    type: "一般有料ブース",
    css_type: "PB",
    content: "内視鏡・電気メス体験や写真撮影会、特製スクラブや歯ブラシなどの物販を用意しています。",
    pr: "リベクリスタッフやリベシティの医療関係者が参加しており、多くが医師免許を持っている贅沢なチームです。学長ライブで登場するカバ先生や貴公子Yuさんに会えるかも！？\n",
    for_whom: "医療機器を触ってみたい、リベ医療チームに会いたい、限定品が欲しい方。",
    night_content: "16時以降に大人用の特別限定カラーとしてボルドーのスクラブを販売します。",
    flyer_url:
      "https://www.canva.com/design/DAFgho6yf1s/0ECSa_u6tRjaANJ9ZFhDfw/view?utm_content=DAFgho6yf1s&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
    area: null,
    owner: "シゲ",
    owner_url: "https://libecity.com/user_profile/VeNWvdRTvxekqrJQMv9Hmb0O2dP2",
    co_owners:
      "Yu　時給150円のカバ\nあねご\nアンディ\nかえるうさぎ\nかめきち\nキャサリン\nきょうこ\nくう\nくるみ\ngigi\nじゃがいも\nシアル\n耳鼻科医おひょう\nすぐる\nずー\n茶がゆ\nドクターケイ\ntoto\ntomoko\nなおと\nなみえ\nのぶ\nひで\nべてぃお\nぽん\nマーラ\nまるみん\nみみ\nみつを\nむかちゃん\nモッキー\nもりみ\n夜更かしヒツジ\nりえ\nりんご湯\nロゼ\nロックン\nわっきー\nわっこ",
    categories: "健康, 体験, リベ大グループ, 夜フェス企画あり",
  },
  {
    num: 134,
    id: 132,
    search_id: 132,
    name: "世界旅行 〜「いつか海外」を「今」叶えるブース",
    type: "一般無料ブース",
    css_type: "NB",
    content: "①「VRで今すぐ海外体験」②海外エキスパート達によるカウンセリング③プレゼンテーション",
    pr: "世界一周バックパッカーから、現役ワーホリ生、海外移住歴21年のセーシェル在住者、学長と同じモルディブホテル経験者まで、海外エキスパートがあなたの「いつか海外」の夢を叶えます！",
    for_whom: "「いつか海外」という夢が、ずっとバケツリストに置き去りにされたままの方",
    night_content:
      "スナック世界旅行：ここでしか聞けない「絶対内緒の海外のヤバイ話」を、世界中を歩き回ってきたメンバーが、お酒を片手に語り尽くします（変更の可能性あり）",
    flyer_url:
      "https://www.canva.com/design/DAFhSx-593w/XOCC9F-EHH45c-vuAD1KQg/view?utm_content=DAFhSx-593w&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
    area: null,
    owner: "ゆかちょ",
    owner_url: "https://libecity.com/user_profile/nutowjqWa9MXL6Fv5QYj7n9nvNv1",
    co_owners:
      "パル\nゆかちょ\nむろっち\nひよこの子\nYuya\nりょう\nしゃあ\nかめ\nカリベ\nかっぴー\nえん\nしゅうしゅう",
    categories: "販売・セミナー, 交流, 国・地域密着, 夜フェス企画あり",
  },
  {
    num: 135,
    id: 133,
    search_id: 133,
    name: "英語で話そう！稼ごう！繋がろう！",
    type: "一般無料ブース",
    css_type: "NB",
    content:
      "英語について何でも相談できます！ 学習方法・英会話・転職・留学・海外進出 レッスンも多数あり！",
    pr: "英語で世界のニーズに応えて稼ぐ力をパワーアップ！ 私たちは世界を相手に稼ぐ人を増やしたい。英語はその強力なツールです。 稼ぐ力をUPしたいあなたを全力でサポートします。",
    for_whom: "英語の初心者から上級者まで、稼ぎたい・学びたい・頼みたい方はぜひどうぞ！",
    night_content:
      "平日毎朝6:30〜の大人気レッスン「リベグリッシュ」がリアルで夜にも開催！ フェスのみですよ！",
    flyer_url: "https://drive.google.com/file/d/17YROgO0yeYbCr5WpwQx4-ztupcQ39vCJ/view?usp=sharing",
    area: null,
    owner: "よめこ",
    owner_url: "https://libecity.com/user_profile/GjJntAtoeta0Oon7Qli0BmNwwl33",
    co_owners:
      "MAO\nさえこ\nてんちゃん\nたく\nきいぽん\nミー\nまっくす\nともこ\nりょう\nマサヤン\nマコビー\nいっせい\nアロワナ\nひろし隊長\nカプチーノ\nしげさん\nよっしー\nけい\nしゅうしゅう\nオレンジ\nSHIN\nナナミン\n軽トラ\nふわたまみるく\nサンカヨウ\nkazusan\nyuuka\nくるる\nはるはら\nとま\nコエカ\nグレッグ\n莉々",
    categories: "販売・セミナー, ワークショップ, 夜フェス企画あり",
  },
  {
    num: 136,
    id: 134,
    search_id: 134,
    name: "タピオカゲームス - タイピングとゲームづくり体験",
    type: "一般有料ブース",
    css_type: "PB",
    content: "リベ大テーマの非公式タイピングゲーム『タピオカート』を楽しめます！",
    pr: "ゲームづくりの舞台裏をメイキング映像で見ることもでき、ブースではプログラミング体験のワークショップも実施します！",
    for_whom: "子どもから大人まで、ゲームづくりに興味のある方はぜひ遊びにきて下さい♪",
    night_content:
      "最終日のみ16時からPCが倍増！短い待ち時間で、誰でも無料で『タピオカート』を楽しめます♪",
    flyer_url:
      "https://www.canva.com/design/DAFgt_2TAl4/VYwczdm-kgXrsIMKBK5bqQ/view?utm_content=DAFgt_2TAl4&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
    area: null,
    owner: "あまがみ",
    owner_url: "https://libecity.com/user_profile/M5enFkw0rIP87aPP1a1bnmZ8jDW2",
    co_owners:
      "らいん\nケイト\nほにゃ\nリタお\nよっすぃ\nハル\n桜浪漫\nケットディー\nめんま\nゆうにゃん\nきんとん\njunjun\nきとちお\nしゃくね\nかつ\nコレチ\nバッチョ\nきー坊\nフー\nライオンフィッシュ\nリアねこ\n駿\nけい\nつまびく\nyo\nサイノメ\nヨル\nあぎゅ",
    categories: "ワークショップ, 子供向け, ゲーム, 夜フェス企画あり",
  },
  {
    num: 137,
    id: 135,
    search_id: 135,
    name: "3Dプリンター部2.0～最新のモノづくり、お見せします！～",
    type: "一般無料ブース",
    css_type: "NB",
    content: "最先端の3Dプリンター実機デモ・3Dプリンター部作品展示、記念品無料プレゼント！",
    pr: "世界最先端の3Dプリンターの実機デモ、3Dプリンターでのモノづくり方法をご紹介します！フェス名物の無料プレゼント、お子様も楽しめる3Dプリンター製のおもちゃもあります！",
    for_whom: "3Dプリンターが動いているところや、作品を見てみたい！という方向けです！",
    night_content: null,
    flyer_url:
      "https://www.canva.com/design/DAFiAucuW-s/C0YukJA1v33Gt82K670Gfw/view?utm_content=DAFiAucuW-s&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
    area: null,
    owner: "まー@3Dプリンター部長",
    owner_url: "https://libecity.com/user_profile/3pAcvyFHG3aqIHEMj73OdTRthfQ2",
    co_owners:
      "MOZA\nフロー\nよっち\nゆうらる\nヨンケル\nチャスケ\nてぃんく\nユウキ（すだち）\nたっちん\nダンダン\nくろう\nTAKU\nわたまご\nMiNA\nひかる\nもちねこ\nめっちゃやるNaoki",
    categories: "ワークショップ, 展示, ショー・パフォーマンス",
  },
  {
    num: 138,
    id: 136,
    search_id: 136,
    name: "リベキッズパーク🎈",
    type: "一般有料ブース",
    css_type: "PB",
    content:
      "遊んで！稼いで！楽しんで！子どもに最高の一日を！\n託児で素敵な思い出ができるのはこのブースだけ！",
    pr: "親子でフェスを楽しもう！\n0～2歳児の託児利用、年少～小４児童は仕事体験が可能。\n体験で、協賛店で使えるマネーを稼ぎ、使う経験ができる！\n夜は縁日でフェスを満喫しよう。",
    for_whom: "将来につながる体験をしたい子ども\n安心してフェスを楽しみたい保護者",
    night_content:
      "託児サービスはリピーター割引が始まるよ！\nぜひお得に利用してね！\n１８時以降は縁日でお祭りだ！",
    flyer_url:
      "https://www.canva.com/design/DAFhxo9gqXk/bpYUQHNbPXxztogGz5uhQA/view?utm_content=DAFhxo9gqXk&utm_campaign=share_your_design&utm_medium=link&utm_source=shareyourdesignpanel",
    area: null,
    owner: "めぐりん",
    owner_url: "https://libecity.com/user_profile/H7zwOQMSwEZFUeN1heGnTIxuNIv1",
    co_owners: "みっつー",
    categories: "ハンドメイド販売, ワークショップ, 子供向け, 夜フェス企画あり",
  },
  {
    num: 139,
    id: 137,
    search_id: 137,
    name: "リベ大工務店",
    type: "一般有料ブース",
    css_type: "PB",
    content:
      "大人も子供も楽しめるイベントやリベ大工務店オリジナル学長ライオングッズを多数取り揃えております。",
    pr: "家づくりのプロがやぐらの下でお待ちしております♪　家づくりに関する疑問やお悩み、その他何でもご相談あれ！リベ大工務店のオリジナル学長もお待ちしております。",
    for_whom: "一人でも多くのリベ大生に『お金で失敗しない家』を提供したい",
    night_content:
      "両子ママの夜フェス価格の商品や夜フェスでしか買えない商品をご準備してお待ちしております「一部変更になる可能性があります」",
    flyer_url:
      "https://www.canva.com/design/DAFiLUo_kd8/apFRcgumlbrQXIK3QL93Zw/view?utm_content=DAFiLUo_kd8&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
    area: null,
    owner: "さとし",
    owner_url:
      "https://libecity.com/user_profile/3foMsvgLOwRWlCalbGyF8fFl2Ac2?redirect=/room_list?comment_id=ZhL0qnoCYDyo4VZVck63%26room_id=gClaBnl7iIKsZMdEEqjN",
    co_owners:
      "Milk\nさとし\nアツシアツシ\nホウホウ\nヘンリー\nゆった\nあずき\nリザ\nヨッシー\nトヨさん\nha880\nひろひろ\nぱぱおにぎり\nかめぽん\nヤギヌマ\nツメ子\nえみっこ\nこーくん",
    categories: "販売・セミナー, ワークショップ, リベ大グループ, 夜フェス企画あり",
  },
  {
    num: 140,
    id: 138,
    search_id: 138,
    name: "リベシティポールダンス部ブース",
    type: "一般無料ブース",
    css_type: "NB",
    content:
      "ポールダンスショーと体験会を行います。未知の世界へ皆さんを誘います！その魅力を是非味わってください！",
    pr: "ポールダンスはナイトクラブで踊られるようなセクシーさが強調されがちですが、実は子供から年配者まで楽しめる生涯スポーツです！健康資産を増やす優良銘柄としてのポールダンスを提案します！",
    for_whom: "お子様からご年配の方までポールダンスに興味ある方は誰でもウェルカムです!",
    night_content:
      "夜こそポールダンスの聖域！ポールダンスブースも本領を発揮して夜モードに切り替わります💛",
    flyer_url:
      "https://www.canva.com/design/DAFiFhWPzpM/xk0fW51Gq6qZWGP9sCcSKw/view?utm_content=DAFiFhWPzpM&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
    area: null,
    owner: " エムキューエル@ポールダンスブース138",
    owner_url: "https://libecity.com/user_profile/SUifNhddBkgByqcb6DuETXlKEuH2",
    co_owners:
      "りじあ@ポールダンス🧚クレープ🌈🦄\nロバ\nノゾミ\n未来最強きち(吉)@オフィスまでご案内\nひろ@ダンサー兼ダンスインストラクター\nたろう@エクストリームアイロニスト\nちょり@青森に逃亡中\nMina@Youtube_旅_釣り",
    categories: "体験, 子供向け, ショー・パフォーマンス, 夜フェス企画あり",
  },
  {
    num: 141,
    id: 139,
    search_id: 139,
    name: "美容師が、あなたの一番似合うヘアースタイルをご提案",
    type: "一般有料ブース",
    css_type: "PB",
    content:
      "ヘアアレンジや、カラーエクステ（5つの力ライオンと同じカラーエクステ）を付けてフェスを楽しみましょう",
    pr: "ヘアアレンジでは、普段自分では出来ないようなヘアセットを提供します😊\nカラーエクステでは、5つの力ライオンちゃんと同じ色のエクステを付けられる♪",
    for_whom: "ヘアアレンジをしてみたい一般の方♪\nまたは、登壇者（出店者）で気合い入れたい方♪",
    night_content: "5つの力エクステの本数一本増加‼️（一部変更になる可能性がある）",
    flyer_url: "https://drive.google.com/file/d/1FdB7pRpsJ7A6RT7gaiqY9r6zy7J5gFL3/view",
    area: null,
    owner: "ニチェ@美容師ブース（NO.139）",
    owner_url: "https://libecity.com/user_profile/AWT4MRJXrxWxbGhjdPUuK3OXOZq1",
    co_owners:
      "太郎\nたち\nべべ\nジン\nkatsu\nゆかりん\nカエル\nにこナオ\nnico\nやまちぃ\nさととも\nりこ下町の美容師\nタムタム\nノイ\nまふまふ\nはや\njunjun\nYurie\nビーノ\nますいりょう",
    categories: "美容・ファッション, 夜フェス企画あり",
  },
  {
    num: 142,
    id: 140,
    search_id: 140,
    name: "にゃーのアトリエ",
    type: "一般有料ブース",
    css_type: "PB",
    content: "ハンドメイドアクセサリーの販売をします",
    pr: "普段はリベシティで非公開のアクセサリーを特別に販売します。スワロフスキーなどを使用しており、どの年代を問わずお使いいただけるアクセサリーです。",
    for_whom: "女性向けのアクセサリーだけでなく、男性向けの作品もあります。",
    night_content: null,
    flyer_url:
      "https://www.canva.com/design/DAFiO4t175Y/Szh-6k_Lz-lMMYsFSTeHRw/view?utm_content=DAFiO4t175Y&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
    area: null,
    owner: "にゃー",
    owner_url: "https://libecity.com/user_profile/eNCSSbIwpRaZ6TxVFs9L0mpdfwm1",
    co_owners: null,
    categories: "ハンドメイド販売",
  },
  {
    num: 143,
    id: 141,
    search_id: 141,
    name: "イタリアのガラスが使い放題！オリジナルアクセサリーを作ろう！！",
    type: "一般有料ブース",
    css_type: "PB",
    content:
      "小さなお子さまから大人の方まで楽しめるワークショップを開催！学長のステンドグラスもごお紹介します",
    pr: "イタリアの世界遺産の島で作られているヴェネチアングラスを使い、世界にひとつしかない作品を作ることが出来、リベ大フェスを振り返って頂ける記念品となります！",
    for_whom: "ハンドメイドがお好きな方から今までガラスに興味がなかった方も楽しめます！",
    night_content: null,
    flyer_url:
      "https://www.canva.com/design/DAFgYDv27Xk/SxAoR9L1534rozCsy0kr3w/view?utm_content=DAFgYDv27Xk&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
    area: null,
    owner: "こーこー@ガラス職人",
    owner_url: "https://libecity.com/user_profile/hc6Khp9GawgDeKTdE8yF4FhWVF93",
    co_owners:
      "しゅう＠Webエンジニア\nあんな\nいかにんじん＠ブログ・アフィリ\nシホ＠レース・オーダーアクセサリー",
    categories: "ハンドメイド販売, ワークショップ, 体験",
  },
  {
    num: 144,
    id: 142,
    search_id: 142,
    name: "あっつあつネコ祭り",
    type: "一般有料ブース",
    css_type: "PB",
    content: "製作している猫モチーフアクセサリーの\n紹介と、学長モチーフペンダントの受注販売会",
    pr: "手作業で原型を作りネコ科の曲線美を追求。\n金属で柔らかさを出し、可愛らしさも演出。\n資産として残す人も、身に付けてお楽しみください。\nいつも寄り沿ってくれるはずです。",
    for_whom: "猫が好きな方。学長の言葉を大切にしている方へお届けします。",
    night_content: null,
    flyer_url:
      "https://www.canva.com/design/DAFjORNDQ5w/DbDs9q6zEkcyV5XK9EoSmQ/view?utm_content=DAFjORNDQ5w&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
    area: null,
    owner: "おがたねこ",
    owner_url: "https://libecity.com/user_profile/te2tNudUrfZBvsInjBliysMBgA42",
    co_owners: null,
    categories: "ハンドメイド販売, 交流",
  },
  {
    num: 145,
    id: 143,
    search_id: 143,
    name: "いわて南部鉄器本舗～フェス限定! 学長鉄玉・鉄瓶・風鈴販売～",
    type: "一般有料ブース",
    css_type: "PB",
    content:
      "両学長をデザインした南部鉄器を販売！今年しか買えない限定商品をリベ大ファンにお届けします！",
    pr: "老舗の南部鉄器工房『及富』の特別協力のもと、デザインから型作り・鋳造まで行ったこだわりの南部鉄器です！また南部鉄器の特性上、鉄分補給の一助にもなる優れものです！",
    for_whom: "学長ファンの皆さまにはもちろん、鉄分不足にお悩みの方にもおススメです！",
    night_content:
      "\n砂型の問題からお蔵入りになった幻の学長鉄玉を配布等を企画中\n※最新情報はWebチラシ参照",
    flyer_url: "https://www.canva.com/design/DAFiOZ56LMk/kBO5zxUGl17j7VowWseb1Q/view?website#4",
    area: null,
    owner: "おばたく",
    owner_url: "https://libecity.com/user_profile/RdXhJ51joNepsRGmfBiheLv1Qb52",
    co_owners:
      "あろえ\nミルクスキー\nコウシュン\nホヌ\nさなよし\nまいしゃん\nふく太郎\nmi___\nきじねこ\nはぶ\nサキ\nしろろ\nよっけ\nはやかわ318号\nうーちん\nてっつ\n雪穂\nもん",
    categories: "ハンドメイド販売, 販売・セミナー, 健康, 夜フェス企画あり",
  },
  {
    num: 146,
    id: 144,
    search_id: 144,
    name: "折り紙アクセサリー＊ウェルカムブック＊切り絵QRコード",
    type: "一般有料ブース",
    css_type: "PB",
    content:
      "折り紙アクセサリー販売。\n本のウェルカムボード販売&オーダー受注。\n切り絵QRコードオーダー受注。",
    pr: "折り紙や和紙の美しさを、独自のデザインや色彩が楽しめるアクセサリー。\n感動の切り絵と光の融合。ウェルカムブックで特別な日を彩ろう。\n美しい切り絵とQRコードが融合した革新なアート",
    for_whom:
      "ハンドメイドに興味がある方もない方も手作りから生まれる世界にひとつの良さを知ってもらいたい！",
    night_content: "学長切り絵グッズプレゼント（数量限定）※変更になる可能性があります。",
    flyer_url: "https://drive.google.com/file/d/1Ns-Z51Xmjz6-c6QfHjb1UsZbL8yK73Hr/view?usp=sharing",
    area: null,
    owner: "ANNA",
    owner_url: "https://libecity.com/user_profile/lqDAC47EfTarBem1193xQDyar732",
    co_owners: "春ねこ",
    categories: "ハンドメイド販売, 美容・ファッション, 似顔絵・イラスト, 夜フェス企画あり",
  },
  {
    num: 147,
    id: 145,
    search_id: 145,
    name: "【空手道】蹴る！突く！女子プロに蹴られる⁈体験",
    type: "一般有料ブース",
    css_type: "PB",
    content:
      "蹴り・板割り、迫力の演舞、そして女子プロに蹴られる体験！\n今ここでしか味わえない感動がここにある！\n",
    pr: "蹴り・板割りで気分爽快！\n迫力の演舞！その気迫を肌で感じよう！\n女子プロの蹴りはご希望の方に、ご希望の威力でお届け！\n短時間で非日常を楽しめます！\n",
    for_whom: "・身体を動かしたい方！\n・板割りのような非日常を体験したい方！\n",
    night_content: "演武の回数を増やし、より多くの方に楽しんでいただける演出を企画します！！！",
    flyer_url: "https://www.canva.com/design/DAFgbQK91s0/BULWgZgpv7m5QDQZG_TJrw/view",
    area: null,
    owner: "さんかく",
    owner_url: "https://libecity.com/user_profile/i7DhpqmZU9S5btMtO97cTijUvge2",
    co_owners: "せめる\nアミドラム\nケイ\nさらゆき\nちよこ\nななこ\nマリコ\nりんぼう",
    categories: "健康, 体験, ショー・パフォーマンス, 夜フェス企画あり",
  },
  {
    num: 148,
    id: 146,
    search_id: 146,
    name: "エンタメブース 〜リベ大ShowTime！〜",
    type: "一般無料ブース",
    css_type: "NB",
    content:
      "音楽・ダンス・漫才など、多種多様なグループが順番にパフォーマンスを披露！\n皆さんの心をほぐします♪",
    pr: "エンタメが人と人とを繋ぐ！\nありとあらゆるパフォーマーが、熱い思いを持って集結したブースです^^\nタイムテーブルはWebチラシにて。\nお気軽にお越しください☆",
    for_whom:
      "◆とにかくフェスを楽しみたい人\n◆気軽にフェスを楽しみたい人\n◆1人で来て、何をしたらいいかわからない人",
    night_content:
      "夜フェスでは参加型のパフォーマンスを企画中！\n詳細が決まり次第、Webチラシに随時掲載していきます^^",
    flyer_url: "https://drive.google.com/file/d/1N_gvGgx5G0_wcUy8TRnFn8Mfs2kMCpNn/view",
    area: null,
    owner: "おいちゃん🌱踊るWebデザイナー⭐",
    owner_url: "https://libecity.com/user_profile/aVEzPgxmQyWTwK1IWPg8GslJBQ02",
    co_owners:
      "れも@イラスト•デザイン/エンタメブース\nおいちゃん\nれも\nコアラッコ\nうにゃら\nRIEKO\nShinmei\nゆわ\nじゅんや\nはらぞ〜\ntaku sam\nホミノレンジ\nとみ\nりえ\nやそえ\nみっちゃん\nぷちすりあ\nありあ\nももこ\nまさかず\n最強ポジティブりつこ\n189（ワンパク）\nライス\nイシケン\nゆうた\nゴマ＠JAZZ\nひろ\nHope\nおかっぱインコ\nしろみ\nふゆちゃん\nしげ\nジョージ（元ゆず）\nだちゃん\nナンシー\nムックン\nやびー\n文鎮亭けすけ\nイースト\nきつねとおもち\n他64名",
    categories: "子供向け, 交流, ショー・パフォーマンス, 夜フェス企画あり",
  },
  {
    num: 149,
    id: 147,
    search_id: 147,
    name: "東京オフィス・学生応援ブース",
    type: "一般有料ブース",
    css_type: "PB",
    content:
      "リベシティのお悩み無料相談会やハンドメイド・オリジナルグッズの販売、ワークショップ開催",
    pr: 'リベで活躍中の"あの"先輩達が、一歩踏み出せないあなたを後押しします！頑張るあなたを応援するグッズ販売やリベ大生のハンドメイド作品もあるので、ぜひお立ち寄りください😊',
    for_whom: "リベシティにこれから入ろうか躊躇している方や、まだ活用できていない方",
    night_content: "1日目のみ、光るうちわの販売があります！",
    flyer_url: "https://www.canva.com/design/DAFhXthN9TI/oa9hgBDX2dpgvFehjMVS8g/edit",
    area: null,
    owner: "ひので",
    owner_url: "https://libecity.com/user_profile/SV4fc4QVz7YXNNC8h92PtKLKJ9j1",
    co_owners:
      "ogu\nJKせどらーはるか\nいけあす\nあくお\nおむ太郎\nやましろ\nにこ\nショウタン\nマコ\nケイト\nちいぺん\nツバ\nまっちゃしろ\nヤマネコ\nすいすい\nもに\nはとわん\nさらり\nすずらん\nくら\nそえこ\nかなた\nレオン",
    categories: "ハンドメイド販売, 販売・セミナー, ワークショップ, 夜フェス企画あり",
  },
  {
    num: 150,
    id: 148,
    search_id: 148,
    name: "core rocca -コレロッカ-",
    type: "一般有料ブース",
    css_type: "PB",
    content: "キャンプの模擬体験として薪割り体験・火起こし体験を実施！体験と物販を行います。",
    pr: "【日本初】子どもに向けて製作した薪割りクサビの開発者、はまちゃんが手掛ける薪割り道具の体験コーナー！大人も是非体験を！キャンプしない方もストレス解消に効果あり！",
    for_whom: "アウトドアな方・ストレスに悩まれている方・スカッとしたい方にオススメの商品！",
    night_content:
      "キャンプの模擬体験として薪割り体験・火起こし体験を実施！お酒とキャンプを楽しみる夜にしましょう！",
    flyer_url:
      "https://drive.google.com/file/d/1OCp-SkZ_x5avyoYnUapyBlyspW8_VP2-/view?usp=drive_link",
    area: null,
    owner: "クサビマイスター はまちゃん",
    owner_url: "https://libecity.com/user_profile/pkGeAHSIcHTKGqwDp0k138KCP8t2",
    co_owners: "たくちゃん",
    categories: "販売・セミナー, ワークショップ, 体験, 夜フェス企画あり",
  },
  {
    num: 151,
    id: 149,
    search_id: 149,
    name: "親子で楽しむワークショップ&お花のアクセサリー",
    type: "一般有料ブース",
    css_type: "PB",
    content: "子どもでも30分程度で作れる簡単なワークショップと花モチーフのアクセサリー販売",
    pr: "ハンドメイドを通して自由な表現の楽しさや、親子の触れ合いや友人と同じ時間を共有する喜びが感じられます。フェス限定商品の販売やスタンプラリーなどイベントも企画しています！",
    for_whom: "•ハンドメイドに興味がある\n•ハンドメイド品が好き\n•家族づれの方",
    night_content:
      "大人のガチャガチャ（企画中）\n•ハズレクジなし\n•ブース内物販の割引券や、物販の高単価品を景品にする",
    flyer_url:
      "https://www.canva.com/design/DAFgx3tM7e0/9m1tBFyxRyEyqtRSJ74Piw/view?utm_content=DAFgx3tM7e0&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
    area: null,
    owner: "アンスール",
    owner_url: "https://libecity.com/user_profile/WiHCyUs6HWaNMPzFF1yiBzdVX6h2",
    co_owners: "千葉のまにまに",
    categories: "ハンドメイド販売, ワークショップ, 子供向け, 夜フェス企画あり",
  },
  {
    num: 152,
    id: 150,
    search_id: 150,
    name: "インフルエンサーによる勉強相談会／熊野筆de開運メイクup術",
    type: "一般有料ブース",
    css_type: "PB",
    content: "Day1:お子さんの勉強の悩みをスッキリ解決！ \nDay2,3:自分の魅力UP！簡単メイクブラシ術",
    pr: "Day1:毎月200名以上の勉強相談の実績と経験をもとに最適なアドバイスをします \nDay2,3：プロのメイク講師による運気UPメイクブラシ術/才能ズバッと診断！",
    for_whom: "Day1:子どもの勉強を支えたい方 \nDay2,3：女性男性どなたでも！",
    night_content:
      "魔法のブラシで一瞬で顔のリフトアップの実演\nあなたのお悩みマインドからズバット回答オフ会",
    flyer_url:
      "https://www.canva.com/design/DAFir83mxCg/dkdajeV9wKBZPkPwqlYCyg/view?utm_content=DAFir83mxCg&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
    area: null,
    owner: "カナピ /// ECサイト·叶ふ熊野筆",
    owner_url: "https://libecity.com/user_profile/YwvCpm174DPAdneEoCIAodzzV5J3",
    co_owners: "嫁カナピ　夢を叶える@メイク講師\nとも先生＠オンライン学習塾",
    categories: "ワークショップ, 美容・ファッション, 占い・診断, 夜フェス企画あり",
  },
  {
    num: 153,
    id: 151,
    search_id: 151,
    name: "お子さん、初心者さん向け　レザークラフト体験/販売",
    type: "一般有料ブース",
    css_type: "PB",
    content:
      "お子さん、初心者さん向け。革に名入れをして刻印体験、手縫い体験では簡単な縫い方で革製品を仕上げます。",
    pr: "革製品はあなたにも作れます！好きな革、好きな色で自分だけの作品を作りましょう！体験する時間が無い方向けに、手縫いで仕上げる製作キットもご用意しています。",
    for_whom: "お子さん、レザークラフト未経験の方",
    night_content:
      "刻印体験に、筆記体のアルファベットを追加します。手縫い体験には、キーホルダーの穴あけ、手縫い体験が出来ます。",
    flyer_url:
      "https://drive.google.com/file/d/1RQmb2t05RI0Iu8qP8OB7XNpLdI15H35n/view?usp=share_link",
    area: null,
    owner: "チョロライフ",
    owner_url: "https://libecity.com/user_profile/MFnM9oNo6Xf3Nz85IhvnRzpeWJR2",
    co_owners: "ちあみ\nnico\nもちごめ\n三つ葉\nなおたろー\nハンドクラフター庭\n革作家フランボ",
    categories: "ハンドメイド販売, ワークショップ, 子供向け, 夜フェス企画あり",
  },
  {
    num: 154,
    id: 152,
    search_id: 152,
    name: "話に花咲く名札家〜手描き名札と革小物販売〜",
    type: "一般有料ブース",
    css_type: "PB",
    content:
      "・オリジナル名札と名札ケース販売\n・学長柄入り革小物とガチャの販売\n\n・ワークショップで名札作り\n",
    pr: "一枚ずつ手描きした名札背景から1枚選んでいただき、その場で名前を描き加えてお渡し。\n名札に合わせたケースも選んで、世界に一つだけの名札で話に花を咲かせましょう！！",
    for_whom: "初めの一歩を踏み出せない方、世界に一つの名札と革小物で背中を押します！",
    night_content:
      "・夜フェス限定の名札背景を販売\n・ガチャ景品に金の学長出現\n・革小物アウトレット品販売",
    flyer_url:
      "https://www.canva.com/design/DAFfrrdswpo/cFINTTcaUdqCMK2-ES9Y-Q/view?utm_content=DAFfrrdswpo&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
    area: null,
    owner: "きな",
    owner_url: "https://libecity.com/user_profile/yBrIlTFclqbSi2igFsUzSbsuvW72",
    co_owners: "なえなえ\nうっちー\nぐり",
    categories: "ハンドメイド販売, ワークショップ, 交流, 夜フェス企画あり",
  },
  {
    num: 155,
    id: 153,
    search_id: 153,
    name: "手づくり工房【アトリベ】",
    type: "一般有料ブース",
    css_type: "PB",
    content:
      "私たちは「ハンドメイドが好きでたまらない」というメンバーが集まってできたグループです！",
    pr: "フェスでしか買えないオリジナルのハンドメイド作品を販売します。もちろんフェス限定の学長モチーフの作品も販売します♡",
    for_whom: "ハンドメイドが好きな人。ハンドメイドを副業にしてみたい人におすすめのブースです",
    night_content:
      "撮影ブース（ミニュチュア）をベーカリー→バーに変更。\n購入の方にオリジナルのハンドメイド品をプレゼント！\n",
    flyer_url:
      "https://www.canva.com/design/DAFhgJ5sm6E/OFxayXrecJVGHxf8faSfMQ/view?utm_content=DAFhgJ5sm6E&utm_campaign=designshare&utm_medium=link&utm_source=homepage_design_menu",
    area: null,
    owner: "硝子作家-ヨウ-",
    owner_url: "https://libecity.com/user_profile/FMwjbPcLTKOG74xWQBsP7tHIWlw1",
    co_owners: "あゆみ\n\nさおりん\nちくたく\nフローレン\nみちん/フジヤマニット\nhana\nララ\nmaru ",
    categories: "ハンドメイド販売, 展示, 夜フェス企画あり",
  },
  {
    num: 156,
    id: 154,
    search_id: 154,
    name: "脱・初心者！行列のできるメルカリ相談所",
    type: "一般無料ブース",
    css_type: "NB",
    content: "メルカリ初心者向けに出品体験・梱包体験・意外に売れた不用品選手権を行います。",
    pr: "出品体験・梱包体験を行います。16時以降はメルカリ部の猛者から売れる商品ページの作り方を聞けるので早速不用品販売を始められます！",
    for_whom: "メルカリに興味がある方や、メルカリ初心者向けのブースです！",
    night_content:
      "メルカリ部の猛者から売れる商品ページの作り方についての説明を聞くことができます。",
    flyer_url:
      "https://drive.google.com/file/d/131bxP4G078V2jiQ_UfMZEec2HYPk_9Y2/view?usp=drive_link",
    area: null,
    owner: "おむ太郎",
    owner_url: "https://libecity.com/user_profile/J2SZEoh6bBaGCS13X9eIGHjhIyI2",
    co_owners:
      "ボンバー\nおむ太郎\nおはる\nゆー\nとうふやの若葉ちゃん🍀\nメルカリ大好き❤︎向日葵\nゆき虫🐛\n銀行員イースト\nみー\nたぁ\nはぴなす\nシゲオ\nきょんた\nゆか\nしまむら\nピノ\nヒッコロ\nきな\nあやたん\nふじむぅ\nウサギ\nHana\nえぬ\nかつたか\nおゆきちゃん\nおと\nえみは\nはな\nMayako（まやこ）\nmaccha（旧あんこ）\nanako\nまさちぴ\nアップル\n",
    categories: "販売・セミナー, ワークショップ, 夜フェス企画あり",
  },
  {
    num: 157,
    id: 155,
    search_id: 155,
    name: "ゲームやクイズで学長愛をチェック♡～JKせどらーはるかの店～",
    type: "一般有料ブース",
    css_type: "PB",
    content:
      "学長に関するクイズや子供も楽しめるミニゲームで変態度をチェック！フェスの思い出グッズをゲットしよう。",
    pr: "自称”学長マニア”の挑戦者求む！①学長に関するあんなコトやこんなコト、あなたはどこまで知っている!?②学長への愛をパワーに変えて、ミニゲームを成功させよう！\n",
    for_whom: "老若男女問わず、学長を愛する人ならだれでも楽しめます！",
    night_content: null,
    flyer_url:
      "https://www.canva.com/design/DAFiL2TZNf8/gOrbWYh8UxKHyAbXH5SIXw/view?utm_content=DAFiL2TZNf8&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
    area: null,
    owner: "JKせどらーはるか",
    owner_url: "https://libecity.com/user_profile/CT4QEoee7JYcgh4Rq6wlkitrZ8m1",
    co_owners: "くまゆき",
    categories: "子供向け, ゲーム",
  },
  {
    num: 158,
    id: 156,
    search_id: 156,
    name: "波飛打(ハートビーツ)ラボ",
    type: "一般有料ブース",
    css_type: "PB",
    content: "葛飾北斎の浮世絵をモチーフにしたレジンアート＆アクセサリー",
    pr: "葛飾北斎の波の絵、富士の絵、を応用したキーホルダー、アクセサリー(ネックレスやピアス、イヤリング、ブレスレットなど)を販売します。",
    for_whom:
      "アート、アクセサリー、和のモチーフが好きな方、マンガやアニメが好きな方におすすめです！",
    night_content:
      "夜フェス企画として、当ブースでは売り子のコスチュームを着物からスーツに変更し、大人の雰囲気でお迎えします。商品も限定品を販売します。",
    flyer_url:
      "https://drive.google.com/file/d/1GvN1YM5CR8RwPsec9O31Cbsgs23pn6Ef/view?usp=drivesdk",
    area: null,
    owner: "波飛打/ハートビーツ",
    owner_url: "https://libecity.com/user_profile/rZCk2Mt8sfZ73mqkgzjTgMvvo4W2",
    co_owners: "波飛打/ハートビーツ",
    categories: "ハンドメイド販売, 夜フェス企画あり",
  },
  {
    num: 159,
    id: 157,
    search_id: 157,
    name: " オリジナルアイデア雑貨の小売り＆卸売り",
    type: "一般有料ブース",
    css_type: "PB",
    content: "ひとりメーカーによる、アイデア雑貨の小売り＆卸売り販売をさせて頂きます！",
    pr: "ちょっとクスっとくるモノや、話のネタになる雑貨を作っています。\n商品のを通じて、人と人とのコミュニケーションのきっかけ作りになれるようなブースを目指します!",
    for_whom: "卸売りは雑貨屋さんに限らず、物販をはじめてみようと思う方も大歓迎です!",
    night_content: null,
    flyer_url:
      "https://drive.google.com/file/d/1KHq-u64yG6yHjLsibYSrtuMDIJVqbhlV/view?usp=share_link",
    area: null,
    owner: "浦中",
    owner_url: "https://libecity.com/user_profile/DqBaIDUQQrVaMMoedJjgvZXRerE3",
    co_owners: null,
    categories: "ハンドメイド販売, 販売・セミナー",
  },
  {
    num: 160,
    id: 158,
    search_id: 158,
    name: "プリザーブドフラワー販売＆ワークショップ『Toflower』",
    type: "一般有料ブース",
    css_type: "PB",
    content:
      "プリザードフラワーアレンジメントの販売＆ワークショップ＆撮影のコツ、お仕事アドバイスも実施",
    pr: "学長ライオンの可愛いアレンジメントを限定販売！生のお花の様に鮮やかでお手入れいらず！日頃の『ありがとう』をお花に込めて贈りませんか？お花を仕事にしてみたい方へアドバイスも実施",
    for_whom: "お花、ハンドメイドが好き🌺お花を贈りたい方🌺お花を仕事にしたい方🌺",
    night_content: null,
    flyer_url:
      "https://www.canva.com/design/DAFiMV0gdUg/LcJcTQK1-pDfjck-9Qu7lg/view?utm_content=DAFiMV0gdUg&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
    area: null,
    owner: "momo@フェス出店🌼北海道から花で",
    owner_url: "https://libecity.com/user_profile/3I39toAep0OPtchzC9T3k0Igs8G2",
    co_owners:
      "ハッピーささみ\nみずねー\n優香\nぐるぐるベリー\nたま\nまろん\nマリア\nローズMari\nはる",
    categories: "ハンドメイド販売, 販売・セミナー, ワークショップ",
  },
  {
    num: 161,
    id: 159,
    search_id: 159,
    name: "リベ大フェスをより楽しめる便利グッズ！『スマホショルダー』のお店",
    type: "一般有料ブース",
    css_type: "PB",
    content:
      "スマホショルダー・スマホグリップ、フェス限定「5つの力ショルダー」を販売するハンドメイドのお店です。",
    pr: "スマホショルダーは、両手フリーでリベ大フェスをより楽しめるグッズです！スマホ決済やプロフィールQR交換もらくらく！人工芝やテントを使ったマルシェさながらの雰囲気をお楽しみください！",
    for_whom:
      "ハンドメイドや流行りのスマホショルダーに少しでも興味のある方、ぜひあそびに来てくださいね〜★",
    night_content:
      "【先着20名様】16時以降に商品をご購入のお客様に、パラコードを使った手作りS字フック(2こ)をプレゼント！",
    flyer_url: "https://drive.google.com/file/d/1mblIpXZtvxXa_TpRsNrkpTzdDGVaUreS/view?usp=sharing",
    area: null,
    owner: "まゆか",
    owner_url: "https://libecity.com/user_profile/4XgXBjC5jcWCrKgF63SvyrkFJCk1",
    co_owners: null,
    categories: "ハンドメイド販売, 交流, 美容・ファッション, 夜フェス企画あり",
  },
  {
    num: 162,
    id: 160,
    search_id: 160,
    name: "予約率80％超え！人気ネイリストによる10分ネイルケア",
    type: "一般有料ブース",
    css_type: "PB",
    content:
      "10分ネイルケアと3分学長アートをご提供。皆さんのフェステンションをネイルで底上げ！交流の後押しも♪",
    pr: "体験すると人気ネイリストの集客の秘密が分かるかも！？交流に不安のある方も私達に10分お預け下さればしっかりフォローします^ ^体験ギフトとしてユニコーンチケットの販売も行います♡",
    for_whom:
      "フェスを楽しみたい全ての方！もちろん男性も！沢山交流して一緒にフェスを楽しみましょう♪",
    night_content: "キラキラ学長アートを200円OFFにて提供いたします。",
    flyer_url:
      "https://drive.google.com/file/d/14RP95EGZ5KJS_kICWptLJ16ctEPyGxqE/view?usp=drivesdk",
    area: null,
    owner: "よぴ",
    owner_url: "https://libecity.com/user_profile/68hlKnvt8kgHppyMKl98aygLd1e2",
    co_owners: "まいきぃ\nはむ",
    categories: "体験, 交流, 美容・ファッション, 夜フェス企画あり",
  },
  {
    num: 163,
    id: 161,
    search_id: 161,
    name: "指先に学長降臨！？交流のきっかけに!３日間限定ネイルサロン",
    type: "一般有料ブース",
    css_type: "PB",
    content:
      "フェスを指先から盛り上げる！リベのネイルチームがフェスでしか買えない学長シールを限定販売。",
    pr: "シールはその場で爪に貼るサービス付。学長の爪で新たな交流のきっかけに！？フェス後も指先を見て余韻を楽しめます☆他にネイル体験、サロン経営相談、チップオーダー等々できます！",
    for_whom: "フェスを楽しみたい、新たな交流をしたい全員へ。女性・男性・お子様も大歓迎！",
    night_content: "疲れた足にケア&マッサージをご提供。キレイな足でフェスと夏を楽しんでください！",
    flyer_url:
      "https://www.canva.com/design/DAFhMtpMibA/5S5moFLNiL0dpB8FsxL6Hw/view?utm_content=DAFhMtpMibA&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
    area: null,
    owner: "カノン🍀美足職人／ネイルブース出店",
    owner_url: "https://libecity.com/user_profile/dhfWOb6X4WTPEIETj3TIx1swgTp2",
    co_owners: "ホワイトネイル×SE@フェス出店\nななっつ@ネイル柏フェス出店♪\nKOBA @フェス出店する！",
    categories: "ハンドメイド販売, 体験, 美容・ファッション, 夜フェス企画あり",
  },
  {
    num: 164,
    id: 162,
    search_id: 162,
    name: "『ぬりたくり絵』体験＆販売",
    type: "一般有料ブース",
    css_type: "PB",
    content:
      "・インクで塗ると絵柄が浮き出てくる『ぬりたくり絵』を1枚無料で塗っていただけます！（販売もあり）",
    pr: "『ぬりたくり絵』を塗ると、あなたの中から“天才”が出てきます！ルールは一切ありません！筆を使わず、クリアファイルで芸術的に染めちゃう方法もありますよ〜♪",
    for_whom: "「普通に生きてたら万年筆インクと出会わなかった」という人に遊んでみてほしい！",
    night_content: null,
    flyer_url:
      "https://www.canva.com/design/DAFfT9GjAgY/9fUnhwl6OKaRIUSw5mmffQ/view?utm_content=DAFfT9GjAgY&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
    area: null,
    owner: "ぺぺこ@インクあそび",
    owner_url: "https://libecity.com/user_profile/26t5PmhpbxRXmW3zLHvOCdTcoc82",
    co_owners: "もぬんこ（非会員）",
    categories: "ワークショップ, 子供向け",
  },
  {
    num: 165,
    id: 163,
    search_id: 163,
    name: "スモークチーズでおなじみ燻製屋カコが秘境高知からやってきた！",
    type: "飲食ブース(調理なし)",
    css_type: "RB",
    content:
      "オンラインで即完のスモークチーズをはじめ、おつまみにぴったりな燻製商品の販売を行います！",
    pr: "リベ大フェス限定アイテムとして、スモークチーズのスライスパックがおつまみセットとして登場します！（数量限定）\nその場で食べられますので交流がはずむこと間違いなし！",
    for_whom: "お酒にぴったりのおつまみを探している方、チーズやナッツが好きな方におすすめです",
    night_content: null,
    flyer_url:
      "https://www.canva.com/design/DAFgacUUn6c/BL47Vi5wg0Bm71VNjCWOCw/view?utm_content=DAFgacUUn6c&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
    area: null,
    owner: "燻製屋カコ＠スモークチーズの人",
    owner_url: "https://libecity.com/user_profile/quVprrbOaeSWnUCJTWR3uX2OBgf1",
    co_owners: "さやぽん",
    categories: "販売・セミナー, フード【飲食ブース】",
  },
  {
    num: 166,
    id: 164,
    search_id: 164,
    name: "道東マルシェ from Hokkaido",
    type: "飲食ブース(調理なし)",
    css_type: "RB",
    content:
      "グルメの宝庫✨北海道のひがし側「道東」のおいしい特産品を北海道民が厳選して販売します‼",
    pr: "酪農王国👑十勝のジャージー乳を使用した🐂夏にピッタリ🍨フローズンヨーグルト🍦濃厚カップアイス🍨\n道東各地の牧場発🐄オリジナルチーズ🧀厳選してご提供✨",
    for_whom: "北海道物産展が🦀スイーツが🍦珍味が🐟大好きなそこのアナタです✨",
    night_content:
      "夜フェス限定🍷サロマや釧路の珍味を\n今、トレンドのいたす力に特化したネーミングでお届けします💚",
    flyer_url:
      "https://www.canva.com/design/DAFh9rLhalI/AGIRQmRXy8ddr0HQN5YoiQ/watch?utm_content=DAFh9rLhalI&utm_campaign=share_your_design&utm_medium=link&utm_source=shareyourdesignpanel",
    area: null,
    owner: "FOX",
    owner_url: "https://libecity.com/user_profile/ggtwjoacAlPuaqCqcqsaAmntWuc2",
    co_owners:
      "まっSUN\nりな\ntugu\nかぉちん\nユメクイ\nななたび\nあくしゅ\nりっこ\nレティ\nうめよ\nmi-co\nかずや\nゑ宮\nDice-K",
    categories: "販売・セミナー, スイーツ【飲食ブース】, フード【飲食ブース】, 夜フェス企画あり",
  },
  {
    num: 167,
    id: 165,
    search_id: 165,
    name: "究極の「生」シフォンケーキ＆こだわりの自家焙煎コーヒー",
    type: "飲食ブース(調理なし)",
    css_type: "RB",
    content:
      "ゆたんぽ＆珈琲焙煎部\n米粉シフォンケーキ＆焼き菓子\n自家焙煎珈琲豆＆カフェオレベースを販売します！　",
    pr: "ファーマーズマーケット「いいね！」数１位！（当社調べ）\nゆたんぽ『究極の「生」シフォンケーキ』\n共同出店という形で敗者復活を遂げた珈琲焙煎部との3日間限定コラボ！",
    for_whom: "子供から大人まで、お一人様も大歓迎！究極シフォンと珈琲でお迎えします♪",
    night_content: null,
    flyer_url: "https://drive.google.com/file/d/1EcGz5kd7oqs8xJbH1Yzx4p4uSvx7YjTJ/view",
    area: null,
    owner: "ゆたんぽ@生シフォンケーキ",
    owner_url: "https://libecity.com/user_profile/gzS5wghHfNexBPMC0I1jiOBGcbR2",
    co_owners:
      "てぃーけい@珈琲でファーマーズ出店準備中\nみちょべ\nこうすけ🛁ﾘﾌｫｰﾑ屋×ｼｪｱ別荘🌊\nシーザー@アパート2棟目挑戦中\nマジャのちか@宮古島の珈琲屋☕フェス出店\nＴ子@珈琲焙煎部ファーマズ出品中☕️\nさんたろう＠珈琲焙煎部シンバ\nみちん/フジヤマニット@アトリべ９🧶\nしだも＠珈琲焙煎部 シンバ\nyoshiこん@旧KeAloha\nぱっぷ\nみーみ@webデザイン🌈シフォン🍰\nまな夫婦@オンライン米粉スイーツ教室\nみっち夫婦@SNS運用とWEB制作\n強み発掘🐻 もりくま@寄り添い✨発想家\nもりくまよめめ@ピアノ弾くwebライター\nやながわ@せどり&不用品回収",
    categories: "ソフトドリンク【飲食ブース】, スイーツ【飲食ブース】, フード【飲食ブース】",
  },
  {
    num: 168,
    id: 166,
    search_id: 166,
    name: "学長パパのふるさとの味",
    type: "飲食ブース(調理なし)",
    css_type: "RB",
    content: "油菓子の試食販売",
    pr: "驚きの食感！！試食あり。\n学長のお父様のふるさと、蒲郡（がまごおり）の郷土菓子を材料にこだわり健康に良い最高級の油で揚げで作りました。",
    for_whom: "お子様からご年配の方まで喜んでいただけます。特に健康志向の方必見。",
    night_content: "数量限定じゃんけんで商品プレゼント　「一部変更になる可能性がある」",
    flyer_url: "https://drive.google.com/file/d/13FBBBK-_E_1Z7CgRL2LE-td2E_nDAoYi/view?usp=sharing",
    area: null,
    owner: "ひろみん",
    owner_url: "https://libecity.com/user_profile/A8w5gtIcArRg1Dn4jyaUMlyM7nl2",
    co_owners: "はっくん",
    categories: "スイーツ【飲食ブース】, フード【飲食ブース】, 夜フェス企画あり",
  },
  {
    num: 169,
    id: 167,
    search_id: 167,
    name: "鰹BAR 〜自然のタンパク質を、手軽に美味しく〜",
    type: "飲食ブース(調理なし)",
    css_type: "RB",
    content:
      "高知県産のカツオ。\n食べ方は、袋をあけて、食べるだけ。\n自然のたんぱく質を、手軽に美味しく。",
    pr: "・安心安全な国産のカツオを100%使用！\n・保存料や着色料は完全不使用！\n・調理不要で1年間常温保存ができる！\n現代人に不足しがちな栄養素を豊富に含んでおります。",
    for_whom: "・食事や栄養に気を遣いたい…！\n・でも、魚の調理は面倒…！\nピッタリな商品です！",
    night_content: null,
    flyer_url:
      "https://drive.google.com/file/d/1hpnwhXn0La_kp9x7b3S5yXc31d25-rZc/view?usp=share_link",
    area: null,
    owner: "オカケン",
    owner_url: "https://libecity.com/user_profile/Utef6KOqbAf75XLlQlLmdsRma6D3",
    co_owners: "西川匠\n\nいっせい",
    categories: "健康, フード【飲食ブース】",
  },
  {
    num: 170,
    id: 168,
    search_id: 168,
    name: "クラフトビール部フェス限定ビアショップ-LiBe Brew-",
    type: "飲食ブース(調理なし)",
    css_type: "RB",
    content: "リベフェス2023でしか飲めない完全オリジナルクラフトビール2種類を販売します！",
    pr: "幻の柑橘ヘベスを使用した爽やかな「HEBESUエール」\nまるでカクテルのような甘い香りの「稼ぐ力IPA」\n大手のビールとは全く違う味わいのビールをお楽しみあれ！",
    for_whom: "一口飲んでみな、、、「とぶぞ！！」",
    night_content:
      "「稼ぐ力IPA」の夜フェス限定デザインボトルを発売するわよ！あなた達絶対に来なさいよ！（ママ風）",
    flyer_url:
      "https://www.canva.com/design/DAFgjtuSUA0/eoOaujtO5TCnx2cIuktmGg/view?utm_content=DAFgjtuSUA0&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
    area: null,
    owner: "くんくん",
    owner_url: "https://libecity.com/user_profile/Sxv8XE7nsjeuv6n0wSMpVVvY2CP2",
    co_owners: "marimo",
    categories: "販売・セミナー, 交流, アルコールドリンク【飲食ブース】, 夜フェス企画あり",
  },
  {
    num: 171,
    id: 169,
    search_id: 169,
    name: "鹿児島の農家『MATSUKI GARDENING』",
    type: "飲食ブース(調理なし)",
    css_type: "RB",
    content: "アイコトマトの加工品(アイコトマトジャム、ジェラート)\nとろーり旨なす(青果)※収穫次第",
    pr: "会場から1,442km離れた鹿児島県指宿市から、就農2年目のニューファーマーが、アイコトマトのジャムやジェラート、珍しい白ナスをもって参戦します！！",
    for_whom:
      "甘いものが好きな方、珍しいものが好きな方、農産物の加工品が好きな方、冷たい食べ物が食べたい方におススメです！",
    night_content: null,
    flyer_url:
      "https://www.canva.com/design/DAFitsg3R58/mgJLhMsn0XJyRbr09hXyLw/view?utm_content=DAFitsg3R58&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
    area: null,
    owner: "まつき",
    owner_url: "https://libecity.com/user_profile/XqKJFykEFMSoTokWo72nuGqFCG72",
    co_owners: "あとぐら",
    categories: "販売・セミナー, 健康, スイーツ【飲食ブース】",
  },
  {
    num: 172,
    id: 170,
    search_id: 170,
    name: "リベイクド×スイーツ〜焼上げは全てを癒す〜",
    type: "飲食ブース(調理なし)",
    css_type: "RB",
    content: "手作り焼き菓子の販売",
    pr: "自称No.1ベイカーによる手作り焼き菓子の販売\n帰ってからもリベ大フェス‼︎",
    for_whom: "甘いもの好き。かわいいもの好きな方どなたも満足いただけるスイーツ🍰お土産にも是非",
    night_content: "16時以降の特別メニュー（一部変更あり）",
    flyer_url:
      "https://www.canva.com/design/DAFh9GXkofw/GY2Kkph3L2NmQG-MtX-DyQ/view?utm_content=DAFh9GXkofw&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
    area: null,
    owner: "きまき",
    owner_url: "https://libecity.com/user_profile/909k7GHNfteu53nIKwQ3mg4t7Fi1",
    co_owners: "シンゴ\nケーシー\nシフォンケーキの人\nきまき",
    categories: "ハンドメイド販売, スイーツ【飲食ブース】, フード【飲食ブース】, 夜フェス企画あり",
  },
  {
    num: 173,
    id: 171,
    search_id: 171,
    name: "Drise　ドライフード",
    type: "飲食ブース(調理なし)",
    css_type: "RB",
    content:
      "国産無添加のドライフルーツ・乾燥野菜の販売。天然水と作る「フレーバーウォーター」の無料提供をします！",
    pr: "〜素材そのままドライフルーツ〜\nリベシティのサービスを活用して完成した商品。「苦手は人に頼る」ことで、商品化までたどりついた過程も共有します！",
    for_whom:
      "美味しいドライフルーツを買ってみたい方、リベシティの活用方法を知りたい方におすすめです！",
    night_content: null,
    flyer_url:
      "https://www.canva.com/design/DAFiMTakpIc/1GHRTgCqlFCjIAGC1R7Q3w/view?utm_content=DAFiMTakpIc&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
    area: null,
    owner: "ぼうず",
    owner_url: "https://libecity.com/user_profile/LcP4RcO0HeQqbvpYmreKHo89tEc2",
    co_owners: "うめちゃん@Webライター",
    categories: "ソフトドリンク【飲食ブース】, スイーツ【飲食ブース】, フード【飲食ブース】",
  },
  {
    num: 174,
    id: 172,
    search_id: 172,
    name: "夏の贅沢パン祭り&美と健康の干し芋屋",
    type: "飲食ブース(調理なし)",
    css_type: "RB",
    content: "具沢山&濃厚なもちもちパンと、お土産にもぴったりな焼き菓子、こだわりの干し芋を販売！",
    pr: "フェスのために開発したもっちり生地に、溢れるほど贅沢に具材を詰め込んだ新感覚・新食感のパンを販売！共同出店ではさつまいもの甘みと栄養を存分に引き出した干し芋を小袋で提供します！",
    for_whom: "その場で食べても、誰かにプレゼントしても良し！リベ大フェスの思い出にいかがですか？",
    night_content: null,
    flyer_url:
      "https://www.canva.com/design/DAFhuSzHPxA/BZW5Xq0TtgeluRTNU4NMfQ/view?utm_content=DAFhuSzHPxA&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
    area: null,
    owner: "むぎ＠パン職人",
    owner_url: "https://libecity.com/user_profile/SwB90j7DjRdxLTfiESaDW68s9Uf2",
    co_owners: "キャメル\nピートル\nやるにゃん",
    categories: "販売・セミナー, スイーツ【飲食ブース】, フード【飲食ブース】",
  },
  {
    num: 175,
    id: 173,
    search_id: 173,
    name: "ウロウロジェラート",
    type: "飲食ブース(調理なし)",
    css_type: "RB",
    content:
      "夏にピッタリ♬ジェラートと冷たい和菓子をご提供。フェス限定フレーバー＆グッズも販売します！",
    pr: "素材にこだわった自然な甘さのジェラートは低カロリーで後味スッキリ♡メンバー経営の和菓子屋『一朶』のもっちり柔らか冷たい和菓子やフェスだけのコラボ商品もあります。",
    for_whom: "小さなお子様から大人の方まで、潤いと涼を求めに、是非お越しください！",
    night_content:
      "２点以上購入のお客様にはオリジナル缶バッチをプレゼント（詳しくはWebチラシを確認ください）",
    flyer_url: "https://drive.google.com/file/d/1U4nz60aL-_rboeV8FeUSFOdSK_ZxH-c_/view?usp=sharing",
    area: null,
    owner: "モモキン",
    owner_url: "https://libecity.com/user_profile/sIPu3dpnIOY8GB8LoNWXqlAiWeM2",
    co_owners: "SNOW\nさやぴぃ\nトシ\nグリフィー\nやこ\nわなな\nジャイアンパンダ",
    categories: "販売・セミナー, スイーツ【飲食ブース】, フード【飲食ブース】, 夜フェス企画あり",
  },
  {
    num: 176,
    id: 174,
    search_id: 174,
    name: "感謝を届けるクレープ屋さん",
    type: "飲食ブース(調理なし)",
    css_type: "RB",
    content:
      "感謝している人に贈ったり、一緒に食べて楽しめたりする手のひらサイズの冷凍プチクレープを販売します。",
    pr: "クリエイター達がデザインした5種類のクレープパッケージで、アートの魅力も発信します。「ヘルシーでおいしい」をモットーに、ランキング1位常連クレープ店から仕入れています。",
    for_whom: "会場をありがとうの気持ちで包みたい！冷凍プチクレープを花束代わりにどうぞ💓",
    night_content:
      "食品ロスを減らすため、当日１６時以降に在庫として残っているクレープを値引きして販売します。",
    flyer_url: "https://www.canva.com/design/DAFhrqGCN1U/Qfl0r3x-Ybz_DzUBWyifJw/view",
    area: null,
    owner: "ぺるこ",
    owner_url:
      "https://libecity.com/user_profile/z5jIjX2KUke8nEIAP8VoTEP0SPh1?redirect=/room_list?room_id=aGVwXEQNo8GQ3HyvpmlQ",
    co_owners:
      "さいちゃん\nみこママ\nシロミ\nりりー\nよっしー\nタイトン\nりじあ\n黒豆\nまちゃこ\n家ちゃん\nふぎん\nもりん\nきょん\nしぎ田岡明\nあすぽん\nじんべいみきこ\nくみ",
    categories: "スイーツ【飲食ブース】, 夜フェス企画あり",
  },
  {
    num: 177,
    id: 175,
    search_id: 175,
    name: "1516夏のベトナムフォー屋台/17北陸富山グルメ祭り",
    type: "飲食ブース(調理あり)",
    css_type: "RB",
    content: "ベトナムと富山の涼やかで味わい深いグルメを日替わりで提供致します。",
    pr: "15.16日はベトナムの屋台名物をお手軽に！フォーは両学長も美味しいと褒めて下さった自信作！17日は冷やし氷見うどんやマスの寿司など富山のグルメをたくさん楽しめます！",
    for_whom: "ユニークで豊富なメニューで幅広いお客様の食事のニーズにお応えします。",
    night_content:
      "ベトナムビールやトロピカルカクテルとおつまみのセットの提供\n※一部変更の可能性あり",
    flyer_url: "https://www.dropbox.com/s/rt2dtyl0eu2vb3r/andipop.jpg?dl=0",
    area: null,
    owner: "アンディ",
    owner_url: "https://libecity.com/user_profile/hyWTJXMdyyWlqgP9zerhsnzGqA33",
    co_owners:
      "sawa\nいっすん\nおだけみよ\nかずノート\nことり\nタルタル\nはるゆこ \nはんちゃん\nひつじ\nみつまめ\nやま\nゆかさ",
    categories:
      "ソフトドリンク【飲食ブース】, スイーツ【飲食ブース】, フード【飲食ブース】, 夜フェス企画あり",
  },
  {
    num: 178,
    id: 176,
    search_id: 176,
    name: "稼ぐ力upスパイス入り！インドの喫茶店｢チャチャイのチャイ｣",
    type: "飲食ブース(調理あり)",
    css_type: "RB",
    content: "稼ぐ力UP↑のスパイスをかけた本場インドのアイスチャイ！楽しい交流のお供にぜひどうぞ！",
    pr: "インドでは、チャイはコミュニケーションの必需品。\n美味しいアイスチャイがあればいろんな人と楽しく交流できちゃいます！\nラッシーやソーダ、ポップコーンもあるよ！",
    for_whom: "フェス参加者は全員集合！現役動画クリエイターとお話しもできるよ！",
    night_content:
      "稼ぐ力UP↑スパイス入りポップコーン！本場インドカレー味でおつまみにピッタリです！",
    flyer_url:
      "https://www.canva.com/design/DAFhw_oaBwg/6loOe6HUrbTgcLx7jIfGBg/view?utm_content=DAFhw_oaBwg&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
    area: null,
    owner: "うた",
    owner_url: "https://libecity.com/user_profile/4a1uG2H32hMJGuMUCyuryHR688i2",
    co_owners:
      "ほづみ\nうた\nハチ子\nマッピー（mappe）\nイヌ\nととと\nばーど\nたなよし\nいいかた\nえいきち\nあさがお",
    categories: "ソフトドリンク【飲食ブース】, フード【飲食ブース】, 夜フェス企画あり",
  },
  {
    num: 179,
    id: 177,
    search_id: 177,
    name: "焼き芋スイーツ店　芋学長",
    type: "飲食ブース(調理あり)",
    css_type: "RB",
    content:
      "ねっとり焼き芋にアイスを乗せて最高の甘みをお届け！癒しキャラ『芋学長』がお出迎えします！",
    pr: "焼き芋スイーツとさっぱりドリンクの相性は抜群！『芋学長』の勝負スイーツであなたのフェス疲れを癒します。昼も夜も芋尽くしでフェスを200%楽しめること間違いなし！",
    for_whom: "お芋が好きな方もそんなにな方も、男女や年齢も問わずみんなが楽しめます！",
    night_content:
      "芋焼酎・チップスのセット『芋晩酌』を販売！焼酎は優しい味で飲みやすいです。是非お試しください！",
    flyer_url:
      "https://drive.google.com/file/d/1ds7EcoPE3GQXpA212HXXFATHuQttiTyY/view?usp=share_link",
    area: null,
    owner: "のびった！",
    owner_url: "https://libecity.com/user_profile/17aEDj90eAMnIUgzAGnefT4v83y2",
    co_owners: "うし\nダイ\nyaco\nマルコフ\nちょこり\nしゅう\nたろ\nあち\nかねつぐ",
    categories:
      "ソフトドリンク【飲食ブース】, アルコールドリンク【飲食ブース】, スイーツ【飲食ブース】, 夜フェス企画あり",
  },
  {
    num: 180,
    id: 178,
    search_id: 178,
    name: "東海アウトドア部「キャンプめし！」&学長名言マグ販売",
    type: "飲食ブース(調理あり)",
    css_type: "RB",
    content:
      "ホットサンド、カレーなどの「キャンプめし」を販売！ライブでお馴染みの学長名言マグカップも販売します！",
    pr: "キャンプ初心者の方にも簡単・美味しい「キャンプめし！」としてホットサンドとカレーを販売！また毎朝ライブでお馴染み！キャンプで使える学長名言ホーローマグカップも販売します",
    for_whom:
      "「キャンプめし」を食べてみたい方・アウトドアに興味のある方・学長マグでアウトドアを楽しみたい方",
    night_content: "16時以降の別メニューや学長グッズのプレゼントを企画中！(詳細はWEBチラシ参照)",
    flyer_url:
      "https://www.canva.com/design/DAFiOwBheto/9SR7EA_TS9bL-6790FYvLw/view?utm_content=DAFiOwBheto&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
    area: null,
    owner: "テニスのおすぎ",
    owner_url: "https://libecity.com/user_profile/dN4XQHatVYOx9jE4RYlHe01za8f2",
    co_owners: "ハルパパ\n島\nたーみー\nぶん\nなおき\nひぃ\nchoco",
    categories:
      "販売・セミナー, ソフトドリンク【飲食ブース】, フード【飲食ブース】, 夜フェス企画あり",
  },
  {
    num: 181,
    id: 179,
    search_id: 179,
    name: "カリッとプライドポテト",
    type: "飲食ブース(調理あり)",
    css_type: "RB",
    content:
      "揚げたてのフライドポテトをメインに、大人のハムカツやバゲット、いちじくパン等を販売します。",
    pr: "厳選したじゃがいもを料理歴２０年以上の技とプライドで仕上げた笑顔になれるフライドポテトを筆頭に大人のハムカツや高級ワインやバゲット、ナッツ、生ハム等の定番メニューもありますよー。",
    for_whom: "年齢や性別を問わず笑顔になりたい人に向けてのブース。",
    night_content: "夜フェス専用メニュー　・トリュフが薫る贅沢ポテト　",
    flyer_url:
      "https://www.canva.com/design/DAFiJzWcGPY/7PWpn-45ad5xqiikpP-oOA/view?utm_content=DAFiJzWcGPY&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
    area: null,
    owner: "じょり",
    owner_url: "https://libecity.com/user_profile/afZBagH9niRwUXV7v6KUFTYlhQH2",
    co_owners: "おとも",
    categories:
      "子供向け, アルコールドリンク【飲食ブース】, フード【飲食ブース】, 夜フェス企画あり",
  },
  {
    num: 182,
    id: 180,
    search_id: 180,
    name: "幸せペアリング体験！超大型ワインと自家製手打ちパスタ",
    type: "飲食ブース(調理あり)",
    css_type: "RB",
    content: "『ワインと料理の相性』にこだわり、シナジー効果による『口福』をお届けします！",
    pr: "6000mlの超大型スパークリングで祭り感を演出！ソムリエ厳選ワインと熟練女性シェフが作る自家製手打ちパスタとのマリアージュをご堪能下さい。料理だけでも大歓迎！",
    for_whom: "美味しいパスタとワインを愉しみたい方、イタリアンがお好きな方はぜひ！",
    night_content:
      "夜フェス限定「倍トリュフパスタ」が登場！超大型6ℓスパークリングも夜フェス用に確保！",
    flyer_url:
      "https://www.canva.com/design/DAFiMhaSKto/LcYqcg457fcebn9akPHjMA/view?utm_content=DAFiMhaSKto&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
    area: null,
    owner: "ROMA",
    owner_url: "https://libecity.com/user_profile/U0hG076osrVVe1qb65b92vM22sk2",
    co_owners: "MILANO\nりこ\nめぐみ\nくりりん",
    categories:
      "アルコールドリンク【飲食ブース】, スイーツ【飲食ブース】, フード【飲食ブース】, 夜フェス企画あり",
  },
  {
    num: 183,
    id: 181,
    search_id: 181,
    name: "帰ってきた！栃木おやまオフィス　カフェ＆スナック",
    type: "飲食ブース(調理あり)",
    css_type: "RB",
    content:
      "栃木おやまオフィスのチームが再集結。\nこだわりと情熱が生んだ逸品をお届け。お楽しみに！",
    pr: "・忘れられない魅惑の『こつっパ スイーツ』\n・お部屋をカフェの香りにする『コーヒーバッグ』(試飲◎)\n・アツい夏にピッタリ『スパイシーなお肉&ハイボール』(個別購入◎)",
    for_whom: "人との繋がり×おいしいもの＝アットホームな栃木おやまオフィス。ただいま！",
    night_content:
      "栃木おやま期間限定オフィスの夜の部で振舞われたおすしママのオリジナルジンジャーエール。これをハイボールにしてご提供予定！",
    flyer_url: "https://www.canva.com/design/DAFiKCX4GBU/YgukabJAa9Tub_3GvtGQcg/view",
    area: null,
    owner: "KOJI",
    owner_url: "https://libecity.com/user_profile/crc79SnUi1RPVC4g7MwgUIquubr2",
    co_owners:
      "マーシー\nあきぽん\nKOJI\nこつっパ\nおすし\nザッキー\nみっつ\nchiii\nともよし\nてんちゃん\nあくお\nいのねこ\n月やなぎ\nゆきま\nうでぃ\nみきまろ\nミナ",
    categories:
      "アルコールドリンク【飲食ブース】, スイーツ【飲食ブース】, フード【飲食ブース】, 夜フェス企画あり",
  },
  {
    num: 184,
    id: 182,
    search_id: 182,
    name: "岡山キッチンカー～魅惑のたまごサンド～",
    type: "飲食ブース(調理あり)",
    css_type: "RB",
    content:
      "リベ大キッチンカー1番人気”ふわふわたまごサンド”と、推しの岡山商品をフェス用に限定販売！",
    pr: "お出汁が隠し味！子供から大人まで大好き”ふわふわたまごサンド”\n\nマステなど、お土産にもかわいいオリジナルグッズを数量限定販売\n\nキッチンカーで巡ってわかった！岡山の推しを集結！",
    for_whom:
      "ブース巡りの合間にサッと食べたい方\n\n初代キッチンカーメニューを食べながら、初めて会う方との話題のネタのひとつに",
    night_content: "夜フェスタイムにはお酒にも合う”オトナのたまごサンド”出現",
    flyer_url:
      "https://www.canva.com/design/DAFiOTePbmo/psXsR8OdxsTesegWZCjhoQ/view?utm_content=DAFiOTePbmo&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
    area: null,
    owner: "月のティラミス",
    owner_url: "https://libecity.com/user_profile/ewmaojuFFeRea9PV79Z15F5FSmd2",
    co_owners: "ケイジ\nシカ太郎\nねこかぴ\nべーよん\nチャン\nゆうま\nホリデー",
    categories: "ハンドメイド販売, スイーツ【飲食ブース】, フード【飲食ブース】, 夜フェス企画あり",
  },
  {
    num: 185,
    id: 183,
    search_id: 183,
    name: "ぱたカレーwithリベ生産者",
    type: "飲食ブース(調理あり)",
    css_type: "RB",
    content: "次々にメニューが変わるリベ生産者コラボカレー！フェス中、何種類登場する？お楽しみに！",
    pr: "リベシティ生産者さんの食材とのコラボカレー！ 気まぐれなシェフがフェス中に何種類出す？ すべて限定！なくなり次第終了！！お見逃しなく！！！",
    for_whom: "カレー好き必見！リベ生産者食材を活かした絶品カレーをお楽しみください！",
    night_content: "限定！冷やし豆乳タンタン麺🍜暑さを快適に！食べた人全員が絶賛する美味さ！",
    flyer_url:
      "https://www.canva.com/design/DAFhjcBDXeY/Cf150y_nC9jf9C--WSKnVg/view?utm_content=DAFhjcBDXeY&utm_campaign=designshare&utm_medium=link",
    area: null,
    owner: "ぱた",
    owner_url: "https://libecity.com/user_profile/tt0kwVhYztcTYgNg7Km04k5eSZD3",
    co_owners:
      "やっちゃん\nおまさ\nいりー\nユミタ\nふーちゃん\nあーちゃん\nポポロ\nrii（りい）\nちゃん\nさにー\n米のまきし",
    categories: "フード【飲食ブース】, 夜フェス企画あり",
  },
  {
    num: 186,
    id: 184,
    search_id: 184,
    name: "時短家電ホットクックのかぼちゃポタージュ",
    type: "飲食ブース(調理あり)",
    css_type: "RB",
    content:
      "ホットクックでコトコト煮込んだかぼちゃのポタージュと、コストコの大人気パンを提供します🎃",
    pr: "第4の時短家電「ホットクック」は、ほったらかしで素材の甘みを引き出す料理を作ってくれます！毎日の家事時間の短縮で自由な時間が増えるよ✨美味しさを試してみてね😆",
    for_whom: "できるなら料理したくない人、ヘルシーな食生活を送りたい人",
    night_content:
      "お得でボリュームたっぷりなワイン＋パン＋チーズのセットを販売🌙数量限定なのでお早めに〜！",
    flyer_url: "https://drive.google.com/file/d/15M-ZBkiNAUxCDKXfvDkG3nsCzMw8XVHQ/view?usp=sharing",
    area: null,
    owner: "api",
    owner_url: "https://libecity.com/user_profile/JS6rb4t94uUzZqxQra4CZvi21Cz2",
    co_owners:
      "テン\nジナン\nあでぺん\nガメ\nたら\nぴこがっぱ\nティピーコペ\nくろ\nsei\nみっちょ\nあんぱん\nよしはる\nひみこ\nおなみ\nママチム",
    categories: "アルコールドリンク【飲食ブース】, フード【飲食ブース】, 夜フェス企画あり",
  },
  {
    num: 187,
    id: 185,
    search_id: 185,
    name: "せどラーメン",
    type: "飲食ブース(調理あり)",
    css_type: "RB",
    content: "東京一味噌ラーメン、芸能人も通う名店の味を再現！",
    pr: "濃厚味噌ラーメン、名店で修行した職人技。特製鶏ガラ豚骨スープ、日光干しのメンマ、香ばしいチャーシュー、ラード炒めもやしを楽しめます。",
    for_whom: "ラーメン愛好家必試！濃厚味噌の至高の味を体験せよ！",
    night_content: null,
    flyer_url: "https://www.canva.com/design/DAFh7mNgAWc/pIjXR6ksr5FHR88pO_b8cg/view",
    area: null,
    owner: "ささっきー",
    owner_url: "https://libecity.com/user_profile/oHlDOpFsJia2Bs5nc2fpKEEj7e12",
    co_owners: "ぽにょ\nおがっち\nらろ\nかわちまる\nあつを\nじょーちゃん",
    categories:
      "ソフトドリンク【飲食ブース】, アルコールドリンク【飲食ブース】, フード【飲食ブース】",
  },
  {
    num: 188,
    id: 186,
    search_id: 186,
    name: "ミートストックトーキョー リベ大店",
    type: "飲食ブース(調理あり)",
    css_type: "RB",
    content: "焼き小龍包や餃子を中心に、カルビ丼などの丼ものも提供する飲食ブースです。",
    pr: "「ミートストックトーキョー」というお肉屋さんを運営しており、そこで扱っている商品の中からリベ大フェスに合う商品を厳選しました！お腹が空いたら、ぜひお越しください！",
    for_whom: "お肉好きの方はもちろん、どの世代でも美味しく食べられる商品を揃えています！",
    night_content: null,
    flyer_url:
      "https://drive.google.com/file/d/1uUyT-rAkIq2xNhuVI_5vuYJdLeIj-Kl3/view?usp=share_link",
    area: null,
    owner: "ぽんたろう＠学習塾オーナー",
    owner_url: "https://libecity.com/user_profile/u6jUu86VmFYI504K97EdfsqBJ692",
    co_owners:
      "管理栄養士てち。\nぽんたろう\nあつ\nりっさん\nはる\nあんな\nしゅんくん\nさいとう\nふじみる\nなつ@管理栄養士\nごん@4月からフリー管理栄養士\nよね@鍼灸師\nあくお\nきゃべつ\nモレオ@ホームページ制作in埼玉\nのじ@フリーライター\nkaiten",
    categories:
      "ソフトドリンク【飲食ブース】, アルコールドリンク【飲食ブース】, フード【飲食ブース】",
  },
  {
    num: 189,
    id: 187,
    search_id: 187,
    name: "生パスタ🍝＆ワイン🍷のお店「アフェット」",
    type: "飲食ブース(調理あり)",
    css_type: "RB",
    content:
      "和風・明太子の2種類の生パスタ\nワインやノンアルサングリア、\n前菜セット、焼き菓子をご用意",
    pr: "・一番人気「蒸し鶏としめじの和風ソース」\n・ニンニク抜き「海老と明太子のソース」\n2種類の生パスタをご提供！\n風味豊かなもっちり食感をご賞味ください",
    for_whom: "パスタが大好きな方はもちろん、\n本格生パスタが初めての方もぜひ！",
    night_content: "ワインに合う、チーズとブラックペッパー\nを使ったオイルパスタをご提供！",
    flyer_url:
      "https://www.canva.com/design/DAFhXs7bMXc/pQ7ct1f4mctpPBX8BPBkbw/view?utm_content=DAFhXs7bMXc&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
    area: null,
    owner: "ノリ@アフェット生パスタ販売でフェス出店",
    owner_url:
      "https://libecity.com/user_profile/MwGnfAGljsciuJyaOpJyNhOtvKy1?redirect=/room_list?comment_id=fSMVpfxdRyI4bddDZSqo%26room_id=EN0GWEgvugRhPKwi6cMD",
    co_owners: "やすえ\nアカギ\nかわばた\nささき\nたいち\nみい\nみゅう\nゆーや",
    categories:
      "ソフトドリンク【飲食ブース】, アルコールドリンク【飲食ブース】, フード【飲食ブース】, 夜フェス企画あり",
  },
  {
    num: 190,
    id: 188,
    search_id: 188,
    name: "みんなで餃子屋さん",
    type: "飲食ブース(調理あり)",
    css_type: "RB",
    content:
      "「餃子部チャット」の12人のメンバーで、\n【餃子屋 はまだ】の手包み餃子を焼きたてで提供します！",
    pr: "【餃子屋 はまだ】の餃子は、機械を使わずに一つ一つ丁寧に手包みしている餃子です！\nもちもちの皮に、ジューシーな具をたっぷりと包んでいる為、ボリューム満点です。",
    for_whom: "好みに合わせて３種類の餃子を用意しましたので、老若男女におすすめです！",
    night_content:
      "大葉餃子・生姜餃子以外に、【にんにく餃子】を解禁します！\n16時以降は、臭いを気にせず交流しましょう！笑",
    flyer_url:
      "https://www.canva.com/design/DAFgpUffiQM/8ojJ8VBx_Zvf6VdiRuaMlg/view?utm_content=DAFgpUffiQM&amp;utm_campaign=designshare&amp;utm_medium=link2&amp;utm_source=sharebutton",
    area: null,
    owner: "餃子屋さん",
    owner_url: "https://libecity.com/user_profile/4Rf37fn5ZxVWyjmyx4GNfmBg6pt2",
    co_owners:
      "シュンゾー\nラン\nおのちゃん\nたびお\n鮭美\n名無しのクロ\nハリーぽってぃ\nふみ\nSHOU\nらっこの中のらっこ\nあきひか",
    categories: "フード【飲食ブース】, 夜フェス企画あり",
  },
  {
    num: 191,
    id: 189,
    search_id: 189,
    name: "ブンちゃんのみかんジュース直売所＆ もぐもぐさんの〝多幸焼き〞",
    type: "飲食ブース(調理あり)",
    css_type: "RB",
    content: "めちゃくちゃ旨いみかんジュースと塩分を控え蒟蒻が入ったお腹に優しい多幸焼きです。",
    pr: "みかんジュースは美味しいものだけを搾り上げその本来の美味しさを100％味わえます。\n多幸焼きは選べる無料カラフルトッピングと旗ピック付きで見た目も楽しく仕上げます。",
    for_whom: "多幸焼き、みかんジュースともにシニア、ファミリー、健康志向の方。",
    night_content:
      "飲んで驚き！みかん農家が厳選したみかんジュース５種類の試飲会※一部変更になる場合もあります",
    flyer_url:
      "https://www.canva.com/design/DAFiG1gcSac/Yg5bnKvf5qTZKG9SspB4gw/view?utm_content=DAFiG1gcSac&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
    area: null,
    owner: "ブンちゃん＠辻中農園🍊",
    owner_url: "https://libecity.com/user_profile/JACEiexTyuRJnmT8KPEDDst5gUB3",
    co_owners: "もぐもぐさん",
    categories:
      "ソフトドリンク【飲食ブース】, スイーツ【飲食ブース】, フード【飲食ブース】, 夜フェス企画あり",
  },
  {
    num: 192,
    id: 190,
    search_id: 190,
    name: "揚げたて！あられ屋さん",
    type: "飲食ブース(調理あり)",
    css_type: "RB",
    content:
      "「揚げたてのあられ」を皆さんにお届けします。「美味しい！」と笑顔になってもらいたいです。",
    pr: "厳選したお餅を油でカラッと揚げ、しょうゆダレで仕上げます。\n出来上がりはふっくらモチモチ、食べるとカリッとしていて、鼻から抜ける香りは市販品では味わえません。",
    for_whom:
      "休憩やお子様のおやつ、お酒のおつまみにもピッタリ♪来場者の帰りを待つ大切な方へのお土産にお土産にもおすすめです！",
    night_content:
      "「揚げたてあられ」にまぶす「限定フレーバー」をご用意します。種類は複数用意する予定ですが、味はシークレットとさせていただきます。",
    flyer_url:
      "https://www.canva.com/design/DAFfzU2-iBI/tpofSGrCYHm-dM3GDpuJ1Q/view?utm_content=DAFfzU2-iBI&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
    area: null,
    owner: "そらがね",
    owner_url: "https://libecity.com/user_profile/zMfPcX0VvvewcimnoHFm13Z8Wfz1",
    co_owners:
      "Joy\nなるはし\nけんちゃん\nSagashimono Doko\nいーぬい\nあずさん\nモヤサラ\nさきりゅう\nてるみん",
    categories:
      "ソフトドリンク【飲食ブース】, スイーツ【飲食ブース】, フード【飲食ブース】, 夜フェス企画あり",
  },
  {
    num: 193,
    id: 191,
    search_id: 191,
    name: "パティスリー ベックシュクレ＠アップルパイ🍎はここだよ",
    type: "飲食ブース(調理あり)",
    css_type: "RB",
    content:
      "＊学長も食べた✨アップルパイ🍎　＊フェス限定オリジナルギフト🎁　＊人気の焼き菓子🍫販売します♬",
    pr: "今年は”クリーム入り”に挑戦♪こだわり卵のカスタードクリームがた～っぷりつまったミルフィーユ🍎アップルパイ✨さらにベックシュクレのお菓子に学長が登場！？是非チェックしてみてね♬\n",
    for_whom: "ホッと一息休憩の時に♪　フェス仕様のお土産を探している時に♪　是非お待ちしています✨",
    night_content: "16時以降もミルフィーユ🍎アップルパイ販売します！！",
    flyer_url:
      "https://www.canva.com/design/DAFhkqaMV7M/xePUCXwZdixIxdZJowfaVw/view?utm_content=DAFhkqaMV7M&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
    area: null,
    owner: "アトリエF@ケーキ屋🍎ベックシュクレ",
    owner_url: "https://libecity.com/user_profile/q40js5FQjmYsEjCk7jSn08EHIlp2",
    co_owners:
      "ペラン＠ベックシュクレ🍎Canva編集\n【お手伝いメンバー】\n7/15　湘南のひろさん\n7/15　秋田のよっしーさん\n7/16　ヨシもんさん\n7/16　Lilyさん\n7/16.17　ペランの弟：まるさん\n7/17　チャコさん",
    categories: "スイーツ【飲食ブース】, フード【飲食ブース】, 夜フェス企画あり",
  },
  {
    num: 194,
    id: 192,
    search_id: 192,
    name: "スイーツ部　米粉ワッフルとレモネードのお店",
    type: "飲食ブース(調理あり)",
    css_type: "RB",
    content:
      "みんなの健康資産を守るため、スイーツ部が『罪悪感ゼロ♡のヘルシースイーツ』を作りました💕",
    pr: "米粉と豆腐を使用した、もっちもち食感のヘルシーで美味しい焼きたてワッフルと、はちみつ×レモンの絶妙なバランスが楽しめるすっきり爽やかな優しい甘さのレモネードを提供します！",
    for_whom: "全ての土台は健康から！全ての人にスイーツを通して”愛”をお届けします💕",
    night_content: "ワッフル　or　レモネードの特別メニューを考案中　※一部変更になる可能性がある",
    flyer_url: "https://www.canva.com/design/DAFiJE9EuG8/Tqs9KQ36aXHc6MRKIjVkLw/view",
    area: null,
    owner: "いちごまま",
    owner_url: "https://libecity.com/user_profile/tRsDMXQZWqZjSmE7RUS49hg4BJm2",
    co_owners:
      "かずき\n藤井\nあすか\nバズ\nぐらたん\nまりころ\nさんかく\nChy-ちぃ\nくまもも\nめいめい\nきらつ\nMaggie\nケイつむ\nミルティーユ\nふち\nクロミツ\n手作りマロン\nなまくら鉄工\nさーちゃん\nのぶたん\nDAI\n",
    categories: "交流, スイーツ【飲食ブース】, 夜フェス企画あり",
  },
  {
    num: 195,
    id: 193,
    search_id: 193,
    name: "リベカフェ",
    type: "飲食ブース(調理あり)",
    css_type: "RB",
    content:
      "ドリップコーヒーやカフェラテ、\nソフトドリンクなどを提供。\nコーヒー豆などの販売もします。",
    pr: "高品質なスペシャルティコーヒーを使用した、\n感動の1杯をお届けします。他のブースでは\n飲めない本格的なエスプレッソマシンも導入し\n誰でもコーヒーを楽しめる空間を作ります。",
    for_whom: "苦手な方にも寄っていただきたいです\nお家コーヒーの相談もお気軽にどうぞ",
    night_content:
      "ジントニックにエスプレッソを注いだ「エスプレッソジントニック」を提供。\n夜もコーヒー片手に語りましょう",
    flyer_url:
      "https://www.canva.com/design/DAFg_47CHSw/50BJbrMBk39GNKP-B7egtA/view?utm_content=DAFg_47CHSw&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
    area: null,
    owner: "たっくん",
    owner_url: "https://libecity.com/user_profile/uDBR1ybMQzcvKekZBH4IZ1Ua3gL2",
    co_owners:
      "ムネ\nヤンク\nおゆみん\nちこたろぉ\nOgachan\nゆべ\nさとまる\nりんりん\n徳望（とくぼー）\nひろみ\nありきち\nにゃー太郎\nにの\nにゃち\nあーや\nまりも\nイズナ\nみく\nねるこ\nたもさん\nりすこ\nぐっちー\nちゃか\nパーネ・パーネ\nはまち",
    categories:
      "販売・セミナー, ソフトドリンク【飲食ブース】, アルコールドリンク【飲食ブース】, 夜フェス企画あり",
  },
  {
    num: 196,
    id: 194,
    search_id: 194,
    name: "お米で作るナンドッグ「ナンだ！このやろう」",
    type: "飲食ブース(調理あり)",
    css_type: "RB",
    content:
      "生米を使って作るお米ナンドッグ、瀬戸内のレモンを使用する檸檬コーク、七色フルーツポンチを提供します",
    pr: "生米から作るナンドッグはモッチモチ？！お米パンが初めての方はぜひご賞味あれ♬こだわりクラフト檸檬コークで皆様の喉を潤し、フルーツポンチで暑くなった体を冷まします！みんな遊びに来てネ",
    for_whom:
      "小麦アレルギーの方やグルテンを控えている方はもちろんお腹が空いた方誰でも♬ クラフトコーラに興味ある・柑橘系爽やかなドリンクが飲みたい方",
    night_content:
      "お酒に合う辛いソースをかけた「ホットなナンドッグ」と檸檬コークにハイボールを混ぜた「檸檬コークハイ」を提供予定！(一部変更になる可能性があります。)",
    flyer_url:
      "https://www.canva.com/design/DAFfXMgs2MQ/JrxFvh3Cjj_EoO85MRi9uw/view?utm_content=DAFfXMgs2MQ&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
    area: null,
    owner: "こんちゃん@お米ナンドッグ🌭フェス🌈",
    owner_url: "https://libecity.com/user_profile/rdT972C0voffnYMGD6KtCBdLP8n2",
    co_owners:
      "かなえ\nRかぁさん\nchy-ちぃ\nまりも\nウッディ\nひまわり\nもっち元大佐\n秋田のよっしー",
    categories:
      "ソフトドリンク【飲食ブース】, アルコールドリンク【飲食ブース】, フード【飲食ブース】, 夜フェス企画あり",
  },
  {
    num: 197,
    id: 195,
    search_id: 195,
    name: "屋久島✽徳之島カフェ&職人のスイーツ",
    type: "飲食ブース(調理あり)",
    css_type: "RB",
    content: "和菓子の実演販売。洋菓子やアップルパイの販売。屋久島✽徳之島の素材にこだわったカフェ",
    pr: "繊細な職人技で作られる上生菓子の実演販売！全国菓子博覧会で金賞受賞！クランベリーのブロニーズ！島外ではここだけ！屋久島産コーヒーを数量限定でご提供！徳之島の珍しいお茶も是非！",
    for_whom: "幅広い年齢層の方に、おもてなしの心が詰まったお菓子とお茶で癒しのひと時を！",
    night_content:
      "屋久島産ジンジャーエールをベースにしたカクテル「一部変更になる可能性があります」",
    flyer_url:
      "https://www.canva.com/design/DAFiPQRNrzE/6eryf7lh0DC7CI7WojzgRA/view?utm_content=DAFiPQRNrzE&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
    area: null,
    owner: "美月",
    owner_url: "https://libecity.com/user_profile/UBtmXzLe0hfJHMOVf16Czm3K0eX2",
    co_owners: "しんちゃん\nwave\nぎだ",
    categories:
      "ソフトドリンク【飲食ブース】, アルコールドリンク【飲食ブース】, スイーツ【飲食ブース】, 夜フェス企画あり",
  },
  {
    num: 198,
    id: 196,
    search_id: 196,
    name: "カヌレとたこせんー酸いも甘いもお届けする3日間ー",
    type: "飲食ブース(調理あり)",
    css_type: "RB",
    content:
      "フェスの前半は極上カヌレとコーヒーを、\n後半は大阪グルメのたこせんとレスカを\n提供します。",
    pr: "ファーマーズで即完売のカヌレと\n自家焙煎のエアロプレスコーヒー。\n大阪ソウルフードたこせんと国産レモンの\n自家製シロップで作るレスカ。\n40＋メンバーとの交流もぜひ！",
    for_whom: "フェスを楽しみお腹が空いた、\n喉が渇いた全ての来場者の皆さんに！",
    night_content:
      "お昼も提供しているメニューに加えて、\nレモンサワーの追い足しおかわりができる\n「追いサワー」を予定",
    flyer_url: "https://growthank.com/40plus-fes/",
    area: null,
    owner: "さあこ",
    owner_url: "https://libecity.com/user_profile/SejDQm4qInW0sW6zZWP1xvb22I03",
    co_owners:
      "まきろん\nさあこ\nYasu\nかっつん\nみほぽ\nねこあし\nミゲル\nクラム\nむげん\nゆらり\nくまごろー３\nるりしお\nごりへい\nたつ猫\nジュニア\nまるみ\nこじまる\nしぃー\nちくわ\nそそぴり",
    categories:
      "アルコールドリンク【飲食ブース】, スイーツ【飲食ブース】, フード【飲食ブース】, 夜フェス企画あり",
  },
  {
    num: 199,
    id: 197,
    search_id: 197,
    name: "フルーツかき氷＠福島支部",
    type: "飲食ブース(調理あり)",
    css_type: "RB",
    content:
      "リベで一番楽しいチャット福島支部の仲間が、丸ごとフレッシュなフルーツかき氷をお届けします。",
    pr: "福島の桃、メンバーの農園のいちごをかき氷にして、爽やかな涼をお届けします。なんとフルーツ１００%！！桃もいちごも丸ごとそのまま削ります。かなり贅沢なかき氷です。",
    for_whom: "リベ大フェス限定！福島のプレミアムなかき氷が食べたい方にオススメです。",
    night_content: null,
    flyer_url:
      "https://drive.google.com/file/d/12cnc5Pc01Akv8-kIJDYNIsLOT2iRWQAL/view?usp=share_link",
    area: null,
    owner: "ライス",
    owner_url: "https://libecity.com/user_profile/P17D7zvu2PhgWxSpRaQMZrYJM0w2",
    co_owners:
      "さとよし\nライス\nぴぽ　\nミルクレープ\nGo\nゆーー\nなっつん\nあーさー\nももんが\n横浜郁子\nラム\nniro",
    categories: "交流, 国・地域密着, スイーツ【飲食ブース】",
  },
  {
    num: 200,
    id: 198,
    search_id: 198,
    name: "ミルクスキーと愉快な仲間たちの牧場生乳アイスとA5和牛串屋さん",
    type: "飲食ブース(調理あり)",
    css_type: "RB",
    content:
      "ミルクスキーと牛チャットの仲間が牧場直送の生乳を使ったアイスクリームと和牛串焼きをお届け！",
    pr: "北海道の生乳と鳥取の生クリーム、2つの牧場のコラボで生まれた生乳アイス！リベ大レストランにも採用された愛知の絶品A5和牛串！当日は牧場からの中継ライブも開催！",
    for_whom: "全てのリベ大生が思わず笑顔になる牛への愛とおいしさを届けるブースです！",
    night_content:
      "夜フェス限定！幻の『シャトーブリアン串』と『ミルクスキーのチーズ』を数量限定で販売します！",
    flyer_url: "https://www.canva.com/design/DAFiOEzWT20/AzwX_jT-Alq5pI7msJSNig/view?website#4",
    area: null,
    owner: "ミルクスキー🐮",
    owner_url: "https://libecity.com/user_profile/tHIxKXkLUqYj9ItwbGL8KyGMfVH2",
    co_owners:
      "はぶ\n志賀牧場\nちょいす\nDice-K\nべごや\nおれんじ\nみなみ\nづんづろ\nホロゾン\n牛飼い\nちき",
    categories: "交流, スイーツ【飲食ブース】, フード【飲食ブース】, 夜フェス企画あり",
  },
  {
    num: 201,
    id: 199,
    search_id: 199,
    name: "ちひろ菓子店",
    type: "飲食ブース(調理あり)",
    css_type: "RB",
    content: "ちひろ菓子店の看板商品「フィナンシェ」の販売",
    pr: "リベ大フェス限定「マッスルフィナンシェ」や「ホットなフィナンシェ」も提供します！",
    for_whom: "フェスの思い出になるお土産をお探しの皆様にオススメの焼き菓子です。",
    night_content: null,
    flyer_url: null,
    area: null,
    owner: "フィナンシェ王子＠ちひろ菓子店",
    owner_url: "https://libecity.com/user_profile/U4LUnexYprVJrdcsUfUrGrAjKsv1",
    co_owners: null,
    categories: "スイーツ【飲食ブース】, フード【飲食ブース】",
  },
  {
    num: 202,
    id: 200,
    search_id: 200,
    name: "コットンキャンディ　わたがしシェフの帽子",
    type: "飲食ブース(調理あり)",
    css_type: "RB",
    content: "わたがしを作ります。当日は機材3台を使って、色とりどりのわたがしを提供する予定です。",
    pr: "わたがし屋さんでイキリ立つ٩( ''ω'' )و\n主力のわたがしにプロが使う「鬼ザラメ」という最高純度の結晶でできたザラメを使用。\n人が喜ぶ、くちどけ豊かなわたがしをご提供します。",
    for_whom: "子供のころ、縁日でわたがしを買えなかった大人たちへ・・・",
    night_content:
      "海外で人気を集めている『コットンキャンディカクテル』を提供予定です。（その他にもお酒提供予定）",
    flyer_url:
      "https://www.canva.com/design/DAFh_EGOM_8/nFa11lMIYTinsxTOiPhuGQ/view?utm_content=DAFh_EGOM_8&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
    area: null,
    owner: "マネねこ",
    owner_url: "https://libecity.com/user_profile/jPYu59iqgGOm4ACB0tsDPh4NkiF3",
    co_owners: "えつこ\nゆうか\nはだみん\nノワール",
    categories:
      "ソフトドリンク【飲食ブース】, アルコールドリンク【飲食ブース】, スイーツ【飲食ブース】, 夜フェス企画あり",
  },
  {
    num: 203,
    id: 201,
    search_id: 201,
    name: "超ふわふわ！プレミアムシロップかき氷〜冷たい氷で熱い交流を〜",
    type: "飲食ブース(調理あり)",
    css_type: "RB",
    content:
      "珍しい味の厳選シロップを使った、大人も子供も楽しめる超ふわふわ食感かき氷を提供します✨",
    pr: "ふわふわ食感のかき氷にあまおういちごやシャインマスカットなど厳選したシロップを使用！さらに交流を促進する立て札を無料配布しますので、飲食スペースで他のリベ大生と交流ができます！",
    for_whom: "フェスで交流を楽しみたい方におすすめ！\nお供に美味しいかき氷はいかがですか？😊",
    night_content:
      "ドリンク用フローズンを販売！他ブースのお酒やドリンクと組み合わせてクールダウンにどうぞ！",
    flyer_url:
      "https://www.dropbox.com/s/pplu6j61o83sqou/%E2%91%A1WEB%E3%83%81%E3%83%A9%E3%82%B7A4%E7%B8%A6%E9%95%B7%E3%83%87%E3%82%B6%E3%82%A4%E3%83%B3W210mm%C3%97H297mm_ol_A4_chirashi_tate.pdf?dl=0",
    area: null,
    owner: "しんや",
    owner_url: "https://libecity.com/user_profile/Tdk73iIKl4Qg9X2xF3X8ZnbaDgJ3",
    co_owners: "まーしー\nD君",
    categories: "子供向け, 交流, スイーツ【飲食ブース】, 夜フェス企画あり",
  },
  {
    num: 205,
    id: 203,
    search_id: 203,
    name: "こめこdeもちもちどら焼き",
    type: "飲食ブース(調理あり)",
    css_type: "RB",
    content:
      "学長が大好きなどら焼きを米粉で作りました～！リベフェス限定学長🦁の焼き印つき！\n米粉の焼き菓子も♪",
    pr: "小麦粉の代わりに米粉を使った”からだにやさしい”どら焼きです。きび砂糖を使い、抹茶はメンバー自家製とこだわりました。選べる餡は３種類！米粉で作ったもちもちの皮をぜひご賞味ください♪",
    for_whom: "健康に気をつかっている方に\n罪悪感なく食べられる\n米粉のおいしさを届けます♪",
    night_content: "絶賛企画中です！特別などら焼きを販売します～♪",
    flyer_url:
      "https://www.canva.com/design/DAFg6xjp0BA/vQssLew6xyHBofOEQwT_Rw/view?utm_content=DAFg6xjp0BA&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
    area: null,
    owner: "どんぐりこ",
    owner_url: "https://libecity.com/user_profile/zneex5h9pzTkQnP81Q39H3r2jhC2",
    co_owners:
      "にちこ\nめい\nにれ\nマイキッチンひなの\nお茶のジュンジュン\nきら\n幸mom\nたんたか☆\nまな夫婦\nおやつ\n米粉のぐみ",
    categories: "スイーツ【飲食ブース】, フード【飲食ブース】, 夜フェス企画あり",
  },
  {
    num: 206,
    id: 204,
    search_id: 204,
    name: "新食感！とろみバル",
    type: "飲食ブース(調理あり)",
    css_type: "RB",
    content:
      "新食感！滑らかな口当たり、濃厚な味わい。初めてのとろみビールとムースで贅沢なひとときを。",
    pr: "ほどよいとろみが楽しめる炭酸飲料や、口どけ滑らかなムースをご提供。飲んだ瞬間、舌先から広がる爽快感や、贅沢な味わいが魅力。新しい飲み方や食感をお探しの方、ぜひ一度ご賞味ください。",
    for_whom:
      "新食感で美味しいものを求めている方。大事なご家族にいつまでも美味しいものを提供したい方。",
    night_content:
      "夜フェス限定スペシャルメニューをご提供！とろみが楽しめる飲料に、口溶け豊かなムースに魅惑のメニューが追加！乞うご期待。",
    flyer_url:
      "https://www.canva.com/design/DAFiM9-kWDc/2i_f4bCTD-spHia1GH5gDQ/view?utm_content=DAFiM9-kWDc&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
    area: null,
    owner: "ゆー@介護食経営フェス出店『とろみバル』",
    owner_url: "https://libecity.com/user_profile/SPIiE9DbRIdKaI3DdOBHGbd2wRj2",
    co_owners:
      "たかの@食支援ナース•フェス出展✖️２\nあーやー@フェス出店トリプルサポーター\nかもしか＠終活ナース\n看護師たねこ＠とろみバル出展\nコーギー@物販＋リウマチケアNs.\nさくら🌸産後ケアNs/お腹ソムリエ出店\n鮭美@【餃子屋さん】【とろみバル】フェス\nsora@とろみバル スタッフ\n宝箱@フェスとろみバル店員\nつっちょん@フェス『とろみバル』店員🍺\nなお@フェスとろみバル/ケアマネ起業\nノーミー大阪訪問看護社長\nはとわん\nぴっぴ🌻看護師・『とろみバル』スタッフ\nベルどろっぷ🔔フェス【とろみバル🍺】\nまあ旅ナース＠フェス「とろみバル」サポ\nまっちゃ＠フェスとろみバル店員\nまりな健康第一\nみきまろ\nミック＠心にハグ看護師/お腹ソムリエ出店\nやー@フェスオケ、バル、アイコンシール等\nりい＠栄養ナース/お腹ソムリエフェス出店\nルピ@採血室の看護師",
    categories:
      "ソフトドリンク【飲食ブース】, アルコールドリンク【飲食ブース】, フード【飲食ブース】, 夜フェス企画あり",
  },
  {
    num: 207,
    id: 205,
    search_id: 205,
    name: "26万いいね！！SNS映えの水まる餅を食べよう",
    type: "飲食ブース(調理あり)",
    css_type: "RB",
    content: "SNS26万いいねの夏スイーツがリベフェス限定バージョンで登場！友達は全員特別価格だよ！",
    pr: "SNSで26万いいねの夏スイーツを友達価格で食べて行こう！！学長のイラスト皿のうえで透明スイーツがプルプルするよ＾＾",
    for_whom: "夏のスイーツ好きな人！フェス記念の写真にも！喉乾いてる人もどーぞ＾＾",
    night_content: "夜フェス専用『両子ママ』のイラスト皿登場！！",
    flyer_url:
      "https://www.canva.com/design/DAFhvIWfpEM/K4uxBnHjW8JtqlEe1Q8Jnw/view?utm_content=DAFhvIWfpEM&utm_campaign=share_your_design&utm_medium=link&utm_source=shareyourd",
    area: null,
    owner: "丸之助＠フェス出店チームマルモチヤ",
    owner_url: "https://libecity.com/user_profile/V7cAZiY8S6QUTHM8NJ5Mh5FG5302",
    co_owners:
      "YUMIKO@フェス出店チームマルモチヤ\nまさ＠美容鍼灸整体\n三日月＠ガンプラ製作\nライト✨",
    categories: "スイーツ【飲食ブース】, 夜フェス企画あり",
  },
  {
    num: 208,
    id: 206,
    search_id: 206,
    name: "静岡ブース",
    type: "飲食ブース(調理あり)",
    css_type: "RB",
    content:
      "お茶のテイクアウトカップ\nお茶の葉（袋詰め）\nお茶の詰め放題\nフルーツシャーベット\nコッペパン",
    pr: "お世話になった人たちにユニコーンチケットで感謝を伝えよう！！\n５枚綴りのチケットに割引券をプラス！\nチケットやユニコーン（割引券）を、お世話になった人に贈れる！！",
    for_whom:
      "冷たい物ばかりで胃が疲れた時や、さっぱりしたいときに、お茶をどうぞ！\n隣のまるもち屋さんの水まる餅との相性もバッチリ！\nシャーベットは、カップなので食べ歩きに最適！",
    night_content: "静岡割り（お茶割り）焼酎\nしぞーかおでん\n枝豆の炒め物",
    flyer_url:
      "https://www.canva.com/design/DAFh8fE3Neo/-JX9DSxS65ZPHVKmI8xVsA/view?utm_content=DAFh8fE3Neo&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
    area: null,
    owner: "かずバドコーチ",
    owner_url: "https://libecity.com/user_profile/wcsbfXLSO2dVcEJIa4D0AUFVdbH2",
    co_owners: "しな",
    categories:
      "ソフトドリンク【飲食ブース】, アルコールドリンク【飲食ブース】, スイーツ【飲食ブース】, 夜フェス企画あり",
  },
  {
    num: 209,
    id: 207,
    search_id: 207,
    name: "もらって嬉しいぶどう屋さん！アイスもあるよ！",
    type: "飲食ブース(調理あり)",
    css_type: "RB",
    content:
      "学長にご紹介して頂いた巨峰の先行予約販売、パティシエとぶどう屋の作る贅沢アイスの販売です！",
    pr: "普段行なっていない巨峰の先行予約販売おフランスで腕を磨かれたパティシエさんとの特別コラボ企画でアイス販売を行います！",
    for_whom: "ぶどうの贈り物をしたい方やアイスでちょっと贅沢をしたい方にオススメです！",
    night_content: "フランスの大人シロップでアイスに魅了されて下さい！",
    flyer_url:
      "https://www.canva.com/design/DAFiAwZeaCA/X3PxrCumSmh-b41j2quyGQ/view?utm_content=DAFiAwZeaCA&utm_campaign=designshare&utm_medium=link&utm_source=viewer",
    area: null,
    owner: "ぶどう屋グッチ",
    owner_url: "https://libecity.com/user_profile/AqnzYh6tPjOaeYoD2NpArNSNnv23",
    co_owners:
      "わゆみん\nぶどう屋グッチ\nタイトン\nグラ\nあかり\nユーキ\nかたつむり母\nれもんぱい\nあーい\nぷーた\nかずほ\nウール178%\n内藤みさき\nゆかちん\nまいぽんたん\nあじふらい\nみりり\nしろくま",
    categories: "交流, スイーツ【飲食ブース】, 夜フェス企画あり",
  },
  {
    num: 210,
    id: 208,
    search_id: 208,
    name: "マッスルカフェ",
    type: "飲食ブース(調理あり)",
    css_type: "RB",
    content:
      "一晩かけて水出しした後味スッキリのアイスコーヒーとパンからはみ出る美味しさホットなドック！",
    pr: "誰でも最初は未経験者、チャレンジに年齢は関係ない。何より人と会うことの大切さを1人でも多くの人に伝えたい、そんなキッカケとなる場所がマッスルカフェの魅力です。",
    for_whom: "ささいなことは気にしない！明るく楽しく前向きな人生を送っていきたい方。",
    night_content: "購入された方に非売品特製ステッカープレゼント！　※お一人様限定1枚",
    flyer_url:
      "https://www.canva.com/design/DAFiwyFkZjY/zOoXmFuwXPzsRxX1leF5mw/view?utm_content=DAFiwyFkZjY&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
    area: null,
    owner: "マッスルおじさん",
    owner_url: "https://libecity.com/user_profile/LxemcKOVsygEtlPtkhLlRSusEbn1",
    co_owners: "もあい\nあらふぉー、キョーコ、よっしー、もぶこ、アーヤ。、ろこ",
    categories: "ソフトドリンク【飲食ブース】, フード【飲食ブース】, 夜フェス企画あり",
  },
  {
    num: 211,
    id: 209,
    search_id: 209,
    name: "スターマッスルコーヒー",
    type: "飲食ブース(調理あり)",
    css_type: "RB",
    content:
      "タンパク質たっぷりの甘くて、美味しく、気軽に食べられるプロテインスイーツを提供します！",
    pr: "タンパク質は筋トレをする人にしか必要でないと思われがちですが、健康資産を積み上げるのにも重要な役割があります！スターマッスルコーヒーでタンパク質を摂取しませんか！？",
    for_whom: "マッチョはもちろん、健康資産を積み上げたい人ブースへ来てください！",
    night_content: null,
    flyer_url:
      "https://www.canva.com/design/DAFh2pH_TsY/tMTiN6aajup1RSHxNoolCg/view?utm_content=DAFh2pH_TsY&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
    area: null,
    owner: "グリチキ",
    owner_url: "https://libecity.com/user_profile/gyj15Y1taDeRrkSTUlHpTd6DlVM2",
    co_owners: "ショータ\nゆーき",
    categories: "健康, スイーツ【飲食ブース】, フード【飲食ブース】",
  },
  {
    num: 212,
    id: 210,
    search_id: 210,
    name: "リベ大野球部のスタジアムドック～幕張メッセに売り子が登場～",
    type: "飲食ブース(調理あり)",
    css_type: "RB",
    content:
      "野球場の売店をイメージした飲食店です！ホットドックや売り子など、球場の雰囲気が楽しめます！",
    pr: "野球観戦のワクワクをリベ大フェスでも感じてもらいたく、球場定番グルメのホットドックや、名物の売り子販売を再現します！WBCでおなじみのペッパーミルも・・・！？",
    for_whom: "とにかく野球が好きな方！球場の雰囲気を味わってみたい方におすすめです！",
    night_content: null,
    flyer_url:
      "https://www.canva.com/design/DAFgXq0b49c/KM7a61HbTerFNOgkSxXUxA/view?utm_content=DAFgXq0b49c&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
    area: null,
    owner: "おりぺい",
    owner_url: "https://libecity.com/user_profile/vhLvGJBAGBSZcDORHrpccCE5Q9t2",
    co_owners:
      "みっちゃん\nよう\nねぎねぎ\nかずきんぐ\nやっさん\nチャーリー\nうるま\nスージー\nみるふぃーゆ\nもつむら\nblue\nぽんたまる\nたくみん\nおたまる",
    categories: "体験, アルコールドリンク【飲食ブース】, フード【飲食ブース】",
  },
  {
    num: 213,
    id: 211,
    search_id: 211,
    name: "糖度25度ぶどう丸ごとジュース&葛アイス",
    type: "飲食ブース(調理あり)",
    css_type: "RB",
    content:
      "大阪「北河内」の農家＆パティスリーと協力した【ぶどう丸ごとジュース】と【葛アイス】を限定販売",
    pr: "学長ライブで話題の「ひらパー」がある大阪・北河内の「神宮寺ぶどう」を使用！\nフェス限定の「糖度25度のぶどうが丸ごと入ったジュース」と「特別な葛アイス」をご賞味ください",
    for_whom: "ぶどう大好きな方！「とけない！？不思議なアイス」葛アイスが気になる方！",
    night_content: "ここでしか味わえない！糖度25度のぶどう丸ごとスパークリングワインを限定販売！",
    flyer_url: "https://drive.google.com/file/d/1lgmO4lyIElIVx8R9t8Br8TrdwjlRQJij/view",
    area: null,
    owner: "ケン",
    owner_url: "https://libecity.com/user_profile/6vcjKAGQIuPgM0JCCaedCWCcJO73",
    co_owners:
      "おまつ\nあまゆき\nひさもん\nとおる\nゆー\n大魔神\nゆうき\nちび丸\nはっち\nひだりの狸\nくまゆき",
    categories:
      "ソフトドリンク【飲食ブース】, アルコールドリンク【飲食ブース】, スイーツ【飲食ブース】, 夜フェス企画あり",
  },
  {
    num: 214,
    id: 212,
    search_id: 212,
    name: "ぽん酒アカデミー",
    type: "飲食ブース(調理あり)",
    css_type: "RB",
    content:
      "日本酒好き20人が、全国から厳選した日本酒を販売！お酒が飲めない人向けにノンアルもあります",
    pr: "日本酒好きを増やして、日本酒を飲んで交流したい！そんな変人20人が、本気で選んだ日本酒が勢ぞろい！せっかくの機会です。あなた好みの日本酒、見つけてみませんか？",
    for_whom: "日本酒が好きな人も！日本酒初心者も！一緒に楽しく日本酒を学びましょう！",
    night_content:
      "【あんたたち～！飲むわよ～！】夜フェス限定「特別酒セット」を、今夜のおともにいかがですか？",
    flyer_url:
      "https://www.canva.com/design/DAFgO8iUoTk/IMysr6zCmRFUzBNbAVLAfQ/view?utm_content=DAFgO8iUoTk&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
    area: null,
    owner: "びーと",
    owner_url: "https://libecity.com/user_profile/1hyhmmGhq8Yas1BvKao56sbF37k2",
    co_owners:
      "ASK\nおみ\nみそや庵\nあたる\nヒリー\nけいチュン\nさいちゃん\nおつまみ\nmayo\nしろま\nゆな\nとらい\nななは\nすかい\nハシモ\nspool\nもちんぬ\nカイカイ\nガクトくん",
    categories:
      "ソフトドリンク【飲食ブース】, アルコールドリンク【飲食ブース】, フード【飲食ブース】, 夜フェス企画あり",
  },
  {
    num: 215,
    id: 213,
    search_id: 213,
    name: "呑まさる🐵NorthBar 「北海道から厳選したお酒とおつまみ」",
    type: "飲食ブース(調理あり)",
    css_type: "RB",
    content:
      "北海道の味覚をお届け！ビール、ウヰスキー、チーズソーセージ、ブルスケッタ、充実ラインナップ",
    pr: "北海道希少ホップビールSorachi1984、ケムケムさんのバケットでブルスケッタ、新札幌乳業と山中牧場の限定チーズソーセージ！ラムバンディさんのパンティラム♡",
    for_whom: "おひとり様でも気軽に北海道の厳選品を片手に出会いを楽しめる場所を提供",
    night_content: "北海道の厳選品を一部特別価格で提供予定です！詳細は今後のWebチラシをチェック！",
    flyer_url: "https://drive.google.com/file/d/18YtVfhJsJC-jDZmlsHLLkwAhF8wFdo_B/view",
    area: null,
    owner: "たかな",
    owner_url: "https://libecity.com/user_profile/uRhuyy2EEphGOwexxMq18Hai5112",
    co_owners:
      "じゅんこ\nかず\nすーさん\nよしの\nふゆ\n取説じじ\ntoto\nよピっと\nKana\nみかん\nゆき\nチズ⼦\nラムバンディ\nシャチ",
    categories: "アルコールドリンク【飲食ブース】, フード【飲食ブース】, 夜フェス企画あり",
  },
  {
    num: 216,
    id: 214,
    search_id: 214,
    name: "5つの力を美味しく吸収！ 「お茶カフェ」@お茶・Tea部",
    type: "飲食ブース(調理あり)",
    css_type: "RB",
    content:
      "・お茶使用のドリンク販売と実演\n・「５つの力」のカラフルなお茶\n・学長タンブラーと夜はお酒も！",
    pr: "学長と【5つの力】をお茶の色彩で鮮やかな\nカクテルに♪ノンアルコールなのでお子様も\n楽しめます。日本茶プロのお茶淹れ方実演と\n夜フェスは限定でお酒のメニューもご提供！",
    for_whom: "着色料一切なし、自然な色味のお茶を使用♪心も身体も元気になりたい人へ✨",
    night_content:
      "夜はお茶を使ったアルコールのメニューが増えます。詳しくはWebチラシ又はSNSをチェック！",
    flyer_url:
      "https://www.canva.com/design/DAFiPk6kxEM/1vNP1sBsdAEAlEfGboFw-Q/view?utm_content=DAFiPk6kxEM&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
    area: null,
    owner: "ぽん太@お茶バリスタ",
    owner_url: "https://libecity.com/user_profile/5o9GmQ40xgZehSwSASkHJlvQ9ol2",
    co_owners:
      "きり\nみっちー\nひな吉\nほりほり\nとと\nしゅーみん\nほりまる\nてとら\nエリカナ\nはながえる\nスイ\n月のティラミス",
    categories:
      "ソフトドリンク【飲食ブース】, アルコールドリンク【飲食ブース】, ショー・パフォーマンス, 夜フェス企画あり",
  },
  {
    num: 217,
    id: 215,
    search_id: 215,
    name: "レモンドリンク薬局byリベシティ薬剤師",
    type: "飲食ブース(調理あり)",
    css_type: "RB",
    content:
      "疲れが吹き飛ぶレモンドリンク🍋\nレモネードにスカッシュにサワー\nお薬手帳とカバーも販売します。",
    pr: "抗酸化作用や免疫力アップ効果のレモン。\n白衣の薬剤師が超速で調剤します🥼\nクエン酸で味の変化も楽しみ、疲労回復！\n学長のお薬手帳で病院も楽しくなりますよ。",
    for_whom: "暑い熱いフェスを満喫してるあなたに！\n一息ついてリフレッシュしましょう😺",
    night_content: "追加アルコール１Push/クエン酸1Shotが無料になります！",
    flyer_url:
      "https://www.canva.com/design/DAFg0jhuHSw/X2conFrV-E-ajtHmxFONEQ/view?utm_content=DAFg0jhuHSw&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
    area: null,
    owner: "なかがみ🐸",
    owner_url: "https://libecity.com/user_profile/5BKeigRkiIahvTlfv7oyh3HsTNu2",
    co_owners:
      "ニッシェ\nなかがみ\nりーこ\nけんしん\nまっくん\nにっぴー\nタケノコ\n勇気りんりん\nもりこ\nニック345\nむむむ\nさくら\nしゅーみん\nぺも",
    categories:
      "健康, ソフトドリンク【飲食ブース】, アルコールドリンク【飲食ブース】, 夜フェス企画あり",
  },
  {
    num: 218,
    id: 216,
    search_id: 216,
    name: "300均キンドリンク ~300円均一のドリンク専門店~",
    type: "飲食ブース(調理あり)",
    css_type: "RB",
    content:
      "キンキ～ンに冷えたソフトドリンクからアルコールまで、全て300円均一で販売し、皆様の喉を潤します!",
    pr: "こだわりポイント‼\n①全ドリンク【300円均一】\n②交流のきっかけを作るユニコーンドリンクチケットも販売!!\n③どこのブースに行くか迷ったら、、まずは『300均キンで乾杯っ！』",
    for_whom: "フェスに来場される皆様、【全員】に向けたドリンクブースです!!",
    night_content: "夜フェス限定、魅惑の300円ドリンクを販売します！",
    flyer_url:
      "https://www.canva.com/design/DAFiNa_Ca_U/hMPNGeQJWU7eNu2JP51VSw/view?utm_content=DAFiNa_Ca_U&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
    area: null,
    owner: "はなくま🐻歯科コンサル/医療介護パンフ",
    owner_url: "https://libecity.com/user_profile/ZjMAtJxoyGOcPliz8W2lG5T7U3u1",
    co_owners:
      "がみ。@医療×集客デザイン🔥\npopo@インテリアコーディネーター\nかんかん@サロン＆レンスペ経営\nひでぷぅ@営業集客のプロ",
    categories:
      "交流, ソフトドリンク【飲食ブース】, アルコールドリンク【飲食ブース】, 夜フェス企画あり",
  },
  {
    num: 219,
    id: 217,
    search_id: 217,
    name: "まじゅのバナナジュース屋さん",
    type: "飲食ブース(キッチンカー)",
    css_type: "RB",
    content:
      "バナナジュースや軽食、オリジナルグッズの販売を予定。黄色いバナナキッチンカーでお待ちしてます★",
    pr: "旅するリベ大食堂in埼玉で大人気だった、バナナジュースが再びリベにやって来る！砂糖不使用の甘くて美味しい！冷たいバナナジュースを是非！ガチャガチャでオリジナルグッズも販売します！",
    for_whom: "子供から大人まで、いろんな方におすすめなバナナジュースです✨",
    night_content: null,
    flyer_url: "https://drive.google.com/file/d/15ziFpULAzPkNz7ZgSxo_pR-_TOLMmVsT/view?usp=sharing",
    area: null,
    owner: "まじゅ@キッチンカー",
    owner_url: "https://libecity.com/user_profile/QUUQnTmZA4h58GwmADaqgRRmh0E3",
    co_owners: "銀時",
    categories: "ソフトドリンク【飲食ブース】, スイーツ【飲食ブース】, フード【飲食ブース】",
  },
  {
    num: 220,
    id: 218,
    search_id: 218,
    name: "馬肉料理専門店(キッチンカー) 今日が一番馬い日！！",
    type: "飲食ブース(キッチンカー)",
    css_type: "RB",
    content:
      "馬肉料理に特化した、サーロインステーキ、つくね、ソーセージ。手作りドリンクを販売します。",
    pr: "最も安全な食肉とされており低カロリー、高タンパク、高ミネラル。試行錯誤を重ね、最高な馬肉料理に。自家製ジンジャーとレモネードでキリっと夏には最高のペアリングを。",
    for_whom: "健康志向の方、鍛えてる方、老若男女問わず誰もが美味しくヘルシー!",
    night_content:
      "馬✖️牛のMIXホルモン焼き。つまみ3種とドリンクのセット（一部変更になる可能性があります。）",
    flyer_url:
      "https://www.canva.com/design/DAFg-e8MAd8/fVntPA6-FzEjCJWartE1TA/view?utm_content=DAFg-e8MAd8&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink",
    area: null,
    owner: "アイジ",
    owner_url:
      "https://libecity.com/user_profile/iXA1FoljN0UFXQoFPH5uUKcsPUV2?redirect=/room_list?room_id=DzTpjAl9uGttOOHG0b6Q",
    co_owners:
      "みぇる\nちっち\nひさりーぬ\nケイ\nSAO\nりなっち\nReina\nちーぶ\n八雲\nはるやま\nかいり\nかなっち",
    categories: "フード【飲食ブース】, 夜フェス企画あり",
  },
];
